import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NavigationComponent } from './navigation/navigation.component';
import { scrollToPage } from './app.animations';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [scrollToPage]
})
export class AppComponent implements OnInit {
  title = 'pacific-entertainment';
  config: any;
  fullpage_api: any;
  isResponsive: false;

  @ViewChild(NavigationComponent) navigation;

  constructor() {
  }

  getWheelDelta(event) {
    return (
      event.wheelDelta ||
      -event.detail ||
      event.originalEvent.wheelDelta ||
      -event.originalEvent.detail ||
      -(event.originalEvent.deltaY * 25) ||
      null
    );
  }

  ngOnInit() {
    const _this = this;

    if (navigator.userAgent.match(/Trident\/7\./)) {
      // if IE
      $('body').on('mousewheel', function(event) {
        // remove default behavior
        event.preventDefault();

        // scroll without smoothing
        const wheelDelta = _this.getWheelDelta(event);
        const currentScrollPosition = window.pageYOffset;
        window.scrollTo(0, currentScrollPosition - wheelDelta);
      });
    }

    // Set intro anim
    setTimeout(() => {
      const loader = $('.loader-wrap');
      loader.addClass('is-disabled');
    }, 1000);
    setTimeout(() => {
      const heading = $('.parallax--block h1, .parallax--block h2, .parallax--block h3'),
        content = $('.parallax--block p');

      heading.removeClass('is-disabled');
      setTimeout(() => {
        content.removeClass('is-disabled');
      }, 2000);
    }, 2000);
  }

  scrollStart(el) {
    const content = el.element.querySelectorAll('.parallax--block h1, .parallax--block h2, .parallax--block h3, .parallax--block p');

    content.forEach(e => {
      e.classList.add('is-disabled');
    });
  }

  scrollDone(el) {
    const heading = el.element.querySelectorAll('.parallax--block h1, .parallax--block h2, .parallax--block h3'),
        content = el.element.querySelectorAll('.parallax--block p');

    setTimeout(() => {
      heading.forEach(h => {
        h.classList.remove('is-disabled');
      });
      setTimeout(() => {
        content.forEach(c => {
          c.classList.remove('is-disabled');
        });
      }, 2000);
    }, 2000);
  }
}
