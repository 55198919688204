<section class="parallax__content claims-promise">
  <h2>Honoring our claims promise</h2>
  <div class="r-table" *ngFor="let panel of panels; let i = index">
    <div class="col col--bg{{ i + 1 }}"></div>
    <div class="col">
      <div class="col--content">
        <img
          src="assets/img/svg/quotes.svg"
          alt='Quotes'
          width="30"
          height="31"
          class="quote-top"
          alt="Quote icon"
        />
        <p *ngFor="let desc of panel.description">{{ desc }}</p>
      </div>
    </div>
  </div>
</section>
