/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./intro.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../logo/logo.component.ngfactory";
import * as i3 from "../logo/logo.component";
import * as i4 from "./intro.component";
import * as i5 from "../globals";
var styles_IntroComponent = [i0.styles];
var RenderType_IntroComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_IntroComponent, data: { "animation": [{ type: 7, name: "scrollAnimation", definitions: [{ type: 0, name: "show", styles: { type: 6, styles: { opacity: 1 }, offset: null }, options: undefined }, { type: 0, name: "hide", styles: { type: 6, styles: { opacity: 0 }, offset: null }, options: undefined }, { type: 0, name: "fadein", styles: { type: 6, styles: { opacity: 1 }, offset: null }, options: undefined }, { type: 1, expr: "show => hide", animation: { type: 4, styles: { type: 6, styles: { opacity: 0 }, offset: null }, timings: "300ms ease-out" }, options: null }, { type: 1, expr: "hide => show", animation: { type: 4, styles: { type: 5, steps: [{ type: 6, styles: { transform: "translate3d(0,100%,0)", opacity: 0, offset: 0 }, offset: null }, { type: 6, styles: { transform: "translate3d(0,70%,0)", offset: 0.3 }, offset: null }, { type: 6, styles: { transform: "translate3d(0,30%,0)", offset: 0.6 }, offset: null }, { type: 6, styles: { transform: "translate3d(0,0,0)", opacity: 1, offset: 1 }, offset: null }] }, timings: "1500ms ease-in-out" }, options: null }, { type: 1, expr: "hide => fadein", animation: { type: 4, styles: { type: 6, styles: { opacity: 1 }, offset: null }, timings: "1000ms ease-in" }, options: null }, { type: 1, expr: "fadein => hide", animation: { type: 4, styles: { type: 6, styles: { opacity: 1 }, offset: null }, timings: "500ms ease-out" }, options: null }], options: {} }] } });
export { RenderType_IntroComponent as RenderType_IntroComponent };
export function View_IntroComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 19, "article", [["class", "main-section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 18, "div", [["class", "parallax__wrap"]], [[24, "@scrollAnimation", 0]], [[null, "@scrollAnimation.start"], [null, "@scrollAnimation.done"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("@scrollAnimation.start" === en)) {
        var pd_0 = (_co.scrollStart($event) !== false);
        ad = (pd_0 && ad);
    } if (("@scrollAnimation.done" === en)) {
        var pd_1 = (_co.scrollDone($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 17, "section", [["class", "parallax__content--center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 16, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-logo", [], null, null, null, i2.View_LogoComponent_0, i2.RenderType_LogoComponent)), i1.ɵdid(5, 114688, null, 0, i3.LogoComponent, [], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 13, "div", [["class", "stripe-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 3, "h1", [["class", "is-disabled"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Entertainment:"])), (_l()(), i1.ɵeld(9, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["An Evolving Industry"])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "h2", [["class", "is-disabled"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Advances in technology have changed the way entertainment companies deliver captivating content to consumers. Combined with changing consumer behavior, an increase in mergers and acquisitions, and evolving distribution models, companies like yours are faced with new challenges and risks."])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "p", [["class", "is-disabled"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["With more than 50 years of experience in the entertainment industry, we understand the challenges and opportunities shaping the future, and we use our deep expertise, specialized products and services, and strong claims capabilities to help protect your business, regardless of its size."])), (_l()(), i1.ɵeld(15, 0, null, null, 4, "p", [["class", "is-disabled learn-more"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 3, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.nn.navigateTo() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Learn more\u00A0"])), (_l()(), i1.ɵeld(18, 0, null, null, 1, ":svg:svg", [["height", "10"], ["viewBox", "0 0 20 10"], ["width", "20"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 0, ":svg:path", [["d", "M3185.921-7514.8l6.178,6.178,6.178-6.178"], ["fill", "none"], ["stroke", "#fff"], ["stroke-width", "1"], ["transform", "translate(-3185.568 7515.154)"]], null, null, null, null, null))], function (_ck, _v) { _ck(_v, 5, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.state; _ck(_v, 1, 0, currVal_0); }); }
export function View_IntroComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-intro", [], null, [["window", "scroll"]], function (_v, en, $event) { var ad = true; if (("window:scroll" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).checkScroll($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_IntroComponent_0, RenderType_IntroComponent)), i1.ɵdid(1, 114688, null, 0, i4.IntroComponent, [i1.ElementRef, i5.Globals], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var IntroComponentNgFactory = i1.ɵccf("app-intro", i4.IntroComponent, View_IntroComponent_Host_0, {}, {}, []);
export { IntroComponentNgFactory as IntroComponentNgFactory };
