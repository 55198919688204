import { Component, HostListener, ElementRef, OnInit } from '@angular/core';
import { scrollToPage } from '../app.animations';
import {Globals} from '../globals';

@Component({
  selector: 'app-opportunity',
  templateUrl: './opportunity.component.html',
  styleUrls: ['./opportunity.component.scss'],
  animations: [scrollToPage]
})
export class OpportunityComponent implements OnInit {
  state = 'hide';
  offset = 0;

  constructor(public el: ElementRef, private globals: Globals) { }

  ngOnInit() {
  }

  @HostListener('window:scroll', ['$event']) checkScroll() {
    const ne = this.el.nativeElement,
          direction = window.pageYOffset > this.offset ? 'down' : 'up';

    this.offset = window.pageYOffset;

    if (window.pageYOffset >= ne.offsetTop - ne.offsetHeight / 2 && window.pageYOffset <= ne.offsetTop + ne.offsetHeight) {
      if (direction === 'down') {
        this.state = 'show';
      } else {
        this.state = 'fadein';
      }
    } else {
      this.state = 'hide';
    }
  }

  scrollStart(el) {
    const ne = this.el.nativeElement,
          hr = el.element.querySelectorAll('hr'),
          svg = el.element.querySelectorAll('svg');

    if (window.pageYOffset < (ne.offsetTop - 100) || window.pageYOffset > (ne.offsetTop + ne.offsetHeight + 100)) {
      hr.forEach(h => {
        h.style.transition = 'none';
        h.style.opacity = '0';

      });
      svg.forEach(e => {
        e.style.transition = 'none';
        e.style.opacity = '0';
      });
    }
  }

  scrollDone(el) {
    const hr = el.element.querySelectorAll('hr'),
        svg = el.element.querySelectorAll('svg, img');

    // if (this.globals.ignoreScroll === false) {
    //   if (this.state === 'show' || this.state === 'fade') {
    //     window.dataLayer.push({
    //       'event': 'irEvent',
    //       'eventCategory': 'Scroll (section)',
    //       'eventAction': 'Scrolled from Opportunity & Risk',
    //       'eventLabel': 'from Opportunity & Risk'
    //     });
    //     this.globals.ignoreScroll = true;
    //     setTimeout(() => {
    //       this.globals.ignoreScroll = false;
    //     }, 3000);
    //   }
    // }

    setTimeout(() => {
      hr.forEach(h => {
        h.style.transition = 'all 500ms ease-in';
        h.style.opacity = '1';
      });
    }, 50);

    svg.forEach(e => {
      e.style.transition = 'all 1000ms ease-out';
      e.style.opacity = '1';
    });
  }

}
