<div class="block--content clearfix">
  <div class="clearfix block--filler">
    <div class="block--img">
      <img
        src="{{ thisResource.img.src }}"
        alt="{{ thisResource.img.alt }} thumbnail photo"
        title="{{ thisResource.img.alt }} thumbnail photo"
      />
    </div>
    <div class="block--desc">
      <h3 class="resource__title">{{ thisResource.title }}</h3>
      <p>{{ thisResource.description }}</p>
    </div>
  </div>
  <div class="resource-btns">
    <app-btn-more *ngIf="thisResource.href !== ''" href="{{ thisResource.href }}" title="{{ thisResource.title }} (opens in a new window)" [ngClass]="'resource__btn'"></app-btn-more>
    <app-btn-share  *ngIf="thisResource.href"
        email_id="{{ thisResource.share.email_id }}"
        web_root="{{ thisResource.share.web_root }}"
        url="{{ thisResource.share.url }}"
        social_title="{{ thisResource.share.social_title }}"
        social_body="{{ thisResource.share.social_body }}"
        email_subject="{{ thisResource.share.email_subject }}"
        email_body="{{ thisResource.share.email_body }}"
        color="{{ thisResource.share.color }}"
        [ngClass]="'resource__btn'"
    ></app-btn-share>
  </div>
</div>
