<article class="main-section">
  <div class="parallax__wrap" [@scrollAnimation]="state" (@scrollAnimation.start)="scrollStart($event)" (@scrollAnimation.done)="scrollDone($event)">
    <section class="parallax__content">
      <h1 class="is-disabled">Prepare for growth &hellip; and change</h1>
      <hr />

      <div class="clearfix">
        <div class="outlook--intro">
          <h3 class="is-disabled">Our research-based partnership with The Ohio State University’s National Center for the Middle Market helps us understand the trends impacting your business…</h3>
          <p class="is-disabled">These trends suggest that businesses like yours will continue to grow, expand, and change as you capitalize on new opportunities to remain competitive.</p>
        </div>
        <!--
        <div class="outlook--logo">
          <img src="assets/img/outlook-logo@2x.png" alt="Chubb - National Center for the Middle Market" width="146" />
        </div>
        -->
      </div>



      <!-- <owl-carousel [options]="stats1Options" [carouselClasses]="['owl-theme', 'sliding', 'outlook--owl', 'outlook__slider', 'clearfix']" > -->

        <div data-slider="outlook-1" class="outlook__slider clearfix contentSlider owl-carousel">
          <!-- Stat Card -->
          <div class="item outlook__slide clearfix" *ngFor="let stat of stats1" [innerHTML]="stat"></div>
          <!-- /Stat Card -->
        </div>
      <!-- </owl-carousel> -->

     
        <p>More specifically, entertainment companies like yours are innovating to capitalize on a changing and growing industry:
        </p>

      <div data-slider="outlook-2" class="outlook__slider clearfix contentSlider owl-carousel">
        <!-- Stat Card -->
        <div class="item outlook__slide clearfix" *ngFor="let stat of stats2" [innerHTML]="stat"></div>
        <!-- /Stat Card -->
      </div>


     <p class="disclaim">
       1,2 <a target="_blank" title="Deloitte: 2018 Media and Entertainment Outlook (opens in new window)" href="https://www2.deloitte.com/tr/en/pages/technology-media-and-telecommunications/articles/media-and-entertainment-industry-outlook-trends0.html#">Deloitte, “2018 Media and Entertainment Outlook,” 11/9/2018</a><br>
       3 <a target="_blank" title="Statista: Video Streaming from 2019 to 2023 (opens in new window)" href="https://www.statista.com/outlook/206/109/video-streaming--svod-/united-states">Statista: The Statistics Portal, Video Streaming from 2019 to 2023</a>
      </p>

    </section>
  </div>
</article>

