<div id="find-agent" class="find-agent clearfix">
  <div class="find-agent--container clearfix">
    <div class="find-agent--content">
      <h2 class="find-agent__header">Find an agent</h2>
      <p class="find-agent__copy">Connect with an agent for a custom quote.</p>

      <a href="https://www.chubb.com/us-en/find-agent-page.aspx" target="_blank" title="Find an Agent in the U.S. (opens in new window)" data-cta="Find an Agent in the U.S." class="btn white">
        Find an Agent in the U.S.<fa-icon [icon]="['fas', 'angle-right']"></fa-icon>
      </a>

      &nbsp;

      <a href="https://www.chubb.com/ca-en/find-a-broker.aspx" target="_blank" title="Find an Agent in Canada (opens in new window)" data-cta="Find an Agent in Canada" class="btn white">
        Find an Agent in Canada<fa-icon [icon]="['fas', 'angle-right']"></fa-icon>
      </a>
    </div>
  </div>
</div>
