<article class="main-section">
  <div class="parallax__wrap" [@scrollAnimation]="state" (@scrollAnimation.start)="scrollStart($event)" (@scrollAnimation.done)="scrollDone($event)">
      <section class="parallax__content--center">
        <div>
          <app-logo></app-logo>
          <div class="stripe-left">
            <h1 class="is-disabled">Entertainment:<br>An Evolving Industry</h1>
            <h2 class="is-disabled">Advances in technology have changed the way entertainment companies deliver captivating content to consumers.  Combined with changing consumer behavior, an increase in mergers and acquisitions, and evolving distribution models, companies like yours are faced with new challenges and risks.</h2>
            <p class="is-disabled">With more than 50 years of experience in the entertainment industry, we understand the challenges and opportunities shaping the future, and we use our deep expertise, specialized products and services, and strong claims capabilities to help protect your business, regardless of its size.</p>
            <p class="is-disabled learn-more"><a (click)="nn.navigateTo()">Learn more&nbsp;<svg xmlns="http://www.w3.org/2000/svg" width="20" height="10" viewBox="0 0 20 10"><path d="M3185.921-7514.8l6.178,6.178,6.178-6.178" transform="translate(-3185.568 7515.154)" fill="none" stroke="#fff" stroke-width="1"/></svg></a></p>
          </div>
        </div>
      </section>
  </div>
</article>
