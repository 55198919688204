/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./navigation.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./navigation.component";
import * as i4 from "../globals";
var styles_NavigationComponent = [i0.styles];
var RenderType_NavigationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NavigationComponent, data: { "animation": [{ type: 7, name: "navBounce", definitions: [{ type: 0, name: "in", styles: { type: 6, styles: { color: "#f60" }, offset: null }, options: undefined }, { type: 0, name: "out", styles: { type: 6, styles: { color: "#333" }, offset: null }, options: undefined }, { type: 1, expr: "out => in", animation: [{ type: 4, styles: { type: 5, steps: [{ type: 6, styles: { transform: "translate3d(0,0,0)" }, offset: null }, { type: 6, styles: { transform: "translate3d(0,-3px,0)" }, offset: null }, { type: 6, styles: { transform: "translate3d(0,3px,0)" }, offset: null }, { type: 6, styles: { transform: "translate3d(0,-3px,0)" }, offset: null }] }, timings: ".5s ease-in-out" }], options: null }], options: {} }] } });
export { RenderType_NavigationComponent as RenderType_NavigationComponent };
function View_NavigationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "li", [["class", "full-nav__item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "a", [["class", "full-nav__link"]], [[8, "href", 4], [1, "data-cta", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigateTo(_v.context.$implicit.fragment) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { active: 0 }), (_l()(), i1.ɵted(4, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = "full-nav__link"; var currVal_3 = _ck(_v, 3, 0, (_co.navActive == _v.context.$implicit.fragment)); _ck(_v, 2, 0, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(2, "", _co.indexfile, "#", _v.context.$implicit.fragment, ""); var currVal_1 = _v.context.$implicit.title; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_4 = _v.context.$implicit.title; _ck(_v, 4, 0, currVal_4); }); }
export function View_NavigationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "header", [["class", "mobile-header clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "section", [["class", "mobile-header__content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "a", [["class", "mobile-header__trig"], ["data-cta", "Mobile Nav Trigger"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleMobileClass() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Introduction "])), (_l()(), i1.ɵeld(4, 0, null, null, 3, "figure", [["class", "mobile-header__logo mobile-header__logo--white"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "a", [["class", "mobile-header__link"], ["data-cta", "Chubb Logo"], ["href", "https://www.chubb.com/"], ["title", "Chubb"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "figcaption", [["class", "hidden"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Chubb Logo "])), (_l()(), i1.ɵeld(8, 0, null, null, 16, "nav", [["class", "full-nav is-disabled"], ["id", "index-nav"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 8, "section", [["class", "full-nav__helpers"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 7, "div", [["class", "full-nav__trig"], ["data-cta", "Nav Trigger"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleMobileClass() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 6, ":svg:svg", [["viewBox", "0 0 11.532 11.532"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 2, ":svg:defs", [], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, ":svg:style", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" .cross-a { fill: #fff; } "])), (_l()(), i1.ɵeld(15, 0, null, null, 2, ":svg:g", [["transform", "translate(-37.276 -25)"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 0, ":svg:rect", [["class", "cross-a"], ["height", "2.224"], ["transform", "translate(38.849 25) rotate(45)"], ["width", "14.085"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 0, ":svg:rect", [["class", "cross-a"], ["height", "2.224"], ["transform", "translate(37.276 34.959) rotate(-45)"], ["width", "14.085"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 6, "ul", [["class", "plain-list full-nav__list"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 3, "li", [["class", "full-nav__logo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 2, "a", [["class", "logo-black"], ["data-cta", "Chubb Logo"], ["href", "https://www.chubb.com/"], ["id", "full-nav__logo-link"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 1, "figcaption", [["class", "hidden"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Chubb Logo "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NavigationComponent_1)), i1.ɵdid(24, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.navItems; _ck(_v, 24, 0, currVal_0); }, null); }
export function View_NavigationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-navigation", [], null, [["window", "resize"], ["window", "scroll"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onResize($event) !== false);
        ad = (pd_0 && ad);
    } if (("window:scroll" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).checkScroll($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_NavigationComponent_0, RenderType_NavigationComponent)), i1.ɵdid(1, 114688, null, 0, i3.NavigationComponent, [i1.ElementRef, i4.Globals], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NavigationComponentNgFactory = i1.ɵccf("app-navigation", i3.NavigationComponent, View_NavigationComponent_Host_0, {}, {}, []);
export { NavigationComponentNgFactory as NavigationComponentNgFactory };
