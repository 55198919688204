<article class="main-section">
  <div class="parallax__wrap" [@scrollAnimation]="state" (@scrollAnimation.start)="scrollStart($event)" (@scrollAnimation.done)="scrollDone($event)">
    <section class="parallax__content">
      <div class="clearfix">
      <h1 class="is-disabled">Be ready for what’s next</h1>
      <hr />
      <h3 class="percent66 is-disabled">With changes come new opportunities. And with new opportunities come new risks for which you may not be adequately protected. Have you contemplated the risks emanating from the following…</h3>
    </div>
      <ul data-slider="opportunity_1" class="opportunity__slider clearfix ">
        <li class="opportunity__slide">
            <article class="side-by-side clearfix">
                <aside class="side-by-side__aside">
                  <h4 class="side-by-side__title">A greater demand for digital and personalized content</h4>
                </aside>
                <figure class="side-by-side__fig side-by-side__fig--1"></figure>
              </article>
        </li>

        <li class="opportunity__slide">
         <article class="side-by-side clearfix">
           <aside class="side-by-side__aside">
             <h4 class="side-by-side__title">Increased expectations for riskier, more lifelike productions</h4>
           </aside>
           <figure class="side-by-side__fig side-by-side__fig--2"></figure>
         </article>
        </li>

        <li class="opportunity__slide">
            <article class="side-by-side clearfix">
                <aside class="side-by-side__aside">
                  <h4 class="side-by-side__title">An increase in mergers and acquisitions</h4>
                </aside>
                <figure class="side-by-side__fig side-by-side__fig--3"></figure>
              </article>
        </li>

        <li class="opportunity__slide">
            <article class="side-by-side clearfix">
                <aside class="side-by-side__aside">
                  <h4 class="side-by-side__title">Emerging markets as a growing source of revenue</h4>
                </aside>
                <figure class="side-by-side__fig side-by-side__fig--4"></figure>
              </article>
        </li>
      </ul>
      <div class="opportunity__disclaim">
          The convergence of these disruptions leads to <strong>real threats to your business and future</strong> for which you may not be fully protected.        </div>
    </section>
  </div>
</article>
