import { Component, HostListener, ElementRef, OnInit } from '@angular/core';
import { navBounce } from '../app.animations';
import {Globals} from '../globals';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  animations: [navBounce]
})
export class NavigationComponent implements OnInit {
  navStart = false;
  navActive = 'intro';

  navItems = [
    {
      fragment: 'intro',
      title: 'Introduction',
      state: 'out',
      bgImage: 'assets/img/bg-intro@2x.jpg'
    },
    {
      fragment: 'outlook',
      title: 'Outlook',
      state: 'out',
      bgImage: ''
    },
    {
      fragment: 'opportunity',
      title: 'Opportunity & Risk',
      state: 'out',
      bgImage: 'assets/img/bg-opportunity@2x.jpg'
    },
    {
      fragment: 'expertise',
      title: 'Our Expertise',
      state: 'out',
      bgImage: 'assets/img/bg-work@2x.jpg'
    },
    { fragment: 'work', title: 'Work With Us', state: 'out', bgImage: '' },
    { fragment: 'learn', title: 'Learn More', state: 'out', bgImage: '' }
  ];
  currentNav = {
    fragment: 'intro',
    title: 'Introduction',
    state: 'out',
    bgImage: ''
  };

  public innerWidth: any;
  public isMobile = innerWidth > 920 ? false : true;
  indexfile = '';

  constructor(public el: ElementRef, public globals: Globals) {
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isMobile = window.innerWidth > 920 ? false : true;
    this.innerWidth = window.innerWidth;
    this.markActive();
    this.navSwap();
  }

  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.markActive();
    this.navSwap();
  }

  ngOnInit() {
    this.indexfile = (location.href.includes('index.aspx')) ? 'index.aspx' : '';

    // need to let page finish rendering before sending to anchor link
    setTimeout(() => {
      let hash = document.location.hash.substr(1);
      if (!hash) {
        hash = 'intro';
      }
      this.navigateTo(hash);
    }, 2500);

  }

  // Swap out titles with section data-title
  swapText() {
    const title = this.currentNav.title || 'Introduction';
    $('.mobile-header__trig').text(title);
  }

  markActive() {
    const _this = this;
    const blocks = Array.prototype.slice.call(
      document.querySelectorAll('.parallax--block'),
      0
    );

    blocks.forEach(function(b) {
      const block = document.getElementById(b.id);
      if (window.pageYOffset + 30 >= block.offsetTop) {
        _this.navActive = b.id;
        _this.currentNav = _this.navItems.filter(
          item => item.fragment === _this.navActive
        )[0];
        _this.toggleNavState(_this.navActive);
      }
    });
  }

  // Swap colored nav/logo
  navSwap() {
    const nav = document.querySelector('.full-nav'),
      logo = document.getElementById('full-nav__logo-link'),
      intro = document.getElementById('intro'),
      learn = document.getElementById('learn'),
      work = document.getElementById('work');

    if (window.pageYOffset + 50 >= intro.offsetHeight) { // all but first slide
      logo.classList.remove('logo-white');
      nav.classList.remove('full-nav--white');
      nav.classList.remove('hide');
      nav.classList.remove('is-disabled');
      if (window.pageYOffset + 50 >= learn.offsetTop) { // last page
        logo.classList.add('logo-white');
        nav.classList.add('full-nav--white');
        document.querySelector('.full-next').classList.add('is-disabled');// disable next arrow
      } else if (window.pageYOffset + 50 >= work.offsetTop) { // second-to last page
        logo.classList.add('logo-white');
        nav.classList.add('full-nav--white');
        document.querySelector('.full-next').classList.remove('is-disabled');// disable next arrow
      } else {
        document.querySelector('.full-next').classList.remove('is-disabled');// disable next arrow
      }
    } else { // first slide hide nav on desktop
      if (!this.isMobile) {
        nav.classList.remove('full-nav--white');
        nav.classList.remove('is-active');
        nav.classList.add('is-disabled');
        nav.classList.add('hide');
      }
      // enable next arrow
      document.querySelector('.full-next').classList.remove('is-disabled');
    }

    this.swapText(); // swap mobile header text
  }

  navigateTo(element: string) {
    const nav = document.querySelector('#' + element),
      mobNav = document.querySelector('.full-nav');

    mobNav.classList.remove('is-active');
    // document.location.hash = element;
    nav.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
    this.navActive = element;

    this.markActive();
    this.navSwap();

    nav.classList.remove('bg-' + element);
    setTimeout(function() {
      nav.classList.add('bg-' + element);
    }, 200);

    this.globals.ignoreScroll = true;
    setTimeout(() => {
      this.globals.ignoreScroll = false;
    }, 3000);
  }

  toggleNavState(frag) {
    this.navItems.forEach(n => (n.state = 'out'));
    this.navItems.filter(n => n.fragment === frag)[0].state = 'in';
  }

  toggleMobileClass() {
    const tclass = 'is-active',
      mobNav = document.querySelector('.full-nav'),
      hasClass = mobNav.classList.contains(tclass);
    if (hasClass) {
      mobNav.classList.remove(tclass);
      mobNav.classList.add('is-disabled');
    } else {
      mobNav.classList.remove('is-disabled');
      mobNav.classList.add(tclass);
    }
  }
}
