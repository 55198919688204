/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./claims-promise.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./claims-promise.component";
var styles_ClaimsPromiseComponent = [i0.styles];
var RenderType_ClaimsPromiseComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ClaimsPromiseComponent, data: {} });
export { RenderType_ClaimsPromiseComponent as RenderType_ClaimsPromiseComponent };
function View_ClaimsPromiseComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }); }
function View_ClaimsPromiseComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "r-table"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "col--content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["alt", "Quote icon"], ["class", "quote-top"], ["height", "31"], ["src", "assets/img/svg/quotes.svg"], ["width", "30"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ClaimsPromiseComponent_2)), i1.ɵdid(6, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_1 = _v.context.$implicit.description; _ck(_v, 6, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "col col--bg", (_v.context.index + 1), ""); _ck(_v, 1, 0, currVal_0); }); }
export function View_ClaimsPromiseComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "section", [["class", "parallax__content claims-promise"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Honoring our claims promise"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ClaimsPromiseComponent_1)), i1.ɵdid(4, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.panels; _ck(_v, 4, 0, currVal_0); }, null); }
export function View_ClaimsPromiseComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-claims-promise", [], null, null, null, View_ClaimsPromiseComponent_0, RenderType_ClaimsPromiseComponent)), i1.ɵdid(1, 114688, null, 0, i3.ClaimsPromiseComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ClaimsPromiseComponentNgFactory = i1.ɵccf("app-claims-promise", i3.ClaimsPromiseComponent, View_ClaimsPromiseComponent_Host_0, {}, {}, []);
export { ClaimsPromiseComponentNgFactory as ClaimsPromiseComponentNgFactory };
