/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./work.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../node_modules/ngx-owl-carousel/src/owl-carousel.component.ngfactory";
import * as i4 from "ngx-owl-carousel/src/owl-carousel.component";
import * as i5 from "../work-form/work-form.component.ngfactory";
import * as i6 from "../work-form/work-form.component";
import * as i7 from "../services/work.service";
import * as i8 from "../find-agent/find-agent.component.ngfactory";
import * as i9 from "../find-agent/find-agent.component";
import * as i10 from "./work.component";
import * as i11 from "../globals";
var styles_WorkComponent = [i0.styles];
var RenderType_WorkComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WorkComponent, data: { "animation": [{ type: 7, name: "scrollAnimation", definitions: [{ type: 0, name: "show", styles: { type: 6, styles: { opacity: 1 }, offset: null }, options: undefined }, { type: 0, name: "hide", styles: { type: 6, styles: { opacity: 0 }, offset: null }, options: undefined }, { type: 0, name: "fadein", styles: { type: 6, styles: { opacity: 1 }, offset: null }, options: undefined }, { type: 1, expr: "show => hide", animation: { type: 4, styles: { type: 6, styles: { opacity: 0 }, offset: null }, timings: "300ms ease-out" }, options: null }, { type: 1, expr: "hide => show", animation: { type: 4, styles: { type: 5, steps: [{ type: 6, styles: { transform: "translate3d(0,100%,0)", opacity: 0, offset: 0 }, offset: null }, { type: 6, styles: { transform: "translate3d(0,70%,0)", offset: 0.3 }, offset: null }, { type: 6, styles: { transform: "translate3d(0,30%,0)", offset: 0.6 }, offset: null }, { type: 6, styles: { transform: "translate3d(0,0,0)", opacity: 1, offset: 1 }, offset: null }] }, timings: "1500ms ease-in-out" }, options: null }, { type: 1, expr: "hide => fadein", animation: { type: 4, styles: { type: 6, styles: { opacity: 1 }, offset: null }, timings: "1000ms ease-in" }, options: null }, { type: 1, expr: "fadein => hide", animation: { type: 4, styles: { type: 6, styles: { opacity: 1 }, offset: null }, timings: "500ms ease-out" }, options: null }], options: {} }] } });
export { RenderType_WorkComponent as RenderType_WorkComponent };
function View_WorkComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "points clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "point"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }); }
function View_WorkComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 13, "div", [["class", "person-card"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "photo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["width", "192"]], [[8, "src", 4], [8, "alt", 0]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 10, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), (_l()(), i1.ɵeld(9, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [["class", "clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WorkComponent_2)), i1.ɵdid(12, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(13, 0, null, null, 1, "p", [["class", "quote"]], null, null, null, null, null)), (_l()(), i1.ɵted(14, null, ["", ""]))], function (_ck, _v) { var currVal_4 = _v.context.$implicit.points; _ck(_v, 12, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.photo.src, ""); var currVal_1 = i1.ɵinlineInterpolate(1, "Photo of ", _v.context.$implicit.name, ""); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = _v.context.$implicit.header; _ck(_v, 6, 0, currVal_2); var currVal_3 = _v.context.$implicit.name; _ck(_v, 8, 0, currVal_3); var currVal_5 = _v.context.$implicit.quote; _ck(_v, 14, 0, currVal_5); }); }
export function View_WorkComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "article", [["class", "main-section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 13, "div", [["class", "parallax__wrap"]], [[24, "@scrollAnimation", 0]], [[null, "@scrollAnimation.start"], [null, "@scrollAnimation.done"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("@scrollAnimation.start" === en)) {
        var pd_0 = (_co.scrollStart($event) !== false);
        ad = (pd_0 && ad);
    } if (("@scrollAnimation.done" === en)) {
        var pd_1 = (_co.scrollDone($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 12, "section", [["class", "work parallax__content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h1", [["class", "is-disabled"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["We\u2019re here to help"])), (_l()(), i1.ɵeld(5, 0, null, null, 0, "hr", [["class", "bpad"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 4, "owl-carousel", [], null, null, null, i3.View_OwlCarousel_0, i3.RenderType_OwlCarousel)), i1.ɵdid(7, 311296, null, 0, i4.OwlCarousel, [i1.IterableDiffers], { carouselClasses: [0, "carouselClasses"], options: [1, "options"] }, null), i1.ɵpad(8, 4), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_WorkComponent_1)), i1.ɵdid(10, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 1, "app-work-form", [], null, null, null, i5.View_WorkFormComponent_0, i5.RenderType_WorkFormComponent)), i1.ɵdid(12, 114688, null, 0, i6.WorkFormComponent, [i7.WorkService], null, null), (_l()(), i1.ɵeld(13, 0, null, null, 1, "app-find-agent", [], null, null, null, i8.View_FindAgentComponent_0, i8.RenderType_FindAgentComponent)), i1.ɵdid(14, 114688, null, 0, i9.FindAgentComponent, [], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _ck(_v, 8, 0, "owl-theme", "sliding", "work--owl", "clearfix"); var currVal_2 = _co.slideOptions; _ck(_v, 7, 0, currVal_1, currVal_2); var currVal_3 = _co.people; _ck(_v, 10, 0, currVal_3); _ck(_v, 12, 0); _ck(_v, 14, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.state; _ck(_v, 1, 0, currVal_0); }); }
export function View_WorkComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-work", [], null, [["window", "scroll"]], function (_v, en, $event) { var ad = true; if (("window:scroll" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).checkScroll($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_WorkComponent_0, RenderType_WorkComponent)), i1.ɵdid(1, 114688, null, 0, i10.WorkComponent, [i1.ElementRef, i11.Globals], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var WorkComponentNgFactory = i1.ɵccf("app-work", i10.WorkComponent, View_WorkComponent_Host_0, {}, {}, []);
export { WorkComponentNgFactory as WorkComponentNgFactory };
