<section id="webinar" class="webinar-registration clearfix webinar-fix">
    <div class="webinar-registration--container">
        <div class="webinar-registration--content clearfix">
            <h3 class="web-lead">Join us for a webinar</h3>
            <div class="clearfix">
                <div class="block left webinar-top">
                    <div class="block--content">
                        <h2>Entertainment</h2>
                        <strong>Presented by:</strong><br />
                        <p>Tim Ehrhart, SVP - Chubb Entertainment Industry Practice Leader</p>
                        <p>Tom Stewart, Executive Director, National Center for the Middle Market</p>
                    </div>
                </div>
                <div class="block webinar-block">
                    <div class="block--content purple" [ngClass]="this.comingSoon === true ? 'block--soon' : ''">
                        <form class="webinar-registration__form" (submit)="gotoOption()" target="_blank">
                            <div *ngIf="comingSoon"> 
                                <h3 class="webinar-registration__legend">Webinar Coming Soon</h3>
                            </div>
                            <div *ngIf="!comingSoon">
                                <legend class="webinar-registration__legend">Choose a time</legend>
                            </div>
                            <div class="select-wrapper">
                                <select [disabled]="this.comingSoon ? true : false" [(ngModel)]="selectedOption" data-drop="webinar" name="webinar-registration__select--advman" id="webinar-registration__select--advman"
                                    class="webinar-registration__select select-styled">
                                    <option selected value="sort">{{this.comingSoon ? 'Check back soon!' : 'Times available:'}}</option>
                                    <option value="#">
                                    September 8th, 2019 | 1:30pm &ndash; 3:30pm</option>
                                </select>
                            </div>
                            <button [disabled]="this.comingSoon ? true : false" type="submit" name="submit" value="Submit" class="btn white" data-cta="Webinar: Register" title="Webinar: Register (opens in a new window)">Register&nbsp;<fa-icon [icon]="['fas', 'angle-right']"></fa-icon></button>
                            <!-- <a href="https://register.gotowebinar.com/register/8044914768462415106" target="_blank" title="Watch Now" class="btn white" data-cta="Watch Now">Watch Now
                                <svg class="btn-rsa" xmlns="http://www.w3.org/2000/svg" viewBox="3590.646 9513.646 6.061 10.707">
                                    <path id="Path_2083" data-name="Path 2083" class="carat" d="M412.5,194.5l5,5-5,5" transform="translate(3178.5 9319.5)"></path>
                                </svg>
                            </a> -->
                            <!--<p class="webinar-validation">Please select an option above.</p>-->
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
