import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-claims-promise',
  templateUrl: './claims-promise.component.html',
  styleUrls: ['./claims-promise.component.scss']
})
export class ClaimsPromiseComponent implements OnInit {

  panels = [
    {
      background: `/assets/img/claims1.jpg`,
      description: [
        `A young actress was scheduled to shoot as the main character for a production, but on the morning of the first day of principal photography, she received threats on social media from a stalker claiming he would come to the set. This spooked her, and production was delayed for one day. The insured proceeded to hire extra security for the set and had to work with the police to ensure the set was safe for the whole cast and crew. Chubb’s Film Producers Risk policy paid for the delay in production, as well as added expenses incurred by production to secure the set.`,
      ]
    },
    {
      background: `/assets/img/claims2.jpg`,
      description: [
        `An employee of an equipment rental house obtained identification and credit card information and rented out equipment to a new production company. The equipment was not returned as scheduled after the three-day rental. The employee attempted to contact the renter but quickly realized that he had been given false identification and credit card information. The equipment was never returned and considered stolen. Chubb paid to replace the equipment and for the loss of business income while it was being replaced.`,
      ]
    },
    {
      background: `/assets/img/claims3.jpg`,
      description: [
        `A water pipe burst in a theater, resulting in extensive damage to the set, as well as props and wardrobe. In addition, the incident caused the cancellation of two performances, forcing management to refund two nights’ box office receipts. Chubb’s Theatrical Productions Package responded to the property damage and reimbursed the insured for the lost income due to the business interruption.`,
      ]
    }
  ];

  constructor() { }

  ngOnInit() {
  }

}
