import { Component, OnInit, HostListener } from '@angular/core';
import {Globals} from '../globals';

@Component({
  selector: 'app-navigation-next',
  templateUrl: './navigation-next.component.html',
  styleUrls: ['./navigation-next.component.scss']
})
export class NavigationNextComponent implements OnInit {

  indexfile = '';

  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.nextSection();
  }

  constructor(public globals: Globals) {
  }

  ngOnInit() {
    const next = document.querySelector('.full-next');
    this.indexfile = (location.href.includes('index.aspx')) ? 'index.aspx' : '';
    setTimeout(to => {
      this.nextSection();
    }, 4000);
    setTimeout(() => {
      next.classList.remove('is-disabled');
    }, 2500);
  }

  nextSection() {
    const blocks = document.querySelectorAll('.main-section');
    for (let i = 0; i < blocks.length; i++) {// Needs to be a breakable loop (forEach is not)
      const block = document.getElementById(blocks[i].parentElement.id),
        navNext = document.querySelector('#navNext');
      if (window.pageYOffset < block.offsetTop - 30) {
        navNext.setAttribute('href', this.indexfile + '#' + blocks[i].parentElement.id);
        break;
      }
    }
  }

  navigateTo() {
    const nav = document.querySelector('#navNext').getAttribute('href'),
          target = document.querySelector(nav);
    target.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
  }
}
