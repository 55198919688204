<div class="report--content">
  <hr />
  <div class="clearfix report--filler">
    <div class="report__top clearfix">
        <div class="report--img">
          <img
            src="{{ thisReport.img.src }}"
            alt="{{ thisReport.img.alt }} thumbnail photo"
            title="{{ thisReport.img.alt }} thumbnail photo"
          />
        </div>
        <div class="report--desc">
          <h3>{{ thisReport.title }}</h3>
          <p>{{ thisReport.description }}</p>
          <div class="report-btns">
              <app-btn-download ngClass="report-btn" *ngIf="thisReport.href !== ''" href="{{ thisReport.href }}" title="{{ thisReport.title }} (opens in new window)"></app-btn-download>
              <app-btn-share ngClass="report-btn report-btn--share"  *ngIf="thisReport.href"
                  email_id="{{ thisReport.share.email_id }}"
                  web_root="{{ thisReport.share.web_root }}"
                  url="{{ thisReport.share.url }}"
                  social_title="{{ thisReport.share.social_title }}"
                  social_body="{{ thisReport.share.social_body }}"
                  email_subject="{{ thisReport.share.email_subject }}"
                  email_body="{{ thisReport.share.email_body }}"
                  color="{{ thisReport.share.color }}"
              ></app-btn-share>
            </div>
        </div>
    </div>

  </div>

  <hr class="white" />
</div>
