/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./intro/intro.component.ngfactory";
import * as i3 from "./intro/intro.component";
import * as i4 from "./globals";
import * as i5 from "./outlook/outlook.component.ngfactory";
import * as i6 from "./outlook/outlook.component";
import * as i7 from "@angular/platform-browser";
import * as i8 from "./opportunity/opportunity.component.ngfactory";
import * as i9 from "./opportunity/opportunity.component";
import * as i10 from "./expertise/expertise.component.ngfactory";
import * as i11 from "./expertise/expertise.component";
import * as i12 from "./work/work.component.ngfactory";
import * as i13 from "./work/work.component";
import * as i14 from "./learn/learn.component.ngfactory";
import * as i15 from "./learn/learn.component";
import * as i16 from "./navigation-next/navigation-next.component.ngfactory";
import * as i17 from "./navigation-next/navigation-next.component";
import * as i18 from "./navigation/navigation.component.ngfactory";
import * as i19 from "./navigation/navigation.component";
import * as i20 from "./app.component";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_AppComponent, data: { "animation": [{ type: 7, name: "scrollAnimation", definitions: [{ type: 0, name: "show", styles: { type: 6, styles: { opacity: 1 }, offset: null }, options: undefined }, { type: 0, name: "hide", styles: { type: 6, styles: { opacity: 0 }, offset: null }, options: undefined }, { type: 0, name: "fadein", styles: { type: 6, styles: { opacity: 1 }, offset: null }, options: undefined }, { type: 1, expr: "show => hide", animation: { type: 4, styles: { type: 6, styles: { opacity: 0 }, offset: null }, timings: "300ms ease-out" }, options: null }, { type: 1, expr: "hide => show", animation: { type: 4, styles: { type: 5, steps: [{ type: 6, styles: { transform: "translate3d(0,100%,0)", opacity: 0, offset: 0 }, offset: null }, { type: 6, styles: { transform: "translate3d(0,70%,0)", offset: 0.3 }, offset: null }, { type: 6, styles: { transform: "translate3d(0,30%,0)", offset: 0.6 }, offset: null }, { type: 6, styles: { transform: "translate3d(0,0,0)", opacity: 1, offset: 1 }, offset: null }] }, timings: "1500ms ease-in-out" }, options: null }, { type: 1, expr: "hide => fadein", animation: { type: 4, styles: { type: 6, styles: { opacity: 1 }, offset: null }, timings: "1000ms ease-in" }, options: null }, { type: 1, expr: "fadein => hide", animation: { type: 4, styles: { type: 6, styles: { opacity: 1 }, offset: null }, timings: "500ms ease-out" }, options: null }], options: {} }] } });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { navigation: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 18, "section", [["class", "main "], ["role", "main"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "section", [["class", "loader-wrap"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 12, "section", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-intro", [["class", "parallax--block intro clearfix"], ["id", "intro"]], null, [["window", "scroll"]], function (_v, en, $event) { var ad = true; if (("window:scroll" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).checkScroll($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IntroComponent_0, i2.RenderType_IntroComponent)), i1.ɵdid(5, 114688, null, 0, i3.IntroComponent, [i1.ElementRef, i4.Globals], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-outlook", [["class", "parallax--block outlook clearfix"], ["id", "outlook"]], null, [["window", "scroll"]], function (_v, en, $event) { var ad = true; if (("window:scroll" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).checkScroll($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_OutlookComponent_0, i5.RenderType_OutlookComponent)), i1.ɵdid(7, 114688, null, 0, i6.OutlookComponent, [i1.ElementRef, i7.DomSanitizer, i4.Globals], null, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "app-opportunity", [["class", "parallax--block opportunity clearfix"], ["id", "opportunity"]], null, [["window", "scroll"]], function (_v, en, $event) { var ad = true; if (("window:scroll" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).checkScroll($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_OpportunityComponent_0, i8.RenderType_OpportunityComponent)), i1.ɵdid(9, 114688, null, 0, i9.OpportunityComponent, [i1.ElementRef, i4.Globals], null, null), (_l()(), i1.ɵeld(10, 0, null, null, 1, "app-expertise", [["class", "parallax--block expertise clearfix"], ["id", "expertise"]], null, [["window", "scroll"]], function (_v, en, $event) { var ad = true; if (("window:scroll" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11).checkScroll($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_ExpertiseComponent_0, i10.RenderType_ExpertiseComponent)), i1.ɵdid(11, 114688, null, 0, i11.ExpertiseComponent, [i1.ElementRef, i4.Globals], null, null), (_l()(), i1.ɵeld(12, 0, null, null, 1, "app-work", [["class", "parallax--block work clearfix"], ["id", "work"]], null, [["window", "scroll"]], function (_v, en, $event) { var ad = true; if (("window:scroll" === en)) {
        var pd_0 = (i1.ɵnov(_v, 13).checkScroll($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i12.View_WorkComponent_0, i12.RenderType_WorkComponent)), i1.ɵdid(13, 114688, null, 0, i13.WorkComponent, [i1.ElementRef, i4.Globals], null, null), (_l()(), i1.ɵeld(14, 0, null, null, 1, "app-learn", [["class", "bg-grey block parallax--block learn clearfix"], ["id", "learn"]], null, [["window", "scroll"]], function (_v, en, $event) { var ad = true; if (("window:scroll" === en)) {
        var pd_0 = (i1.ɵnov(_v, 15).checkScroll($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i14.View_LearnComponent_0, i14.RenderType_LearnComponent)), i1.ɵdid(15, 114688, null, 0, i15.LearnComponent, [i1.ElementRef, i4.Globals], null, null), (_l()(), i1.ɵeld(16, 0, null, null, 1, "app-navigation-next", [], null, [["window", "scroll"]], function (_v, en, $event) { var ad = true; if (("window:scroll" === en)) {
        var pd_0 = (i1.ɵnov(_v, 17).checkScroll($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i16.View_NavigationNextComponent_0, i16.RenderType_NavigationNextComponent)), i1.ɵdid(17, 114688, null, 0, i17.NavigationNextComponent, [i4.Globals], null, null), (_l()(), i1.ɵeld(18, 0, null, null, 1, "app-navigation", [], null, [["window", "resize"], ["window", "scroll"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 19).onResize($event) !== false);
        ad = (pd_0 && ad);
    } if (("window:scroll" === en)) {
        var pd_1 = (i1.ɵnov(_v, 19).checkScroll($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i18.View_NavigationComponent_0, i18.RenderType_NavigationComponent)), i1.ɵdid(19, 114688, [[1, 4]], 0, i19.NavigationComponent, [i1.ElementRef, i4.Globals], null, null)], function (_ck, _v) { _ck(_v, 5, 0); _ck(_v, 7, 0); _ck(_v, 9, 0); _ck(_v, 11, 0); _ck(_v, 13, 0); _ck(_v, 15, 0); _ck(_v, 17, 0); _ck(_v, 19, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 114688, null, 0, i20.AppComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i20.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
