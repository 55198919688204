import { Component, HostListener, ElementRef, OnInit } from '@angular/core';
import { scrollToPage } from '../app.animations';
import {Globals} from '../globals';

@Component({
  selector: 'app-work',
  templateUrl: './work.component.html',
  styleUrls: ['./work.component.scss'],
  animations: [scrollToPage]
})
export class WorkComponent implements OnInit {

  slideOptions = { items: 1, dots: false, nav: false };
  people = [
    {
      name: `Tim Ehrhart`,
      header: `Meet our Entertainment Industry Practice Leader`,
      photo: {
        src: `assets/img/tim-ehrhart.png`
      },
      points: [`Over 32 years of insurance experience with Chubb`, `10+ years dedicated to entertainment`],
      quote: `“With the fast-paced changing environment of content delivery, and a move toward greater globalization, we take the time to understand the uniqueness of each entertainment client and build custom solutions to address the risks they face.”`
    }
  ];

  state = 'hide';
  offset = 0;

  constructor(public el: ElementRef, private globals: Globals) { }

  ngOnInit() {
  }

  @HostListener('window:scroll', ['$event']) checkScroll() {
    const ne = this.el.nativeElement,
          direction = window.pageYOffset > this.offset ? 'down' : 'up';

    this.offset = window.pageYOffset;

    if (window.pageYOffset >= ne.offsetTop - ne.offsetHeight / 2 && window.pageYOffset <= ne.offsetTop + ne.offsetHeight) {
      if (direction === 'down') {
         this.state = 'show';
      } else {
        this.state = 'fadein';
      }
    } else {
      this.state = 'hide';
    }
  }

  scrollStart(el) {
    const ne = this.el.nativeElement,
      hr = el.element.querySelectorAll('hr'),
      svg = el.element.querySelectorAll('svg');

    if (window.pageYOffset < (ne.offsetTop - 100) || window.pageYOffset > (ne.offsetTop + ne.offsetHeight + 100)) {
      hr.forEach(h => {
        h.style.transition = 'none';
        h.style.opacity = '0';
      });
      svg.forEach(e => {
        e.style.transition = 'none';
        e.style.opacity = '0';
      });
    }
  }

  scrollDone(el) {
    const hr = el.element.querySelectorAll('hr'),
      svg = el.element.querySelectorAll('svg, img');

    // if (this.globals.ignoreScroll === false) {
    //   if (this.state === 'show' || this.state === 'fade') {
    //     window.dataLayer.push({
    //       'event': 'irEvent',
    //       'eventCategory': 'Scroll (section)',
    //       'eventAction': 'Scrolled from Work With Us',
    //       'eventLabel': 'from Work With Us'
    //     });
    //     this.globals.ignoreScroll = true;
    //     setTimeout(() => {
    //       this.globals.ignoreScroll = false;
    //     }, 3000);
    //   }
    // }

    setTimeout(() => {
      hr.forEach(h => {
        h.style.transition = 'all 500ms ease-in';
        h.style.opacity = '1';
      });
      setTimeout(() => {
        svg.forEach(e => {
          e.style.transition = 'all 600ms ease-out';
          e.style.opacity = '1';
        });
      }, 100);
    }, 250);
  }

}
