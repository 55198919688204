import { Component, HostListener, ElementRef, OnInit } from '@angular/core';
import { scrollToPage } from '../app.animations';
import {Globals} from '../globals';

@Component({
  selector: 'app-learn',
  templateUrl: './learn.component.html',
  styleUrls: ['./learn.component.scss'],
  animations: [scrollToPage]
})
export class LearnComponent implements OnInit {
  state = 'hide';
  offset = 0;
  webRoot = window.location.href.replace(window.location.hash, ``).replace(`index.aspx`, ``);

  reports = [
    {
      img: {
        src: 'assets/img/benchmarking-report.jpg',
        alt: `Benchmarking Report`
      },
      title: `Benchmarking Report`,
      href: `assets/pdf/Chubb_Benchmarking_Report.pdf`,
      description: `See what insurance products your peers are buying. Also, get a glimpse into the claims we are seeing in the entertainment space, and how you can mitigate against those risks. View the report and ask your agent if you are adequately protected.`,
      share: {
        email_id: '6_2',
        web_root: this.webRoot,
        url: 'assets/pdf/Chubb_Benchmarking_Report.pdf',
        social_title: 'Chubb’s Benchmarking report',
        social_body: 'Check out Chubb’s benchmarking report to see what insurance products entertainment companies are purchasing and some claims-related insights.',
        email_subject: `Chubb's Entertainment Expertise`,
        email_body: 'Check out Chubb’s benchmarking report to see what insurance products entertainment companies are purchasing and some claims-related insights.',
        color: ''
      }
    },
    {
      img: {
        src: 'assets/img/overview-thumb.jpg',
        alt: `Entertainment Industry Practice Overview`
      },
      title: `Entertainment Industry Practice Overview`,
      href: `assets/pdf/T2_Entertainment.pdf`,
      description: `Learn more about our entertainment expertise, risk engineering, and claims services. Review this with your agent and ask for a Chubb quote.`,
      share: {
        email_id: '6_3',
        web_root: this.webRoot,
        url: 'assets/pdf/T2_Entertainment.pdf',
        social_title: `Chubb's Entertainment Expertise`,
        social_body: 'Check out how Chubb is well-positioned to proactively help entertainment businesses face an ever-expanding array of complex exposures.',
        email_subject: `Chubb's Entertainment Expertise`,
        email_body: 'Check out how Chubb is well-positioned to proactively help entertainment businesses face an ever-expanding array of complex exposures.',
        color: ''
      }
    }

  ];

  resources = [
    {
      img: {
        src: 'assets/img/feature-film.jpg',
        alt: `Feature Film and Television Production Site Evaluation Survey`
      },
      title: `Feature Film and Television Production Site Evaluation Survey`,
      href: `assets/pdf/Feature_Film_and_Television_Production_Site_Evaluation_Survey.pdf`,
      description: ``,
      share: {
        email_id: '7_1',
        web_root: this.webRoot,
        url: 'assets/pdf/Feature_Film_and_Television_Production_Site_Evaluation_Survey.pdf',
        social_title: `Chubb's Entertainment Expertise`,
        social_body: 'Take advantage of Chubb’s expertise in the feature film and television production business by utilizing their check list to conduct a production site evaluation survey.',
        email_subject: `Chubb's Entertainment Expertise`,
        email_body: 'Take advantage of Chubb’s expertise in the feature film and television production business by utilizing their check list to conduct a production site evaluation survey.',
        color: ''
      }
    },
    {
      img: {
        src: 'assets/img/preventing-film-equipment-theft.jpg',
        alt: `Preventing Film Equipment Theft`
      },
      title: `Preventing Film Equipment Theft`,
      href: `assets/pdf/Preventing_Film_Equipment_Theft.pdf`,
      description: ``,
      share: {
        email_id: '7_2',
        web_root: this.webRoot,
        url: 'assets/pdf/Preventing_Film_Equipment_Theft.pdf',
        social_title: `Chubb's Entertainment Expertise`,
        social_body: 'Check out Chubb’s risk mitigation checklist for Film and Television Producers that helps prevent film equipment theft.',
        email_subject: `Chubb's Entertainment Expertise`,
        email_body: 'Check out Chubb’s risk mitigation checklist for Film and Television Producers that helps prevent film equipment theft.',
        color: ''
      }
    },
    {
      img: {
        src: 'assets/img/protecting-digital-media.jpg',
        alt: `Protecting Digital Media`
      },
      title: `Protecting Digital Media`,
      href: `assets/pdf/Protecting_Digital_Media.pdf`,
      description: ``,
      share: {
        email_id: '7_3',
        web_root: this.webRoot,
        url: 'assets/pdf/Protecting_Digital_Media.pdf',
        social_title: `Chubb's Entertainment Expertise`,
        social_body: 'Check out Chubb’s risk mitigation checklist for Film Producers that helps protect your digital media.',
        email_subject: `Chubb's Entertainment Expertise`,
        email_body: 'Check out Chubb’s risk mitigation checklist for Film Producers that helps protect your digital media.',
        color: ''
      }
    },
    {
      img: {
        src: 'assets/img/vehicle-risk-management.jpg',
        alt: `Vehicle Risk Management for Feature Film and Television Production`
      },
      title: `Vehicle Risk Management for Feature Film and Television Production`,
      href: `assets/pdf/Vehicle_Risk_Management_for_Feature_Film_and_Television.pdf`,
      description: ``,
      share: {
        email_id: '7_4',
        web_root: this.webRoot,
        url: 'assets/pdf/Vehicle_Risk_Management_for_Feature_Film_and_Television.pdf',
        social_title: `Chubb's Entertainment Expertise`,
        social_body: 'Check out Chubb’s risk mitigation check list for Film Producers looking to practice fleet management fundamentals.',
        email_subject: `Chubb's Entertainment Expertise`,
        email_body: 'Check out Chubb’s risk mitigation check list for Film Producers looking to practice fleet management fundamentals.',
        color: ''
      }
    }

  ];

  constructor(public el: ElementRef, private globals: Globals) {}

  ngOnInit() {}

  @HostListener('window:scroll', ['$event']) checkScroll() {
    const ne = this.el.nativeElement,
          direction = window.pageYOffset > this.offset ? 'down' : 'up';

          this.offset = window.pageYOffset;

          if (window.pageYOffset >= ne.offsetTop - ne.offsetHeight / 2 && window.pageYOffset <= ne.offsetTop + ne.offsetHeight) {
            if (direction === 'down') {
              // this.state = 'show';
              this.state = 'fadein';
            } else {
              this.state = 'fadein';
            }
          } else {
            this.state = 'hide';
          }
        }

  scrollStart(el) {
    const ne = this.el.nativeElement,
      hr = el.element.querySelectorAll('hr'),
      svg = el.element.querySelectorAll('svg');

    if (window.pageYOffset < (ne.offsetTop - 100) || window.pageYOffset > (ne.offsetTop + ne.offsetHeight + 100)) {
      hr.forEach(h => {
        h.style.transition = 'none';
        h.style.opacity = '0';

      });
      svg.forEach(e => {
        e.style.transition = 'none';
        e.style.opacity = '0';
      });
    }
  }

  scrollDone(el) {
    const hr = el.element.querySelectorAll('hr'),
        svg = el.element.querySelectorAll('svg, img');

    // if (this.globals.ignoreScroll === false) {
    //   if (this.state === 'show' || this.state === 'fade') {
    //     window.dataLayer.push({
    //       'event': 'irEvent',
    //       'eventCategory': 'Scroll (section)',
    //       'eventAction': 'Scrolled from Learn More',
    //       'eventLabel': 'from Learn More'
    //     });
    //     this.globals.ignoreScroll = true;
    //     setTimeout(() => {
    //       this.globals.ignoreScroll = false;
    //     }, 3000);
    //   }
    // }

    setTimeout(() => {
      hr.forEach(h => {
        h.style.transition = 'all 500ms ease-in';
        h.style.opacity = '1';
      });
      setTimeout(() => {
        svg.forEach(e => {
          e.style.transition = 'all 600ms ease-out';
          e.style.opacity = '1';
        });
      }, 100);
    }, 250);
  }
}
