import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss']
})
export class LogoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    // Set intro anim
    // if ($('#full')) {
    //   setTimeout(() => {
    //       const loader = $('.loader-wrap');
    //       loader.addClass('is-disabled');
    //   }, 1000);
    //   setTimeout(() => {
    //       const heading = $('.full__slide--intro h1, .full__slide--intro h2'),
    //           content = $('.full__slide--intro p'),
    //           next = $('.full-next');

    //       heading.removeClass('is-disabled');
    //       setTimeout(() => {
    //           next.removeClass('is-disabled');
    //           content.removeClass('is-disabled');
    //       }, 2000);
    //   }, 2000);
    // }
  }

}
