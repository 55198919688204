<!-- Main Nav -->
<!-- Mobile Header -->
<header class="mobile-header clearfix">
  <section class="mobile-header__content">
    <a
      class="mobile-header__trig"
      data-cta="Mobile Nav Trigger"
      (click)="toggleMobileClass()"
    >
      Introduction
    </a>
  </section>
  <figure class="mobile-header__logo mobile-header__logo--white">
    <a class="mobile-header__link" data-cta="Chubb Logo" href="https://www.chubb.com/" title="Chubb">
      <figcaption class="hidden">
        Chubb Logo
      </figcaption>
    </a>
  </figure>
</header>
<!-- /Mobile Header -->
<nav id="index-nav" class="full-nav is-disabled">
  <!-- Mobile Helpers -->
  <section class="full-nav__helpers">
    <div
      class="full-nav__trig"
      data-cta="Nav Trigger"
      (click)="toggleMobileClass()"
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.532 11.532">
        <defs>
          <style>
            .cross-a {
              fill: #fff;
            }
          </style>
        </defs>
        <g transform="translate(-37.276 -25)">
          <rect
            class="cross-a"
            width="14.085"
            height="2.224"
            transform="translate(38.849 25) rotate(45)"
          />
          <rect
            class="cross-a"
            width="14.085"
            height="2.224"
            transform="translate(37.276 34.959) rotate(-45)"
          />
        </g>
      </svg>
    </div>
  </section>

  <!-- /Mobile Helpers -->
  <!-- [@navBounce]="ni.state" -->
  <ul class="plain-list full-nav__list">

    <!--nav logo-->
    <li class="full-nav__logo">
      <a id="full-nav__logo-link" class="logo-black" data-cta="Chubb Logo" href="https://www.chubb.com/">
          <figcaption class="hidden">
            Chubb Logo
          </figcaption>
      </a>
    </li>

    <!--nav links-->
    <li
      class="full-nav__item"
      *ngFor="let ni of navItems"
    >
      <a
        href="{{indexfile}}#{{ ni.fragment }}"
        (click)="navigateTo(ni.fragment)"
        class="full-nav__link"
        [ngClass]="{ active: navActive == ni.fragment }"
        [attr.data-cta]="ni.title"
        >{{ ni.title }}</a
      >
    </li>
  </ul>
</nav>
