import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-webinar',
  templateUrl: './webinar.component.html',
  styleUrls: ['./webinar.component.scss']
})
export class WebinarComponent implements OnInit {

  selectedOption = `sort`;

  comingSoon = true;

  constructor() { }

  ngOnInit() {
  }

  gotoOption() {
    if (this.selectedOption === 'sort') {
      alert('Please select a date.');
    } else {
      window.open(this.selectedOption, '_blank');
    }
  }
}
