<article class="main-section">
  <div class="parallax__wrap" [@scrollAnimation]="state" (@scrollAnimation.start)="scrollStart($event)" (@scrollAnimation.done)="scrollDone($event)">
    <section class="parallax__content">
      <h1 class="is-disabled">Empower yourself</h1>
      <hr />
      <app-webinar></app-webinar>

      <hr />

      <section class="report clearfix">
        <div class="report--container">
          <div class="report-wrap">
          <div class="report--content clearfix r-table">
            <app-report
              class="container-fluid"
              ngClass="col"
              *ngFor="let report of reports"
              [thisReport]="report"
            ></app-report>
          </div>
        </div>
        </div>
      </section>

      <hr />

      <section class="resources clearfix">
        <h3 class="resource-header">Self-Service Risk Mitigation Check Lists</h3>
        <div class="resource--container">
            <div class="resource-wrap">
                <div class="resource--content clearfix r-table">
                    <app-resource
                      class="container-fluid"
                      ngClass="col"
                      *ngFor="let resource of resources"
                      [thisResource]="resource"
                    ></app-resource>
                </div>
            </div>
        </div>
      </section>

    </section>
  </div>
</article>
