import { Component, HostListener, ElementRef, OnInit, ViewChild } from '@angular/core';
import { scrollToPage } from '../app.animations';
import {Globals} from '../globals';

@Component({
  selector: 'app-expertise',
  templateUrl: './expertise.component.html',
  styleUrls: ['./expertise.component.scss'],
  animations: [scrollToPage]
})
export class ExpertiseComponent implements OnInit {

  constructor(public el: ElementRef, private globals: Globals) {}

  slides = [
    {
      title: `Film production studio`,
      icon: {
        src: `assets/img/svg/film.svg`,
        alt: `Film icon`
      },
      points: [
        `Films in exotic locations`,
        `Owns property in six countries`,
        `Large insurable values`
      ],
      description: `This fast growing, international film producer required a partner that understands complex property risks, can provide insurance for international exposures, and has the capacity to offer high limits of protection. We were able to craft a holistic solution by integrating property insurance with our dedicated Film Producers Risk product, multinational, accident and health, and kidnap and ransom solutions.`,
      difference: [
        `Integrity+ by Chubb, including additive limits for first party and impairment of computer services`,
        `Ability to provide a seamless, global solution`
      ]
    },
    {
      title: `Theatrical production company`,
      icon: {
        src: `assets/img/svg/theater.svg`,
        alt: `Theater icon`
      },
      points: [
        `Expanding production overseas`,
        `Expensive production equipment`,
        `Unique workers’ comp exposures`
      ],
      description: `With a very complex operation, this client was interested in partnering with a carrier that could offer sophisticated risk engineering services to address their unique workers’ compensation exposures.  In addition, they needed a carrier that could grow with them as they expand their production company internationally and enter new markets.  We were able to provide integrated solutions to meet their insurance needs.`,
      difference: [
        `Sophisticated risk engineering program`,
        `Flexibility with structure, particularly with products on a global basis`
      ]
    },
    {
      title: `Equipment rental house`,
      icon: {
        src: `assets/img/svg/rental.svg`,
        alt: `Projector icon`
      },
      points: [
        `Rents high-value equipment`,
        `Transports expensive equipment`,
        `Unique revenue streams`
      ],
      description: `This client required a solution to address the renting out and transportation of high-value equipment.  In addition, with complex revenue streams, they required an insurance partner with deep expertise in business income insurance. We were able to craft a solution that integrated property and casualty insurance products with insurance for miscellaneous owned equipment.`,
      difference: [
        `Integrated solution incorporating specialty products for marine and aviation exposures`,
        `Ability to consolidate all lines with one carrier`
      ]
    }
  ];
  slideOptions = { items: 1, dots: true, nav: true, onChanged: this.owlChanged };
  state = 'hide';
  slideDone = false;
  offset = 0;

  webRoot = window.location.href.replace(window.location.hash, ``);

  videos = [
    {
      id: 'video-craftsmen',
      href: `https://f1.media.brightcove.com/12/818971943001/818971943001_5406608717001_5406599348001.mp4?pubId=818971943001&videoId=5406599348001`, //from https://pasty.link, use sharable URL (players.brightcove.net/...)
      type: `video/mp4`,
      poster: `assets/img/vid-still.jpg`,
      title: `Craftsmen of Insurance`,
      description: `Meet Chubb Risk Engineer Steven Serafin, sharing, in his words, the expertise and commitment to excellence that goes into serving every Chubb client.`,
      share: {
        email_id: '10_1',
        web_root: ``,
        url: `http://players.brightcove.net/818971943001/default_default/index.html?videoId=5406599348001`,
        social_title: `Chubb's Entertainment Expertise`,
        social_body: 'Meet Chubb Risk Engineer Steven Serafin, sharing, in his words, the expertise and commitment to excellence that goes into serving every Chubb client.',
        email_subject: `Watch Craftsmen of Insurance℠ - Steven's Story`,
        email_body: 'Meet Chubb Risk Engineer Steven Serafin, sharing, in his words, the expertise and commitment to excellence that goes into serving every Chubb client.',
        color: 'btn--white'
      }
    }
  ];

  ngOnInit() {
  }

  owlChanged(e) {
    window.dataLayer.push({
      'event': 'carouselEvent',
      'eventCategory': 'Carousel Click',
      'eventAction': 'Clicked on carousel control',
      'eventLabel': 'Slide ' + (e.item.index + 1)
    });
  }

  @HostListener('window:scroll', ['$event']) checkScroll() {
    const ne = this.el.nativeElement,
          direction = window.pageYOffset > this.offset ? 'down' : 'up';

    this.offset = window.pageYOffset;

    if (window.pageYOffset >= ne.offsetTop - ne.offsetHeight / 3 && window.pageYOffset <= ne.offsetTop + ne.offsetHeight) {
      if (direction === 'down') {
        this.state = 'show';
      } else {
        this.state = 'fadein';
      }
    } else {
      this.state = 'hide';
    }
  }

  scrollStart(el) {
    const ne = this.el.nativeElement,
      hr = el.element.querySelectorAll('hr'),
      li = el.element.querySelectorAll('li');

    if (window.pageYOffset < (ne.offsetTop - 100) || window.pageYOffset > (ne.offsetTop + ne.offsetHeight + 100)) {
      hr.forEach(h => {
        h.style.transition = 'none';
        h.style.opacity = '0';
      });
      li.forEach(e => {
        e.style.transition = 'none';
        e.style.opacity = '0';
      });
    }
  }

  scrollDone(el) {
    const hr = el.element.querySelectorAll('hr'),
      li = el.element.querySelectorAll('li');

    setTimeout(() => {
      hr.forEach(h => {
        h.style.transition = 'all 500ms ease-in';
        h.style.opacity = '1';
      });
      setTimeout(() => {
        li.forEach(e => {
          e.style.transition = 'all 700ms ease-in';
          e.style.opacity = '1';
        });
      }, 250);
    }, 250);
  }
}
