import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
// import { RouterModule, Routes } from '@angular/router';

// import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faAngleDown, faAngleRight } from '@fortawesome/free-solid-svg-icons';

import { RecaptchaModule } from 'angular-google-recaptcha';

import * as $ from 'jquery';
import { OwlModule } from 'ngx-owl-carousel';
import { Globals } from './globals';
import { NavigationComponent } from './navigation/navigation.component';
import { LogoComponent } from './logo/logo.component';
import { IntroComponent } from './intro/intro.component';
import { OutlookComponent } from './outlook/outlook.component';
import { OpportunityComponent } from './opportunity/opportunity.component';
import { ExpertiseComponent } from './expertise/expertise.component';
import { WorkComponent } from './work/work.component';
import { WorkFormComponent } from './work-form/work-form.component';
import { LearnComponent } from './learn/learn.component';
import { NavigationNextComponent } from './navigation-next/navigation-next.component';
import { WebinarComponent } from './webinar/webinar.component';
import { FindAgentComponent } from './find-agent/find-agent.component';
import { BtnShareComponent } from './btn-share/btn-share.component';
import { ReportComponent } from './report/report.component';
import { BtnDownloadComponent } from './btn-download/btn-download.component';
import { ClaimsPromiseComponent } from './claims-promise/claims-promise.component';
import { ResourceComponent } from './resource/resource.component';
import { VideoComponent } from './video/video.component';
import { BtnWatchComponent } from './btn-watch/btn-watch.component';
import { BtnMoreComponent } from './btn-more/btn-more.component';
import { HttpClientModule } from '@angular/common/http';

// import { AnimateOnScrollModule } from 'ng2-animate-on-scroll';

// const appRoutes: Routes = [
//   { path: 'rediscover-technology.aspx', component: AppComponent },
// ];

declare global {
  interface Window { dataLayer: any; }
}

window.dataLayer = window.dataLayer || {};

@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    LogoComponent,
    IntroComponent,
    OutlookComponent,
    OpportunityComponent,
    ExpertiseComponent,
    WorkComponent,
    WorkFormComponent,
    LearnComponent,
    NavigationNextComponent,
    WebinarComponent,
    FindAgentComponent,
    BtnShareComponent,
    ReportComponent,
    BtnDownloadComponent,
    ClaimsPromiseComponent,
    ResourceComponent,
    VideoComponent,
    BtnWatchComponent,
    BtnMoreComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    FormsModule,
    OwlModule,
    HttpClientModule,
    RecaptchaModule.forRoot({
      // siteKey: '6LfWnJEUAAAAADVgbp5R7fcfW8EPIPII9OTjqqQI' //graphiti key
      // siteKey: '6LcgfSsUAAAAAKO0-mZmBy1m2W_vKvSiG_-DZEUa' // chubb stage key
      siteKey: '6Lf2qzAUAAAAAA5LX8PnDs-iaknJFfJkROiwW0Jw' // chubb key
    }),
    // AnimateOnScrollModule.forRoot(),
  ],
  providers: [ Globals ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    library.add(faAngleDown, faAngleRight);
  }
 }
