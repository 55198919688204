<article class="main-section">
  <div class="parallax__wrap" [@scrollAnimation]="state" (@scrollAnimation.start)="scrollStart($event)" (@scrollAnimation.done)="scrollDone($event)">
    <section class="parallax__content">
      <h1 class="is-disabled">We know entertainment</h1>
      <hr />
      <h3 class="percent66 is-disabled">We understand the changes happening in your industry and put our specialized knowledge to work for you, with products and services tailored to address the unique needs of entertainment businesses like yours.
        </h3>

      <br clear="all" />
      <div class="block checklist-block clearfix">
        <ul class="checklist-block--list list-unstyled row">
          <li class="checklist-block--list-item clearfix">
              <div class="clearfix">
                  <img src="assets/img/svg/1.svg" width="30" height="31" alt="1">
                  <p class="checklist-block__copy clearfix">Eliminate unforeseen gaps in insurance with our seamlessly integrated products</p>
              </div>
          </li>

          <li class="checklist-block--list-item clearfix">
              <div class="clearfix">
                  <img src="assets/img/svg/2.svg" width="30" height="31" alt="2">
                  <p class="checklist-block__copy clearfix">One-stop shop offering integrated insurance solutions such as property, casualty, errors &amp; omissions, accident &amp; health, cyber, multinational, directors &amp; officers, and more
                    </p>
              </div>
          </li>

          <li class="checklist-block--list-item clearfix">
              <div class="clearfix">
                  <img src="assets/img/svg/3.svg" width="30" height="31" alt="3">
                  <p class="checklist-block__copy clearfix">Tailor your protection to address risks you are most likely to face</p>
              </div>
          </li>

          
        </ul>
        <ul class="checklist-block--list list-unstyled row">
          <li class="checklist-block--list-item clearfix">
            <div class="clearfix">
                <img src="assets/img/svg/4.svg" width="30" height="31" alt="4">
                <p class="checklist-block__copy clearfix">Anticipate and address problems before they happen</p>
            </div>
          </li>
          <li class="checklist-block--list-item clearfix">
            <div class="clearfix">
                <img src="assets/img/svg/5.svg" width="30" height="31" alt="5">
                <p class="checklist-block__copy clearfix">Mitigate risk with help from our entertainment risk engineering experts
                  </p>
            </div>
          </li>

          <li class="checklist-block--list-item clearfix">
            <div class="clearfix">
                <img src="assets/img/svg/6.svg" width="30" height="31" alt="6">
                <p class="checklist-block__copy clearfix">Protect your business overseas with our global resources
                  </p>
            </div>
          </li>

          <li class="checklist-block--list-item clearfix">
            <div class="clearfix">
                <img src="assets/img/svg/7.svg" width="30" height="31" alt="7">
                <p class="checklist-block__copy clearfix">Get reimbursed fairly and quickly after a covered claim
                  </p>
            </div>
          </li>
        </ul>
      </div>

      <a id="claims" name="claims" class="anchor-target"></a>
      <h2>Real Companies. Real Protection.</h2>
      <hr class="gray" />
      <owl-carousel [options]="slideOptions" [carouselClasses]="['owl-theme', 'sliding', 'clearfix', 'owl-protection']" #owlElement>
        <div class="item" *ngFor="let slide of slides">
         <div style="text-align: center; max-width: 80%; margin: 0 auto;">
           <img src="{{ slide.icon.src }}" alt="{{ slide.icon.alt }}" width="70" style="max-width: 70px; margin: 1rem auto;" />
           <h3>{{ slide.title }}</h3>
           <ul class="points clearfix">
            <li *ngFor="let point of slide.points">
              {{ point }}
            </li>
           </ul>

           <p [innerHTML]="slide.description" class="clearfix"></p>
          <!-- <strong>The Chubb difference:</strong><br />
           <ul>
             <li *ngFor="let dif of slide.difference">{{ dif }}</li>
           </ul>-->
           <div [innerHTML]="slide.footer" *ngIf="slide.footer"></div>
         </div>
        </div>
      </owl-carousel>
      <hr class="gray" />

      <a id="video" name="video" class="anchor-target"></a>

      <!--  Hiding the below component as per GDSCP-1670 
      <app-video
        *ngFor="let video of videos"
        [thisVideo]="video"
      ></app-video>
      -->

    </section>

    <app-claims-promise></app-claims-promise>
  </div>
</article>
