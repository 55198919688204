<article class="main-section">
  <div class="parallax__wrap" [@scrollAnimation]="state" (@scrollAnimation.start)="scrollStart($event)" (@scrollAnimation.done)="scrollDone($event)">
    <section class="work parallax__content">
      <h1 class="is-disabled">We’re here to help</h1>
      <hr class="bpad" />

      <owl-carousel [options]="slideOptions" [carouselClasses]="['owl-theme', 'sliding', 'work--owl', 'clearfix']" >
        <div class="item" *ngFor="let person of people">
         <div class="person-card">
           <div class="photo">
              <img src="{{ person.photo.src }}" width="192" alt="Photo of {{ person.name }}" />
           </div>
           <div class="content">
              <h4>{{ person.header }}</h4>
              <h3>{{ person.name }}</h3>
              <hr />
              <div class="clearfix">
                <div class="points clearfix" *ngFor="let point of person.points">
                  <div class="point">{{ point }}</div>
                </div>
              </div>
              <p class="quote">{{ person.quote }}</p>
           </div>

         </div>
        </div>
       </owl-carousel>

      <app-work-form></app-work-form>


      <app-find-agent></app-find-agent>

    </section>

  </div>
</article>
