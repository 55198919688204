import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { scrollToPage } from '../app.animations';
import {Globals} from '../globals';

@Component({
    selector: 'app-outlook',
    templateUrl: './outlook.component.html',
    styleUrls: ['./outlook.component.scss'],
    animations: [scrollToPage]
})
export class OutlookComponent implements OnInit {

    stats1Options = { items: 1, dots: false, nav: false };
    stats1 = [
      this.sanitizer.bypassSecurityTrustHtml(`<svg class="stat-card-svg" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 300 200" style="enable-background:new 0 0 300 200;" xml:space="preserve">
   <style type="text/css">
     .st0{fill:none;}
     .st1{fill:none;stroke:#6E27C5;stroke-width:4;stroke-miterlimit:10;}
     .st2{enable-background:new    ;}
     .st3{fill:#333333;}
     .st4{fill:#6E27C5;}
   </style>
   <g id="Group_17766" transform="translate(-266 -1149.5)">
     <g id="Rectangle_4" transform="translate(266 1149.5)">
       <rect class="st0" width="300" height="200"/>
       <rect x="2" y="2" class="st1" width="296" height="196"/>
     </g>
     <g class="st2">
       <path class="st3" d="M296.5,1283.4c0-2.8,2-4.3,4-4.3c2.3,0,4,1.4,4,4.2c0,2.8-2,4.4-4,4.4
         C298.3,1287.7,296.5,1286.3,296.5,1283.4z M302.9,1283.5c0-2.7-0.8-3.8-2.3-3.8c-1.4,0-2.2,1-2.2,3.4c0,2.7,0.8,3.8,2.4,3.8
         C302.1,1287,302.9,1285.9,302.9,1283.5z"/>
       <path class="st3" d="M305.6,1287l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-5.8h-1.1v-0.5l1.1-0.4v-0.9c0-2,1.5-3.2,3.6-3.2
         c1,0,1.5,0.4,1.5,1c0,0.4-0.3,0.8-0.8,0.8c-0.6,0-0.9-0.3-1-0.8c0-0.3-0.1-0.4-0.3-0.4c-0.6,0-1.4,0.4-1.4,1.8v1.7h1.9l-0.2,0.8
         h-1.6v5.7c0,0.8,0.1,0.8,0.4,0.9l0.9,0.2v0.5h-4V1287z"/>
       <path class="st3" d="M314.8,1283.5c0-3.4,2.6-4.4,4.6-4.4c1.5,0,2.3,0.5,2.3,1.3c0,0.5-0.4,1-0.9,1c-0.6,0-0.8-0.3-0.9-0.9
         c-0.1-0.7-0.3-0.8-0.8-0.8c-1.3,0-2.4,0.9-2.4,3.2c0,2.4,1,3.7,2.8,3.7c1.1,0,1.9-0.4,2.5-0.7v0.6c-0.5,0.4-1.6,1.1-3.1,1.1
         C316.1,1287.7,314.8,1285.9,314.8,1283.5z"/>
       <path class="st3" d="M322.6,1283.4c0-2.8,2-4.3,4-4.3c2.3,0,4,1.4,4,4.2c0,2.8-2,4.4-4,4.4
         C324.4,1287.7,322.6,1286.3,322.6,1283.4z M328.9,1283.5c0-2.7-0.8-3.8-2.3-3.8c-1.4,0-2.2,1-2.2,3.4c0,2.7,0.8,3.8,2.4,3.8
         C328.2,1287,328.9,1285.9,328.9,1283.5z"/>
       <path class="st3" d="M331.6,1287l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-5c0-0.6-0.1-0.7-0.5-0.9l-0.6-0.3v-0.4l2.5-0.3l0.2,0.1
         l-0.1,0.9h0.1c0.8-0.5,1.9-1,2.9-1c1.2,0,1.6,0.4,1.9,1h0.1c0.9-0.5,1.9-1,3-1c1.4,0,2,0.7,2,2.1v4.8c0,0.6,0.1,0.8,0.4,0.8
         l0.7,0.2v0.5h-3.8v-0.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-4.3c0-1.1-0.3-1.5-1.2-1.5c-0.8,0-1.6,0.3-2.1,0.5v5.3
         c0,0.6,0.1,0.8,0.4,0.8l0.7,0.2v0.5h-3.8v-0.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-4.3c0-1.1-0.3-1.5-1.2-1.5
         c-0.8,0-1.6,0.3-2.1,0.5v5.3c0,0.7,0.1,0.8,0.4,0.8l0.7,0.2v0.5h-3.8V1287z"/>
       <path class="st3" d="M346,1290.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-8.5c0-0.6-0.1-0.7-0.5-0.9l-0.6-0.3v-0.4l2.5-0.3l0.2,0.1
         l-0.1,0.8h0.1c0.7-0.5,1.7-0.9,2.8-0.9c1.6,0,3.2,0.9,3.2,3.9s-1.8,4.7-4.6,4.7c-0.4,0-1-0.1-1.4-0.2c0,0,0,0.5,0,1v1
         c0,0.7,0.1,0.8,0.4,0.8l0.9,0.2v0.5h-4V1290.5z M353,1283.4c0-2.2-0.9-3.2-2.5-3.2c-0.9,0-1.5,0.4-1.8,0.5v5.7
         c0.4,0.4,0.9,0.6,1.6,0.6C352.1,1287,353,1286,353,1283.4z"/>
       <path class="st3" d="M355.7,1285.6c0-1.3,0.9-2,2.8-2.4l1.9-0.4v-1.5c0-1.1-0.2-1.6-1.5-1.6c-0.7,0-0.8,0.2-0.9,0.6
         c-0.1,0.6-0.2,1.1-1,1.1c-0.6,0-0.8-0.4-0.8-0.8c0-1.1,1.7-1.5,3-1.5c1.9,0,2.8,0.7,2.8,2.1v4.7c0,0.4,0.1,0.7,0.4,0.8
         c0.2,0.1,0.4,0.1,0.8,0.1v0.4c-0.3,0.2-0.8,0.4-1.4,0.4c-0.8,0-1.2-0.4-1.3-0.9h-0.1c-0.4,0.5-1.3,0.9-2.2,0.9
         C356.6,1287.7,355.7,1287,355.7,1285.6z M360.4,1286v-2.5l-1.2,0.1c-1.2,0.1-1.8,0.5-1.8,1.7c0,0.9,0.6,1.3,1.5,1.3
         C359.6,1286.8,360.1,1286.4,360.4,1286z"/>
       <path class="st3" d="M363.8,1287l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-5c0-0.6-0.1-0.7-0.5-0.9l-0.6-0.3v-0.4l2.5-0.3l0.2,0.1
         l-0.1,0.9h0.1c0.8-0.5,2-1,3-1c1.6,0,2.2,0.7,2.2,2.1v4.8c0,0.6,0.1,0.8,0.4,0.8l0.6,0.2v0.5H369v-0.5l0.7-0.2
         c0.3-0.1,0.4-0.2,0.4-0.8v-4.3c0-1.1-0.4-1.5-1.4-1.5c-0.8,0-1.7,0.3-2.2,0.5v5.3c0,0.7,0.1,0.8,0.4,0.8l0.7,0.2v0.5h-3.8V1287z"
         />
       <path class="st3" d="M373.5,1287l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-5c0-0.6-0.1-0.7-0.5-0.9l-0.6-0.3v-0.4l2.6-0.3l0.2,0.1
         c0,0-0.1,0.7-0.1,1.8v5c0,0.7,0.1,0.8,0.4,0.8l0.7,0.2v0.5h-3.8V1287z M374.2,1276.6c0-0.6,0.5-1,1.1-1c0.6,0,1.1,0.5,1.1,1
         c0,0.5-0.5,1-1.1,1C374.7,1277.7,374.2,1277.2,374.2,1276.6z"/>
       <path class="st3" d="M378.1,1283.3c0-2.6,1.8-4.2,4-4.2c2,0,3.3,1.1,3.3,4.1h-5.5c0,2.4,1.2,3.5,2.8,3.5c1.2,0,2-0.4,2.6-0.7v0.6
         c-0.4,0.4-1.6,1.1-3.1,1.1C379.4,1287.7,378.1,1285.9,378.1,1283.3z M379.8,1282.5l3.7-0.2c0-1.8-0.3-2.5-1.5-2.5
         C380.8,1279.8,379.9,1280.6,379.8,1282.5z"/>
       <path class="st3" d="M386.5,1287.3l-0.1-2.4h0.5c0.3,0.6,0.4,1,0.8,1.4c0.3,0.4,0.6,0.6,1.4,0.6c1,0,1.7-0.5,1.7-1.3
         c0-0.9-0.6-1.2-1.9-1.7c-1.4-0.5-2.4-1.2-2.4-2.5c0-1.5,1.3-2.4,3-2.4c0.9,0,1.5,0.1,2.1,0.3l0.1,2.1h-0.5
         c-0.3-0.5-0.5-0.9-0.8-1.3c-0.2-0.3-0.5-0.4-1.1-0.4c-1.1,0-1.5,0.5-1.5,1.3c0,0.6,0.5,1,1.9,1.6c1.5,0.6,2.5,1.2,2.5,2.4
         c0,1.5-1.2,2.7-3.2,2.7C388.1,1287.7,387.2,1287.6,386.5,1287.3z"/>
       <path class="st3" d="M397.2,1287l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-5c0-0.6-0.1-0.7-0.5-0.9l-0.6-0.3v-0.4l2.5-0.3l0.2,0.1
         l-0.1,0.9h0.1c0.6-0.6,1.6-1.1,2.4-1.1c0.7,0,1.1,0.4,1.1,1c0,0.6-0.4,0.9-0.9,0.9c-0.6,0-0.8-0.2-0.9-0.5
         c-0.1-0.2-0.2-0.3-0.4-0.3c-0.3,0-1,0.4-1.2,0.7v5.1c0,0.7,0.1,0.8,0.4,0.8l0.8,0.2v0.5h-3.9V1287z"/>
       <path class="st3" d="M403.8,1283.3c0-2.6,1.8-4.2,4-4.2c2,0,3.3,1.1,3.3,4.1h-5.5c0,2.4,1.2,3.5,2.8,3.5c1.2,0,2-0.4,2.6-0.7v0.6
         c-0.4,0.4-1.6,1.1-3.1,1.1C405.1,1287.7,403.8,1285.9,403.8,1283.3z M405.5,1282.5l3.7-0.2c0-1.8-0.3-2.5-1.5-2.5
         C406.5,1279.8,405.6,1280.6,405.5,1282.5z"/>
       <path class="st3" d="M412,1290.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-8.5c0-0.6-0.1-0.7-0.5-0.9l-0.6-0.3v-0.4l2.5-0.3l0.2,0.1
         l-0.1,0.8h0.1c0.7-0.5,1.7-0.9,2.8-0.9c1.6,0,3.2,0.9,3.2,3.9s-1.8,4.7-4.6,4.7c-0.4,0-1-0.1-1.4-0.2c0,0,0,0.5,0,1v1
         c0,0.7,0.1,0.8,0.4,0.8l0.9,0.2v0.5h-4V1290.5z M418.9,1283.4c0-2.2-0.9-3.2-2.5-3.2c-0.9,0-1.5,0.4-1.8,0.5v5.7
         c0.4,0.4,0.9,0.6,1.6,0.6C418,1287,418.9,1286,418.9,1283.4z"/>
       <path class="st3" d="M421.8,1283.4c0-2.8,2-4.3,4-4.3c2.3,0,4,1.4,4,4.2c0,2.8-2,4.4-4,4.4
         C423.5,1287.7,421.8,1286.3,421.8,1283.4z M428.1,1283.5c0-2.7-0.8-3.8-2.3-3.8c-1.4,0-2.2,1-2.2,3.4c0,2.7,0.8,3.8,2.4,3.8
         C427.3,1287,428.1,1285.9,428.1,1283.5z"/>
       <path class="st3" d="M430.8,1287l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-5c0-0.6-0.1-0.7-0.5-0.9l-0.6-0.3v-0.4l2.5-0.3l0.2,0.1
         l-0.1,0.9h0.1c0.6-0.6,1.6-1.1,2.4-1.1c0.7,0,1.1,0.4,1.1,1c0,0.6-0.4,0.9-0.9,0.9c-0.6,0-0.8-0.2-0.9-0.5
         c-0.1-0.2-0.2-0.3-0.4-0.3c-0.3,0-1,0.4-1.2,0.7v5.1c0,0.7,0.1,0.8,0.4,0.8l0.8,0.2v0.5h-3.9V1287z"/>
       <path class="st3" d="M438.6,1286v-5.8h-1.1v-0.5c1.1-0.3,1.7-1.1,2.1-2.4h0.6v2h2.3l-0.2,0.8h-2.1v5.6c0,0.7,0.4,1,1.1,1
         c0.5,0,0.9-0.1,1.3-0.2v0.5c-0.3,0.3-1,0.6-2.1,0.6C439.4,1287.7,438.6,1287.2,438.6,1286z"/>
       <path class="st3" d="M443.2,1283.3c0-2.6,1.8-4.2,4-4.2c2,0,3.3,1.1,3.3,4.1h-5.5c0,2.4,1.2,3.5,2.8,3.5c1.2,0,2-0.4,2.6-0.7v0.6
         c-0.4,0.4-1.6,1.1-3.1,1.1C444.5,1287.7,443.2,1285.9,443.2,1283.3z M444.9,1282.5l3.7-0.2c0-1.8-0.3-2.5-1.5-2.5
         C445.9,1279.8,445,1280.6,444.9,1282.5z"/>
       <path class="st3" d="M451.6,1283.7c0-3.1,2.2-4.6,4.5-4.6c0.7,0,1.2,0.1,1.5,0.2c0,0,0-0.6,0-1.2v-1c0-0.6-0.1-0.7-0.5-0.8
         l-0.7-0.3v-0.4l2.7-0.3l0.2,0.1c0,0-0.1,0.6-0.1,1.9v8.4c0,0.7,0.1,0.8,0.5,1l0.6,0.3v0.4l-2.5,0.3l-0.1-0.1l0.1-0.8h-0.1
         c-0.7,0.5-1.7,0.9-2.8,0.9C453.2,1287.7,451.6,1286.7,451.6,1283.7z M457.5,1286.1v-5.6c-0.4-0.3-0.8-0.7-1.6-0.7
         c-1.7,0-2.7,0.9-2.7,3.5c0,2.3,0.9,3.2,2.4,3.2C456.6,1286.6,457.2,1286.3,457.5,1286.1z"/>
     </g>
     <g class="st2">
       <path class="st3" d="M296.4,1310.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-8.5c0-0.6-0.1-0.7-0.5-0.9l-0.6-0.3v-0.4l2.5-0.3l0.2,0.1
         l-0.1,0.8h0.1c0.7-0.5,1.7-0.9,2.8-0.9c1.6,0,3.2,0.9,3.2,3.9s-1.8,4.7-4.6,4.7c-0.4,0-1-0.1-1.4-0.2c0,0,0,0.5,0,1v1
         c0,0.7,0.1,0.8,0.4,0.8l0.9,0.2v0.5h-4V1310.5z M303.4,1303.4c0-2.2-0.9-3.2-2.5-3.2c-0.9,0-1.5,0.4-1.8,0.5v5.7
         c0.4,0.4,0.9,0.6,1.6,0.6C302.5,1307,303.4,1306,303.4,1303.4z"/>
       <path class="st3" d="M306.2,1303.4c0-2.8,2-4.3,4-4.3c2.3,0,4,1.4,4,4.2c0,2.8-2,4.4-4,4.4C308,1307.7,306.2,1306.3,306.2,1303.4z
          M312.5,1303.5c0-2.7-0.8-3.8-2.3-3.8c-1.4,0-2.2,1-2.2,3.4c0,2.7,0.8,3.8,2.4,3.8C311.8,1307,312.5,1305.9,312.5,1303.5z"/>
       <path class="st3" d="M315.5,1307.3l-0.1-2.4h0.5c0.3,0.6,0.4,1,0.8,1.4c0.3,0.4,0.6,0.6,1.4,0.6c1,0,1.7-0.5,1.7-1.3
         c0-0.9-0.6-1.2-1.9-1.7c-1.4-0.5-2.4-1.2-2.4-2.5c0-1.5,1.3-2.4,3-2.4c0.9,0,1.5,0.1,2.1,0.3l0.1,2.1h-0.5
         c-0.3-0.5-0.5-0.9-0.8-1.3c-0.2-0.3-0.5-0.4-1.1-0.4c-1.1,0-1.5,0.5-1.5,1.3c0,0.6,0.5,1,1.9,1.6c1.5,0.6,2.5,1.2,2.5,2.4
         c0,1.5-1.2,2.7-3.2,2.7C317,1307.7,316.1,1307.6,315.5,1307.3z"/>
       <path class="st3" d="M322,1307l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-5c0-0.6-0.1-0.7-0.5-0.9l-0.6-0.3v-0.4l2.6-0.3l0.2,0.1
         c0,0-0.1,0.7-0.1,1.8v5c0,0.7,0.1,0.8,0.4,0.8l0.7,0.2v0.5H322V1307z M322.8,1296.6c0-0.6,0.5-1,1.1-1c0.6,0,1.1,0.5,1.1,1
         c0,0.5-0.5,1-1.1,1C323.3,1297.7,322.8,1297.2,322.8,1296.6z"/>
       <path class="st3" d="M327.4,1306v-5.8h-1.1v-0.5c1.1-0.3,1.7-1.1,2.1-2.4h0.6v2h2.3l-0.2,0.8H329v5.6c0,0.7,0.4,1,1.1,1
         c0.5,0,0.9-0.1,1.3-0.2v0.5c-0.3,0.3-1,0.6-2.1,0.6C328.2,1307.7,327.4,1307.2,327.4,1306z"/>
       <path class="st3" d="M331.9,1307l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-5c0-0.6-0.1-0.7-0.5-0.9l-0.6-0.3v-0.4l2.6-0.3l0.2,0.1
         c0,0-0.1,0.7-0.1,1.8v5c0,0.7,0.1,0.8,0.4,0.8l0.7,0.2v0.5h-3.8V1307z M332.7,1296.6c0-0.6,0.5-1,1.1-1c0.6,0,1.1,0.5,1.1,1
         c0,0.5-0.5,1-1.1,1C333.2,1297.7,332.7,1297.2,332.7,1296.6z"/>
       <path class="st3" d="M337,1301c-0.3-0.7-0.4-0.9-0.9-1.1l-0.4-0.1v-0.5h3.7v0.5l-0.4,0.1c-0.4,0.1-0.4,0.3-0.4,0.5
         c0,0.2,0.1,0.5,0.2,0.8l1.6,4.4h0.1l1.6-4.4c0.1-0.4,0.2-0.7,0.2-0.9c0-0.2-0.1-0.4-0.4-0.5l-0.4-0.1v-0.5h2.9v0.5l-0.4,0.1
         c-0.4,0.1-0.5,0.4-0.8,1.2l-2.4,6.3h-0.9L337,1301z"/>
       <path class="st3" d="M344.4,1303.3c0-2.6,1.8-4.2,4-4.2c2,0,3.3,1.1,3.3,4.1h-5.5c0,2.4,1.2,3.5,2.8,3.5c1.2,0,2-0.4,2.6-0.7v0.6
         c-0.4,0.4-1.6,1.1-3.1,1.1C345.7,1307.7,344.4,1305.9,344.4,1303.3z M346.1,1302.5l3.7-0.2c0-1.8-0.3-2.5-1.5-2.5
         C347.1,1299.8,346.3,1300.6,346.1,1302.5z"/>
       <path class="st3" d="M356.6,1307l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-5c0-0.6-0.1-0.7-0.5-0.9l-0.6-0.3v-0.4l2.5-0.3l0.2,0.1
         l-0.1,0.9h0.1c0.6-0.6,1.6-1.1,2.4-1.1c0.7,0,1.1,0.4,1.1,1c0,0.6-0.4,0.9-0.9,0.9c-0.6,0-0.8-0.2-0.9-0.5
         c-0.1-0.2-0.2-0.3-0.4-0.3c-0.3,0-1,0.4-1.2,0.7v5.1c0,0.7,0.1,0.8,0.4,0.8l0.8,0.2v0.5h-3.9V1307z"/>
       <path class="st3" d="M363.2,1303.3c0-2.6,1.8-4.2,4-4.2c2,0,3.3,1.1,3.3,4.1h-5.5c0,2.4,1.2,3.5,2.8,3.5c1.2,0,2-0.4,2.6-0.7v0.6
         c-0.4,0.4-1.6,1.1-3.1,1.1C364.5,1307.7,363.2,1305.9,363.2,1303.3z M364.9,1302.5l3.7-0.2c0-1.8-0.3-2.5-1.5-2.5
         C366,1299.8,365.1,1300.6,364.9,1302.5z"/>
       <path class="st3" d="M372.1,1301c-0.3-0.7-0.4-0.9-0.9-1.1l-0.4-0.1v-0.5h3.7v0.5l-0.4,0.1c-0.4,0.1-0.4,0.3-0.4,0.5
         c0,0.2,0.1,0.5,0.2,0.8l1.6,4.4h0.1l1.6-4.4c0.1-0.4,0.2-0.7,0.2-0.9c0-0.2-0.1-0.4-0.4-0.5l-0.4-0.1v-0.5h2.9v0.5l-0.4,0.1
         c-0.4,0.1-0.5,0.4-0.8,1.2l-2.4,6.3h-0.9L372.1,1301z"/>
       <path class="st3" d="M379.6,1303.3c0-2.6,1.8-4.2,4-4.2c2,0,3.3,1.1,3.3,4.1h-5.5c0,2.4,1.2,3.5,2.8,3.5c1.2,0,2-0.4,2.6-0.7v0.6
         c-0.4,0.4-1.6,1.1-3.1,1.1C380.9,1307.7,379.6,1305.9,379.6,1303.3z M381.3,1302.5l3.7-0.2c0-1.8-0.3-2.5-1.5-2.5
         C382.3,1299.8,381.4,1300.6,381.3,1302.5z"/>
       <path class="st3" d="M387.7,1307l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-5c0-0.6-0.1-0.7-0.5-0.9l-0.6-0.3v-0.4l2.5-0.3l0.2,0.1
         l-0.1,0.9h0.1c0.8-0.5,2-1,3-1c1.6,0,2.2,0.7,2.2,2.1v4.8c0,0.6,0.1,0.8,0.4,0.8l0.6,0.2v0.5h-3.7v-0.5l0.7-0.2
         c0.3-0.1,0.4-0.2,0.4-0.8v-4.3c0-1.1-0.4-1.5-1.4-1.5c-0.8,0-1.7,0.3-2.2,0.5v5.3c0,0.7,0.1,0.8,0.4,0.8l0.7,0.2v0.5h-3.8V1307z"
         />
       <path class="st3" d="M398.2,1305.6v-4.7c0-0.6-0.1-0.6-0.4-0.8l-0.7-0.3v-0.4l2.4-0.3l0.2,0.2v5.9c0,0.9,0.4,1.4,1.4,1.4
         c0.8,0,1.6-0.3,2.2-0.6v-5.2c0-0.6,0-0.6-0.4-0.8l-0.6-0.3v-0.4l2.4-0.3l0.2,0.2v6.4c0,0.7,0.1,0.8,0.5,1l0.6,0.3v0.4l-2.5,0.3
         l-0.2-0.1l0.1-1h-0.1c-0.8,0.6-1.9,1.1-3,1.1C399,1307.7,398.2,1307,398.2,1305.6z"/>
       <path class="st3" d="M406.9,1303.3c0-2.6,1.8-4.2,4-4.2c2,0,3.3,1.1,3.3,4.1h-5.5c0,2.4,1.2,3.5,2.8,3.5c1.2,0,2-0.4,2.6-0.7v0.6
         c-0.4,0.4-1.6,1.1-3.1,1.1C408.2,1307.7,406.9,1305.9,406.9,1303.3z M408.6,1302.5l3.7-0.2c0-1.8-0.3-2.5-1.5-2.5
         C409.6,1299.8,408.7,1300.6,408.6,1302.5z"/>
       <path class="st3" d="M418.5,1309.2c0-0.8,0.7-1.5,1.8-1.9v-0.1c-0.5-0.2-1.1-0.6-1.1-1.3c0-0.9,0.7-1.4,1.2-1.8v-0.1
         c-0.7-0.3-1.4-1.1-1.4-2.1c0-1.9,1.6-2.8,3.2-2.8c0.6,0,1.1,0.1,1.7,0.3c0.6-0.5,1.3-0.9,2-0.9c0.5,0,0.9,0.3,0.9,0.8
         c0,0.4-0.2,0.8-0.7,0.8c-0.4,0-0.6-0.2-0.7-0.5c-0.1-0.2-0.7,0-1.1,0.2v0c0.7,0.4,1.1,1,1.1,1.9c0,1.9-1.6,2.7-3.2,2.7
         c-0.5,0-0.8-0.1-1.2-0.2c-0.3,0.2-0.6,0.5-0.6,0.9c0,0.4,0.3,0.7,1,0.7h2.5c1.4,0,2.3,0.4,2.3,2c0,1.2-1,3.2-4.4,3.2
         C419.9,1311.1,418.5,1310.3,418.5,1309.2z M425.2,1308.5c0-0.7-0.3-1.2-1.4-1.2h-2.5c-0.5,0.1-1.3,0.8-1.3,1.6
         c0,1,0.7,1.5,2.3,1.5C423.9,1310.4,425.2,1309.6,425.2,1308.5z M423.9,1301.9c0-1.6-0.5-2.2-1.6-2.2c-1,0-1.5,0.6-1.5,2
         c0,1.6,0.5,2.1,1.5,2.1C423.4,1303.8,423.9,1303.3,423.9,1301.9z"/>
       <path class="st3" d="M427.2,1307l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-5c0-0.6-0.1-0.7-0.5-0.9l-0.6-0.3v-0.4l2.5-0.3l0.2,0.1
         l-0.1,0.9h0.1c0.6-0.6,1.6-1.1,2.4-1.1c0.7,0,1.1,0.4,1.1,1c0,0.6-0.4,0.9-0.9,0.9c-0.6,0-0.8-0.2-0.9-0.5
         c-0.1-0.2-0.2-0.3-0.4-0.3c-0.3,0-1,0.4-1.2,0.7v5.1c0,0.7,0.1,0.8,0.4,0.8l0.8,0.2v0.5h-3.9V1307z"/>
       <path class="st3" d="M433.8,1303.4c0-2.8,2-4.3,4-4.3c2.3,0,4,1.4,4,4.2c0,2.8-2,4.4-4,4.4
         C435.6,1307.7,433.8,1306.3,433.8,1303.4z M440.1,1303.5c0-2.7-0.8-3.8-2.3-3.8c-1.4,0-2.2,1-2.2,3.4c0,2.7,0.8,3.8,2.4,3.8
         C439.4,1307,440.1,1305.9,440.1,1303.5z"/>
       <path class="st3" d="M443.5,1301c-0.3-0.7-0.4-0.9-0.9-1.1l-0.4-0.1v-0.5h3.7v0.5l-0.4,0.1c-0.4,0.1-0.4,0.3-0.4,0.5
         c0,0.2,0.1,0.5,0.2,0.8l1.4,4.4h0.1l1.5-4.3l-0.1-0.4c-0.2-0.6-0.4-1-0.8-1.1l-0.4-0.1v-0.5h3.4v0.5l-0.4,0.1
         c-0.3,0.1-0.4,0.3-0.4,0.5c0,0.2,0.1,0.5,0.2,0.8l1.5,4.4h0.1l1.4-4.4c0.1-0.4,0.2-0.7,0.2-0.9c0-0.2-0.1-0.4-0.4-0.5l-0.4-0.1
         v-0.5h2.8v0.5l-0.4,0.1c-0.4,0.1-0.5,0.4-0.8,1.2l-2.3,6.3h-1l-1.8-5.1h0l-1.8,5.1h-1L443.5,1301z"/>
       <path class="st3" d="M456.4,1306v-5.8h-1.1v-0.5c1.1-0.3,1.7-1.1,2.1-2.4h0.6v2h2.3l-0.2,0.8H458v5.6c0,0.7,0.4,1,1.1,1
         c0.5,0,0.9-0.1,1.3-0.2v0.5c-0.3,0.3-1,0.6-2.1,0.6C457.3,1307.7,456.4,1307.2,456.4,1306z"/>
       <path class="st3" d="M461,1307l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-8.9c0-0.6-0.1-0.7-0.5-0.8l-0.7-0.3v-0.4l2.7-0.3l0.2,0.1
         c0,0-0.1,0.8-0.1,1.9v1.5c0,0.7-0.1,1.4-0.1,1.4h0.1c0.8-0.5,2-1,3-1c1.6,0,2.2,0.7,2.2,2.1v4.8c0,0.6,0.1,0.8,0.4,0.8l0.6,0.2
         v0.5h-3.7v-0.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-4.3c0-1.1-0.4-1.5-1.4-1.5c-0.8,0-1.7,0.3-2.2,0.5v5.3c0,0.7,0.1,0.8,0.4,0.8
         l0.7,0.2v0.5H461V1307z"/>
     </g>
     <g class="st2">
       <path class="st4" d="M335.3,1189.3h-29.7c-3.3,0-3.7,1.1-5.9,6.3H298l1.6-13.4h41.7v3.4c-12.6,19.1-20.8,37.6-25.5,65.9h-7.9
         C314.1,1223.6,325.8,1204.4,335.3,1189.3z"/>
       <path class="st4" d="M381.6,1189.3h-29.7c-3.3,0-3.7,1.1-5.9,6.3h-1.7l1.6-13.4h41.7v3.4c-12.6,19.1-20.8,37.6-25.5,65.9h-7.9
         C360.4,1223.6,372.1,1204.4,381.6,1189.3z"/>
       <path class="st4" d="M391.7,1199.6c0-12.3,8-18.4,15.2-18.4c7,0,15,5.3,15,17.3c0,12.3-8.5,18-15.2,18
         C399.9,1216.5,391.7,1211.4,391.7,1199.6z M444.9,1180.1h4l-49.5,76.4h-4.1L444.9,1180.1z M414.6,1199.6c0-10.6-2.5-16.1-7.9-16.1
         c-5.3,0-7.7,5-7.7,14.9c0,10.5,2.6,15.9,7.7,15.9C412,1214.3,414.6,1209.7,414.6,1199.6z M423.8,1236c0-12.3,8-18.4,15.2-18.4
         c7,0,15,5.3,15,17.3c0,12.3-8.5,18-15.2,18C432,1252.9,423.8,1247.8,423.8,1236z M446.7,1236c0-10.6-2.5-16.1-7.9-16.1
         c-5.3,0-7.7,5-7.7,14.9c0,10.5,2.6,15.9,7.7,15.9C444.1,1250.7,446.7,1246.1,446.7,1236z"/>
     </g>
   </g>
   </svg>
   `),
          this.sanitizer.bypassSecurityTrustHtml(`<svg class="stat-card-svg" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
          viewBox="0 0 300 200" style="enable-background:new 0 0 300 200;" xml:space="preserve">
       <style type="text/css">
         .st0{fill:none;}
         .st1{fill:none;stroke:#6E27C5;stroke-width:4;stroke-miterlimit:10;}
         .st2{enable-background:new    ;}
         .st3{fill:#333333;}
         .st4{fill:#6E27C5;}
       </style>
       <g id="Group_17767" transform="translate(-590 -1149.5)">
         <g id="Rectangle_4_Copy_3" transform="translate(590 1149.5)">
           <rect class="st0" width="300" height="200"/>
           <rect x="2" y="2" class="st1" width="296" height="196"/>
         </g>
         <g class="st2">
           <path class="st3" d="M620.5,1283.4c0-2.8,2-4.3,4-4.3c2.3,0,4,1.4,4,4.2c0,2.8-2,4.4-4,4.4
             C622.3,1287.7,620.5,1286.3,620.5,1283.4z M626.9,1283.5c0-2.7-0.8-3.8-2.3-3.8c-1.4,0-2.2,1-2.2,3.4c0,2.7,0.8,3.8,2.4,3.8
             C626.1,1287,626.9,1285.9,626.9,1283.5z"/>
           <path class="st3" d="M629.6,1287l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-5.8h-1.1v-0.5l1.1-0.4v-0.9c0-2,1.5-3.2,3.6-3.2
             c1,0,1.5,0.4,1.5,1c0,0.4-0.3,0.8-0.8,0.8c-0.6,0-0.9-0.3-1-0.8c0-0.3-0.1-0.4-0.3-0.4c-0.6,0-1.4,0.4-1.4,1.8v1.7h1.9l-0.2,0.8
             h-1.6v5.7c0,0.8,0.1,0.8,0.4,0.9l0.9,0.2v0.5h-4V1287z"/>
           <path class="st3" d="M639.4,1287.1v-10c0-0.6-0.1-0.7-0.5-0.8l-0.7-0.3v-0.4l2.7-0.3l0.2,0.1c0,0-0.1,0.6-0.1,1.9v1.5
             c0,0.7-0.1,1.3-0.1,1.3h0.1c0.7-0.5,1.7-0.9,2.8-0.9c1.6,0,3.2,0.9,3.2,3.9s-1.9,4.7-4.6,4.7
             C641.2,1287.7,640.3,1287.5,639.4,1287.1z M645.3,1283.4c0-2.2-0.9-3.2-2.3-3.2c-1.1,0-1.6,0.3-2,0.5v5.8c0.4,0.4,0.9,0.5,1.6,0.5
             C644.5,1287,645.3,1286,645.3,1283.4z"/>
           <path class="st3" d="M649,1285.6v-4.7c0-0.6-0.1-0.6-0.4-0.8l-0.7-0.3v-0.4l2.4-0.3l0.2,0.2v5.9c0,0.9,0.4,1.4,1.4,1.4
             c0.8,0,1.6-0.3,2.2-0.6v-5.2c0-0.6,0-0.6-0.4-0.8l-0.6-0.3v-0.4l2.4-0.3l0.2,0.2v6.4c0,0.7,0.1,0.8,0.5,1l0.6,0.3v0.4l-2.5,0.3
             l-0.2-0.1l0.1-1h-0.1c-0.8,0.6-1.9,1.1-3,1.1C649.8,1287.7,649,1287,649,1285.6z"/>
           <path class="st3" d="M657.8,1287.3l-0.1-2.4h0.5c0.3,0.6,0.4,1,0.8,1.4c0.3,0.4,0.6,0.6,1.4,0.6c1,0,1.7-0.5,1.7-1.3
             c0-0.9-0.6-1.2-1.9-1.7c-1.4-0.5-2.4-1.2-2.4-2.5c0-1.5,1.3-2.4,3-2.4c0.9,0,1.5,0.1,2.1,0.3l0.1,2.1h-0.5
             c-0.3-0.5-0.5-0.9-0.8-1.3c-0.2-0.3-0.5-0.4-1.1-0.4c-1.1,0-1.5,0.5-1.5,1.3c0,0.6,0.5,1,1.9,1.6c1.5,0.6,2.5,1.2,2.5,2.4
             c0,1.5-1.2,2.7-3.2,2.7C659.3,1287.7,658.4,1287.6,657.8,1287.3z"/>
           <path class="st3" d="M664.4,1287l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-5c0-0.6-0.1-0.7-0.5-0.9l-0.6-0.3v-0.4l2.6-0.3l0.2,0.1
             c0,0-0.1,0.7-0.1,1.8v5c0,0.7,0.1,0.8,0.4,0.8l0.7,0.2v0.5h-3.8V1287z M665.1,1276.6c0-0.6,0.5-1,1.1-1c0.6,0,1.1,0.5,1.1,1
             c0,0.5-0.5,1-1.1,1C665.6,1277.7,665.1,1277.2,665.1,1276.6z"/>
           <path class="st3" d="M668.8,1287l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-5c0-0.6-0.1-0.7-0.5-0.9l-0.6-0.3v-0.4l2.5-0.3l0.2,0.1
             l-0.1,0.9h0.1c0.8-0.5,2-1,3-1c1.6,0,2.2,0.7,2.2,2.1v4.8c0,0.6,0.1,0.8,0.4,0.8l0.6,0.2v0.5H674v-0.5l0.7-0.2
             c0.3-0.1,0.4-0.2,0.4-0.8v-4.3c0-1.1-0.4-1.5-1.4-1.5c-0.8,0-1.7,0.3-2.2,0.5v5.3c0,0.7,0.1,0.8,0.4,0.8l0.7,0.2v0.5h-3.8V1287z"
             />
           <path class="st3" d="M678.7,1283.3c0-2.6,1.8-4.2,4-4.2c2,0,3.3,1.1,3.3,4.1h-5.5c0,2.4,1.2,3.5,2.8,3.5c1.2,0,2-0.4,2.6-0.7v0.6
             c-0.4,0.4-1.6,1.1-3.1,1.1C680,1287.7,678.7,1285.9,678.7,1283.3z M680.4,1282.5l3.7-0.2c0-1.8-0.3-2.5-1.5-2.5
             C681.4,1279.8,680.5,1280.6,680.4,1282.5z"/>
           <path class="st3" d="M687.1,1287.3l-0.1-2.4h0.5c0.3,0.6,0.4,1,0.8,1.4c0.3,0.4,0.6,0.6,1.4,0.6c1,0,1.7-0.5,1.7-1.3
             c0-0.9-0.6-1.2-1.9-1.7c-1.4-0.5-2.4-1.2-2.4-2.5c0-1.5,1.3-2.4,3-2.4c0.9,0,1.5,0.1,2.1,0.3l0.1,2.1h-0.5
             c-0.3-0.5-0.5-0.9-0.8-1.3c-0.2-0.3-0.5-0.4-1.1-0.4c-1.1,0-1.5,0.5-1.5,1.3c0,0.6,0.5,1,1.9,1.6c1.5,0.6,2.5,1.2,2.5,2.4
             c0,1.5-1.2,2.7-3.2,2.7C688.6,1287.7,687.7,1287.6,687.1,1287.3z"/>
           <path class="st3" d="M693.9,1287.3l-0.1-2.4h0.5c0.3,0.6,0.4,1,0.8,1.4c0.3,0.4,0.6,0.6,1.4,0.6c1,0,1.7-0.5,1.7-1.3
             c0-0.9-0.6-1.2-1.9-1.7c-1.4-0.5-2.4-1.2-2.4-2.5c0-1.5,1.3-2.4,3-2.4c0.9,0,1.5,0.1,2.1,0.3l0.1,2.1h-0.5
             c-0.3-0.5-0.5-0.9-0.8-1.3c-0.2-0.3-0.5-0.4-1.1-0.4c-1.1,0-1.5,0.5-1.5,1.3c0,0.6,0.5,1,1.9,1.6c1.5,0.6,2.5,1.2,2.5,2.4
             c0,1.5-1.2,2.7-3.2,2.7C695.4,1287.7,694.5,1287.6,693.9,1287.3z"/>
           <path class="st3" d="M700.6,1283.3c0-2.6,1.8-4.2,4-4.2c2,0,3.3,1.1,3.3,4.1h-5.5c0,2.4,1.2,3.5,2.8,3.5c1.2,0,2-0.4,2.6-0.7v0.6
             c-0.4,0.4-1.6,1.1-3.1,1.1C701.9,1287.7,700.6,1285.9,700.6,1283.3z M702.4,1282.5l3.7-0.2c0-1.8-0.3-2.5-1.5-2.5
             C703.4,1279.8,702.5,1280.6,702.4,1282.5z"/>
           <path class="st3" d="M709.1,1287.3l-0.1-2.4h0.5c0.3,0.6,0.4,1,0.8,1.4c0.3,0.4,0.6,0.6,1.4,0.6c1,0,1.7-0.5,1.7-1.3
             c0-0.9-0.6-1.2-1.9-1.7c-1.4-0.5-2.4-1.2-2.4-2.5c0-1.5,1.3-2.4,3-2.4c0.9,0,1.5,0.1,2.1,0.3l0.1,2.1h-0.5
             c-0.3-0.5-0.5-0.9-0.8-1.3c-0.2-0.3-0.5-0.4-1.1-0.4c-1.1,0-1.5,0.5-1.5,1.3c0,0.6,0.5,1,1.9,1.6c1.5,0.6,2.5,1.2,2.5,2.4
             c0,1.5-1.2,2.7-3.2,2.7C710.6,1287.7,709.7,1287.6,709.1,1287.3z"/>
           <path class="st3" d="M719.8,1283.3c0-2.6,1.8-4.2,4-4.2c2,0,3.3,1.1,3.3,4.1h-5.5c0,2.4,1.2,3.5,2.8,3.5c1.2,0,2-0.4,2.6-0.7v0.6
             c-0.4,0.4-1.6,1.1-3.1,1.1C721.1,1287.7,719.8,1285.9,719.8,1283.3z M721.6,1282.5l3.7-0.2c0-1.8-0.3-2.5-1.5-2.5
             C722.6,1279.8,721.7,1280.6,721.6,1282.5z"/>
           <path class="st3" d="M727.8,1287l0.5-0.1c0.6-0.2,0.9-0.4,1.4-1.2l1.5-2.2l-1.8-2.5c-0.7-0.9-0.8-1.1-1.3-1.2l-0.3-0.1v-0.5h3.7
             v0.5l-0.4,0.1c-0.3,0.1-0.4,0.2-0.4,0.4c0,0.2,0.2,0.5,0.4,0.8l1.1,1.5h0l0.8-1.3c0.3-0.5,0.5-0.8,0.5-1c0-0.2-0.1-0.3-0.3-0.3
             l-0.5-0.1v-0.5h3.2v0.5l-0.5,0.1c-0.6,0.2-0.8,0.4-1.4,1.2l-1.4,1.9l2,2.7c0.6,0.8,0.8,1.1,1.4,1.2l0.2,0v0.5h-3.7v-0.5l0.4-0.1
             c0.3-0.1,0.4-0.2,0.4-0.4c0-0.2-0.2-0.5-0.5-0.8l-1.2-1.7h0l-0.9,1.5c-0.3,0.5-0.5,0.8-0.5,1c0,0.2,0.1,0.3,0.5,0.4l0.3,0.1v0.5
             h-3.2V1287z"/>
           <path class="st3" d="M736.5,1290.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-8.5c0-0.6-0.1-0.7-0.5-0.9l-0.6-0.3v-0.4l2.5-0.3l0.2,0.1
             l-0.1,0.8h0.1c0.7-0.5,1.7-0.9,2.8-0.9c1.6,0,3.2,0.9,3.2,3.9s-1.8,4.7-4.6,4.7c-0.4,0-1-0.1-1.4-0.2c0,0,0,0.5,0,1v1
             c0,0.7,0.1,0.8,0.4,0.8l0.9,0.2v0.5h-4V1290.5z M743.5,1283.4c0-2.2-0.9-3.2-2.5-3.2c-0.9,0-1.5,0.4-1.8,0.5v5.7
             c0.4,0.4,0.9,0.6,1.6,0.6C742.6,1287,743.5,1286,743.5,1283.4z"/>
           <path class="st3" d="M746.3,1283.3c0-2.6,1.8-4.2,4-4.2c2,0,3.3,1.1,3.3,4.1H748c0,2.4,1.2,3.5,2.8,3.5c1.2,0,2-0.4,2.6-0.7v0.6
             c-0.4,0.4-1.6,1.1-3.1,1.1C747.6,1287.7,746.3,1285.9,746.3,1283.3z M748,1282.5l3.7-0.2c0-1.8-0.3-2.5-1.5-2.5
             C749,1279.8,748.2,1280.6,748,1282.5z"/>
           <path class="st3" d="M754.6,1283.5c0-3.4,2.6-4.4,4.6-4.4c1.5,0,2.3,0.5,2.3,1.3c0,0.5-0.4,1-0.9,1c-0.6,0-0.8-0.3-0.9-0.9
             c-0.1-0.7-0.3-0.8-0.8-0.8c-1.3,0-2.4,0.9-2.4,3.2c0,2.4,1,3.7,2.8,3.7c1.1,0,1.9-0.4,2.5-0.7v0.6c-0.5,0.4-1.6,1.1-3.1,1.1
             C755.9,1287.7,754.6,1285.9,754.6,1283.5z"/>
           <path class="st3" d="M763.4,1286v-5.8h-1.1v-0.5c1.1-0.3,1.7-1.1,2.1-2.4h0.6v2h2.3l-0.2,0.8H765v5.6c0,0.7,0.4,1,1.1,1
             c0.5,0,0.9-0.1,1.3-0.2v0.5c-0.3,0.3-1,0.6-2.1,0.6C764.2,1287.7,763.4,1287.2,763.4,1286z"/>
           <path class="st3" d="M772.8,1286v-5.8h-1.1v-0.5c1.1-0.3,1.7-1.1,2.1-2.4h0.6v2h2.3l-0.2,0.8h-2.1v5.6c0,0.7,0.4,1,1.1,1
             c0.5,0,0.9-0.1,1.3-0.2v0.5c-0.3,0.3-1,0.6-2.1,0.6C773.7,1287.7,772.8,1287.2,772.8,1286z"/>
           <path class="st3" d="M777.4,1283.4c0-2.8,2-4.3,4-4.3c2.3,0,4,1.4,4,4.2c0,2.8-2,4.4-4,4.4
             C779.2,1287.7,777.4,1286.3,777.4,1283.4z M783.7,1283.5c0-2.7-0.8-3.8-2.3-3.8c-1.4,0-2.2,1-2.2,3.4c0,2.7,0.8,3.8,2.4,3.8
             C783,1287,783.7,1285.9,783.7,1283.5z"/>
         </g>
         <g class="st2">
           <path class="st3" d="M620.4,1307l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-5c0-0.6-0.1-0.7-0.5-0.9l-0.6-0.3v-0.4l2.6-0.3l0.2,0.1
             c0,0-0.1,0.7-0.1,1.8v5c0,0.7,0.1,0.8,0.4,0.8l0.7,0.2v0.5h-3.8V1307z M621.1,1296.6c0-0.6,0.5-1,1.1-1c0.6,0,1.1,0.5,1.1,1
             c0,0.5-0.5,1-1.1,1C621.6,1297.7,621.1,1297.2,621.1,1296.6z"/>
           <path class="st3" d="M624.9,1307l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-5c0-0.6-0.1-0.7-0.5-0.9l-0.6-0.3v-0.4l2.5-0.3l0.2,0.1
             l-0.1,0.9h0.1c0.8-0.5,2-1,3-1c1.6,0,2.2,0.7,2.2,2.1v4.8c0,0.6,0.1,0.8,0.4,0.8l0.6,0.2v0.5h-3.7v-0.5l0.7-0.2
             c0.3-0.1,0.4-0.2,0.4-0.8v-4.3c0-1.1-0.4-1.5-1.4-1.5c-0.8,0-1.7,0.3-2.2,0.5v5.3c0,0.7,0.1,0.8,0.4,0.8l0.7,0.2v0.5h-3.8V1307z"
             />
           <path class="st3" d="M634.7,1303.5c0-3.4,2.6-4.4,4.6-4.4c1.5,0,2.3,0.5,2.3,1.3c0,0.5-0.4,1-0.9,1c-0.6,0-0.8-0.3-0.9-0.9
             c-0.1-0.7-0.3-0.8-0.8-0.8c-1.3,0-2.4,0.9-2.4,3.2c0,2.4,1,3.7,2.8,3.7c1.1,0,1.9-0.4,2.5-0.7v0.6c-0.5,0.4-1.6,1.1-3.1,1.1
             C636,1307.7,634.7,1305.9,634.7,1303.5z"/>
           <path class="st3" d="M642.5,1307l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-5c0-0.6-0.1-0.7-0.5-0.9l-0.6-0.3v-0.4l2.5-0.3l0.2,0.1
             l-0.1,0.9h0.1c0.6-0.6,1.6-1.1,2.4-1.1c0.7,0,1.1,0.4,1.1,1c0,0.6-0.4,0.9-0.9,0.9c-0.6,0-0.8-0.2-0.9-0.5
             c-0.1-0.2-0.2-0.3-0.4-0.3c-0.3,0-1,0.4-1.2,0.7v5.1c0,0.7,0.1,0.8,0.4,0.8l0.8,0.2v0.5h-3.9V1307z"/>
           <path class="st3" d="M649.1,1303.3c0-2.6,1.8-4.2,4-4.2c2,0,3.3,1.1,3.3,4.1h-5.5c0,2.4,1.2,3.5,2.8,3.5c1.2,0,2-0.4,2.6-0.7v0.6
             c-0.4,0.4-1.6,1.1-3.1,1.1C650.4,1307.7,649.1,1305.9,649.1,1303.3z M650.8,1302.5l3.7-0.2c0-1.8-0.3-2.5-1.5-2.5
             C651.8,1299.8,651,1300.6,650.8,1302.5z"/>
           <path class="st3" d="M657.4,1305.6c0-1.3,0.9-2,2.8-2.4l1.9-0.4v-1.5c0-1.1-0.2-1.6-1.5-1.6c-0.7,0-0.8,0.2-0.9,0.6
             c-0.1,0.6-0.2,1.1-1,1.1c-0.6,0-0.8-0.4-0.8-0.8c0-1.1,1.7-1.5,3-1.5c1.9,0,2.8,0.7,2.8,2.1v4.7c0,0.4,0.1,0.7,0.4,0.8
             c0.2,0.1,0.4,0.1,0.8,0.1v0.4c-0.3,0.2-0.8,0.4-1.4,0.4c-0.8,0-1.2-0.4-1.3-0.9H662c-0.4,0.5-1.3,0.9-2.2,0.9
             C658.3,1307.7,657.4,1307,657.4,1305.6z M662.1,1306v-2.5l-1.2,0.1c-1.2,0.1-1.8,0.5-1.8,1.7c0,0.9,0.6,1.3,1.5,1.3
             C661.3,1306.8,661.8,1306.4,662.1,1306z"/>
           <path class="st3" d="M665.7,1307.3l-0.1-2.4h0.5c0.3,0.6,0.4,1,0.8,1.4c0.3,0.4,0.6,0.6,1.4,0.6c1,0,1.7-0.5,1.7-1.3
             c0-0.9-0.6-1.2-1.9-1.7c-1.4-0.5-2.4-1.2-2.4-2.5c0-1.5,1.3-2.4,3-2.4c0.9,0,1.5,0.1,2.1,0.3l0.1,2.1h-0.5
             c-0.3-0.5-0.5-0.9-0.8-1.3c-0.2-0.3-0.5-0.4-1.1-0.4c-1.1,0-1.5,0.5-1.5,1.3c0,0.6,0.5,1,1.9,1.6c1.5,0.6,2.5,1.2,2.5,2.4
             c0,1.5-1.2,2.7-3.2,2.7C667.2,1307.7,666.4,1307.6,665.7,1307.3z"/>
           <path class="st3" d="M672.5,1303.3c0-2.6,1.8-4.2,4-4.2c2,0,3.3,1.1,3.3,4.1h-5.5c0,2.4,1.2,3.5,2.8,3.5c1.2,0,2-0.4,2.6-0.7v0.6
             c-0.4,0.4-1.6,1.1-3.1,1.1C673.8,1307.7,672.5,1305.9,672.5,1303.3z M674.2,1302.5l3.7-0.2c0-1.8-0.3-2.5-1.5-2.5
             C675.2,1299.8,674.3,1300.6,674.2,1302.5z"/>
           <path class="st3" d="M684.6,1307l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-8.9c0-0.6-0.1-0.7-0.5-0.8l-0.7-0.3v-0.4l2.7-0.3l0.2,0.1
             c0,0-0.1,0.8-0.1,1.9v1.5c0,0.7-0.1,1.4-0.1,1.4h0.1c0.8-0.5,2-1,3-1c1.6,0,2.2,0.7,2.2,2.1v4.8c0,0.6,0.1,0.8,0.4,0.8l0.6,0.2
             v0.5h-3.7v-0.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-4.3c0-1.1-0.4-1.5-1.4-1.5c-0.8,0-1.7,0.3-2.2,0.5v5.3c0,0.7,0.1,0.8,0.4,0.8
             l0.7,0.2v0.5h-3.8V1307z"/>
           <path class="st3" d="M694.3,1307l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-5c0-0.6-0.1-0.7-0.5-0.9l-0.6-0.3v-0.4l2.6-0.3l0.2,0.1
             c0,0-0.1,0.7-0.1,1.8v5c0,0.7,0.1,0.8,0.4,0.8l0.7,0.2v0.5h-3.8V1307z M695,1296.6c0-0.6,0.5-1,1.1-1c0.6,0,1.1,0.5,1.1,1
             c0,0.5-0.5,1-1.1,1C695.5,1297.7,695,1297.2,695,1296.6z"/>
           <path class="st3" d="M698.8,1307l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-5c0-0.6-0.1-0.7-0.5-0.9l-0.6-0.3v-0.4l2.5-0.3l0.2,0.1
             l-0.1,0.9h0.1c0.6-0.6,1.6-1.1,2.4-1.1c0.7,0,1.1,0.4,1.1,1c0,0.6-0.4,0.9-0.9,0.9c-0.6,0-0.8-0.2-0.9-0.5
             c-0.1-0.2-0.2-0.3-0.4-0.3c-0.3,0-1,0.4-1.2,0.7v5.1c0,0.7,0.1,0.8,0.4,0.8l0.8,0.2v0.5h-3.9V1307z"/>
           <path class="st3" d="M705.6,1307l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-5c0-0.6-0.1-0.7-0.5-0.9l-0.6-0.3v-0.4l2.6-0.3l0.2,0.1
             c0,0-0.1,0.7-0.1,1.8v5c0,0.7,0.1,0.8,0.4,0.8l0.7,0.2v0.5h-3.8V1307z M706.3,1296.6c0-0.6,0.5-1,1.1-1c0.6,0,1.1,0.5,1.1,1
             c0,0.5-0.5,1-1.1,1C706.8,1297.7,706.3,1297.2,706.3,1296.6z"/>
           <path class="st3" d="M710,1307l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-5c0-0.6-0.1-0.7-0.5-0.9l-0.6-0.3v-0.4l2.5-0.3l0.2,0.1l-0.1,0.9
             h0.1c0.8-0.5,2-1,3-1c1.6,0,2.2,0.7,2.2,2.1v4.8c0,0.6,0.1,0.8,0.4,0.8l0.6,0.2v0.5h-3.7v-0.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8
             v-4.3c0-1.1-0.4-1.5-1.4-1.5c-0.8,0-1.7,0.3-2.2,0.5v5.3c0,0.7,0.1,0.8,0.4,0.8l0.7,0.2v0.5H710V1307z"/>
           <path class="st3" d="M719.2,1309.2c0-0.8,0.7-1.5,1.8-1.9v-0.1c-0.5-0.2-1.1-0.6-1.1-1.3c0-0.9,0.7-1.4,1.2-1.8v-0.1
             c-0.7-0.3-1.4-1.1-1.4-2.1c0-1.9,1.6-2.8,3.2-2.8c0.6,0,1.1,0.1,1.7,0.3c0.6-0.5,1.3-0.9,2-0.9c0.5,0,0.9,0.3,0.9,0.8
             c0,0.4-0.2,0.8-0.7,0.8c-0.4,0-0.6-0.2-0.7-0.5c-0.1-0.2-0.7,0-1.1,0.2v0c0.7,0.4,1.1,1,1.1,1.9c0,1.9-1.6,2.7-3.2,2.7
             c-0.5,0-0.8-0.1-1.2-0.2c-0.3,0.2-0.6,0.5-0.6,0.9c0,0.4,0.3,0.7,1,0.7h2.5c1.4,0,2.3,0.4,2.3,2c0,1.2-1,3.2-4.4,3.2
             C720.5,1311.1,719.2,1310.3,719.2,1309.2z M725.9,1308.5c0-0.7-0.3-1.2-1.4-1.2H722c-0.5,0.1-1.3,0.8-1.3,1.6c0,1,0.7,1.5,2.3,1.5
             C724.5,1310.4,725.9,1309.6,725.9,1308.5z M724.6,1301.9c0-1.6-0.5-2.2-1.6-2.2c-1,0-1.5,0.6-1.5,2c0,1.6,0.5,2.1,1.5,2.1
             C724,1303.8,724.6,1303.3,724.6,1301.9z"/>
         </g>
         <g class="st2">
           <path class="st4" d="M622.2,1243.5c0-2.8,1.7-4.6,4.9-4.6c2.8,0,4.4,1.3,5.1,4.7c1.1,4.8,2,6,6.5,6c10.9,0,17.8-7,17.8-18.4
             c0-10.7-6.6-15.6-17-15.6c-5.5,0-10,0.9-12.7,1.9l-0.6-0.5l3.9-34.8h25.3c2.4,0,3-0.3,5.1-4.9h1.7l-1.7,11.9h-27.8l-2.4,21
             c2.6-0.9,6.1-1.5,10.9-1.5c11,0,22.2,4.9,22.2,20.3c0,13.6-11.6,23.7-25.7,23.7C628.6,1252.7,622.2,1249.4,622.2,1243.5z"/>
           <path class="st4" d="M704.2,1189.3h-29.7c-3.3,0-3.7,1.1-5.9,6.3h-1.7l1.6-13.4h41.7v3.4c-12.6,19.1-20.8,37.6-25.5,65.9h-7.9
             C683,1223.6,694.7,1204.4,704.2,1189.3z"/>
           <path class="st4" d="M714.3,1199.6c0-12.3,8-18.4,15.2-18.4c7,0,15,5.3,15,17.3c0,12.3-8.5,18-15.2,18
             C722.5,1216.5,714.3,1211.4,714.3,1199.6z M767.5,1180.1h4l-49.5,76.4h-4.1L767.5,1180.1z M737.2,1199.6c0-10.6-2.5-16.1-7.9-16.1
             c-5.3,0-7.7,5-7.7,14.9c0,10.5,2.6,15.9,7.7,15.9C734.6,1214.3,737.2,1209.7,737.2,1199.6z M746.4,1236c0-12.3,8-18.4,15.2-18.4
             c7,0,15,5.3,15,17.3c0,12.3-8.5,18-15.2,18C754.6,1252.9,746.4,1247.8,746.4,1236z M769.3,1236c0-10.6-2.5-16.1-7.9-16.1
             c-5.3,0-7.7,5-7.7,14.9c0,10.5,2.6,15.9,7.7,15.9C766.7,1250.7,769.3,1246.1,769.3,1236z"/>
         </g>
       </g>
       </svg>
       `),
        this.sanitizer.bypassSecurityTrustHtml(`<svg class="stat-card-svg" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 300 200" style="enable-background:new 0 0 300 200;" xml:space="preserve">
     <style type="text/css">
       .st0{fill:none;}
       .st1{fill:none;stroke:#6E27C5;stroke-width:4;stroke-miterlimit:10;}
       .st2{enable-background:new    ;}
       .st3{fill:#333333;}
       .st4{fill:#6E27C5;}
     </style>
     <g id="Group_17768" transform="translate(-914 -1149.5)">
       <g id="Rectangle_4_Copy_6" transform="translate(914 1149.5)">
         <rect class="st0" width="300" height="200"/>
         <rect x="2" y="2" class="st1" width="296" height="196"/>
       </g>
       <g class="st2">
         <path class="st3" d="M944.5,1283.4c0-2.8,2-4.3,4-4.3c2.3,0,4,1.4,4,4.2c0,2.8-2,4.4-4,4.4
           C946.3,1287.7,944.5,1286.3,944.5,1283.4z M950.9,1283.5c0-2.7-0.8-3.8-2.3-3.8c-1.4,0-2.2,1-2.2,3.4c0,2.7,0.8,3.8,2.4,3.8
           C950.1,1287,950.9,1285.9,950.9,1283.5z"/>
         <path class="st3" d="M953.6,1287l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-5.8h-1.1v-0.5l1.1-0.4v-0.9c0-2,1.5-3.2,3.6-3.2
           c1,0,1.5,0.4,1.5,1c0,0.4-0.3,0.8-0.8,0.8c-0.6,0-0.9-0.3-1-0.8c0-0.3-0.1-0.4-0.3-0.4c-0.6,0-1.4,0.4-1.4,1.8v1.7h1.9l-0.2,0.8
           h-1.6v5.7c0,0.8,0.1,0.8,0.4,0.9l0.9,0.2v0.5h-4V1287z"/>
         <path class="st3" d="M962.8,1283.3c0-2.6,1.8-4.2,4-4.2c2,0,3.3,1.1,3.3,4.1h-5.5c0,2.4,1.2,3.5,2.8,3.5c1.2,0,2-0.4,2.6-0.7v0.6
           c-0.4,0.4-1.6,1.1-3.1,1.1C964.1,1287.7,962.8,1285.9,962.8,1283.3z M964.5,1282.5l3.7-0.2c0-1.8-0.3-2.5-1.5-2.5
           C965.6,1279.8,964.7,1280.6,964.5,1282.5z"/>
         <path class="st3" d="M971,1287l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-5c0-0.6-0.1-0.7-0.5-0.9l-0.6-0.3v-0.4l2.5-0.3l0.2,0.1l-0.1,0.9
           h0.1c0.8-0.5,2-1,3-1c1.6,0,2.2,0.7,2.2,2.1v4.8c0,0.6,0.1,0.8,0.4,0.8l0.6,0.2v0.5h-3.7v-0.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8
           v-4.3c0-1.1-0.4-1.5-1.4-1.5c-0.8,0-1.7,0.3-2.2,0.5v5.3c0,0.7,0.1,0.8,0.4,0.8l0.7,0.2v0.5H971V1287z"/>
         <path class="st3" d="M981.6,1286v-5.8h-1.1v-0.5c1.1-0.3,1.7-1.1,2.1-2.4h0.6v2h2.3l-0.2,0.8h-2.1v5.6c0,0.7,0.4,1,1.1,1
           c0.5,0,0.9-0.1,1.3-0.2v0.5c-0.3,0.3-1,0.6-2.1,0.6C982.4,1287.7,981.6,1287.2,981.6,1286z"/>
         <path class="st3" d="M986.2,1283.3c0-2.6,1.8-4.2,4-4.2c2,0,3.3,1.1,3.3,4.1h-5.5c0,2.4,1.2,3.5,2.8,3.5c1.2,0,2-0.4,2.6-0.7v0.6
           c-0.4,0.4-1.6,1.1-3.1,1.1C987.5,1287.7,986.2,1285.9,986.2,1283.3z M987.9,1282.5l3.7-0.2c0-1.8-0.3-2.5-1.5-2.5
           C988.9,1279.8,988,1280.6,987.9,1282.5z"/>
         <path class="st3" d="M994.3,1287l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-5c0-0.6-0.1-0.7-0.5-0.9l-0.6-0.3v-0.4l2.5-0.3l0.2,0.1
           l-0.1,0.9h0.1c0.6-0.6,1.6-1.1,2.4-1.1c0.7,0,1.1,0.4,1.1,1c0,0.6-0.4,0.9-0.9,0.9c-0.6,0-0.8-0.2-0.9-0.5
           c-0.1-0.2-0.2-0.3-0.4-0.3c-0.3,0-1,0.4-1.2,0.7v5.1c0,0.7,0.1,0.8,0.4,0.8l0.8,0.2v0.5h-3.9V1287z"/>
         <path class="st3" d="M1001.1,1290.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-8.5c0-0.6-0.1-0.7-0.5-0.9l-0.6-0.3v-0.4l2.5-0.3l0.2,0.1
           l-0.1,0.8h0.1c0.7-0.5,1.7-0.9,2.8-0.9c1.6,0,3.2,0.9,3.2,3.9s-1.8,4.7-4.6,4.7c-0.4,0-1-0.1-1.4-0.2c0,0,0,0.5,0,1v1
           c0,0.7,0.1,0.8,0.4,0.8l0.9,0.2v0.5h-4V1290.5z M1008.1,1283.4c0-2.2-0.9-3.2-2.5-3.2c-0.9,0-1.5,0.4-1.8,0.5v5.7
           c0.4,0.4,0.9,0.6,1.6,0.6C1007.2,1287,1008.1,1286,1008.1,1283.4z"/>
         <path class="st3" d="M1010.7,1287l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-5c0-0.6-0.1-0.7-0.5-0.9l-0.6-0.3v-0.4l2.5-0.3l0.2,0.1
           l-0.1,0.9h0.1c0.6-0.6,1.6-1.1,2.4-1.1c0.7,0,1.1,0.4,1.1,1c0,0.6-0.4,0.9-0.9,0.9c-0.6,0-0.8-0.2-0.9-0.5
           c-0.1-0.2-0.2-0.3-0.4-0.3c-0.3,0-1,0.4-1.2,0.7v5.1c0,0.7,0.1,0.8,0.4,0.8l0.8,0.2v0.5h-3.9V1287z"/>
         <path class="st3" d="M1017.6,1287l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-5c0-0.6-0.1-0.7-0.5-0.9l-0.6-0.3v-0.4l2.6-0.3l0.2,0.1
           c0,0-0.1,0.7-0.1,1.8v5c0,0.7,0.1,0.8,0.4,0.8l0.7,0.2v0.5h-3.8V1287z M1018.3,1276.6c0-0.6,0.5-1,1.1-1c0.6,0,1.1,0.5,1.1,1
           c0,0.5-0.5,1-1.1,1C1018.8,1277.7,1018.3,1277.2,1018.3,1276.6z"/>
         <path class="st3" d="M1022.4,1287.3l-0.1-2.4h0.5c0.3,0.6,0.4,1,0.8,1.4c0.3,0.4,0.6,0.6,1.4,0.6c1,0,1.7-0.5,1.7-1.3
           c0-0.9-0.6-1.2-1.9-1.7c-1.4-0.5-2.4-1.2-2.4-2.5c0-1.5,1.3-2.4,3-2.4c0.9,0,1.5,0.1,2.1,0.3l0.1,2.1h-0.5
           c-0.3-0.5-0.5-0.9-0.8-1.3c-0.2-0.3-0.5-0.4-1.1-0.4c-1.1,0-1.5,0.5-1.5,1.3c0,0.6,0.5,1,1.9,1.6c1.5,0.6,2.5,1.2,2.5,2.4
           c0,1.5-1.2,2.7-3.2,2.7C1023.9,1287.7,1023,1287.6,1022.4,1287.3z"/>
         <path class="st3" d="M1029.1,1283.3c0-2.6,1.8-4.2,4-4.2c2,0,3.3,1.1,3.3,4.1h-5.5c0,2.4,1.2,3.5,2.8,3.5c1.2,0,2-0.4,2.6-0.7v0.6
           c-0.4,0.4-1.6,1.1-3.1,1.1C1030.4,1287.7,1029.1,1285.9,1029.1,1283.3z M1030.8,1282.5l3.7-0.2c0-1.8-0.3-2.5-1.5-2.5
           C1031.8,1279.8,1031,1280.6,1030.8,1282.5z"/>
         <path class="st3" d="M1037.6,1287.3l-0.1-2.4h0.5c0.3,0.6,0.4,1,0.8,1.4c0.3,0.4,0.6,0.6,1.4,0.6c1,0,1.7-0.5,1.7-1.3
           c0-0.9-0.6-1.2-1.9-1.7c-1.4-0.5-2.4-1.2-2.4-2.5c0-1.5,1.3-2.4,3-2.4c0.9,0,1.5,0.1,2.1,0.3l0.1,2.1h-0.5
           c-0.3-0.5-0.5-0.9-0.8-1.3c-0.2-0.3-0.5-0.4-1.1-0.4c-1.1,0-1.5,0.5-1.5,1.3c0,0.6,0.5,1,1.9,1.6c1.5,0.6,2.5,1.2,2.5,2.4
           c0,1.5-1.2,2.7-3.2,2.7C1039.1,1287.7,1038.2,1287.6,1037.6,1287.3z"/>
         <path class="st3" d="M1048.2,1285.6c0-1.3,0.9-2,2.8-2.4l1.9-0.4v-1.5c0-1.1-0.2-1.6-1.5-1.6c-0.7,0-0.8,0.2-0.9,0.6
           c-0.1,0.6-0.2,1.1-1,1.1c-0.6,0-0.8-0.4-0.8-0.8c0-1.1,1.7-1.5,3-1.5c1.9,0,2.8,0.7,2.8,2.1v4.7c0,0.4,0.1,0.7,0.4,0.8
           c0.2,0.1,0.4,0.1,0.8,0.1v0.4c-0.3,0.2-0.8,0.4-1.4,0.4c-0.8,0-1.2-0.4-1.3-0.9h-0.1c-0.4,0.5-1.3,0.9-2.2,0.9
           C1049.2,1287.7,1048.2,1287,1048.2,1285.6z M1052.9,1286v-2.5l-1.2,0.1c-1.2,0.1-1.8,0.5-1.8,1.7c0,0.9,0.6,1.3,1.5,1.3
           C1052.1,1286.8,1052.7,1286.4,1052.9,1286z"/>
         <path class="st3" d="M1056.3,1287l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-5c0-0.6-0.1-0.7-0.5-0.9l-0.6-0.3v-0.4l2.5-0.3l0.2,0.1
           l-0.1,0.9h0.1c0.6-0.6,1.6-1.1,2.4-1.1c0.7,0,1.1,0.4,1.1,1c0,0.6-0.4,0.9-0.9,0.9c-0.6,0-0.8-0.2-0.9-0.5
           c-0.1-0.2-0.2-0.3-0.4-0.3c-0.3,0-1,0.4-1.2,0.7v5.1c0,0.7,0.1,0.8,0.4,0.8l0.8,0.2v0.5h-3.9V1287z"/>
         <path class="st3" d="M1063,1283.3c0-2.6,1.8-4.2,4-4.2c2,0,3.3,1.1,3.3,4.1h-5.5c0,2.4,1.2,3.5,2.8,3.5c1.2,0,2-0.4,2.6-0.7v0.6
           c-0.4,0.4-1.6,1.1-3.1,1.1C1064.3,1287.7,1063,1285.9,1063,1283.3z M1064.7,1282.5l3.7-0.2c0-1.8-0.3-2.5-1.5-2.5
           C1065.7,1279.8,1064.8,1280.6,1064.7,1282.5z"/>
         <path class="st3" d="M1075.2,1290.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-8.5c0-0.6-0.1-0.7-0.5-0.9l-0.6-0.3v-0.4l2.5-0.3l0.2,0.1
           l-0.1,0.8h0.1c0.7-0.5,1.7-0.9,2.8-0.9c1.6,0,3.2,0.9,3.2,3.9s-1.8,4.7-4.6,4.7c-0.4,0-1-0.1-1.4-0.2c0,0,0,0.5,0,1v1
           c0,0.7,0.1,0.8,0.4,0.8l0.9,0.2v0.5h-4V1290.5z M1082.1,1283.4c0-2.2-0.9-3.2-2.5-3.2c-0.9,0-1.5,0.4-1.8,0.5v5.7
           c0.4,0.4,0.9,0.6,1.6,0.6C1081.2,1287,1082.1,1286,1082.1,1283.4z"/>
         <path class="st3" d="M1085,1283.4c0-2.8,2-4.3,4-4.3c2.3,0,4,1.4,4,4.2c0,2.8-2,4.4-4,4.4C1086.7,1287.7,1085,1286.3,1085,1283.4z
            M1091.3,1283.5c0-2.7-0.8-3.8-2.3-3.8c-1.4,0-2.2,1-2.2,3.4c0,2.7,0.8,3.8,2.4,3.8C1090.5,1287,1091.3,1285.9,1091.3,1283.5z"/>
         <path class="st3" d="M1094,1287l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-5c0-0.6-0.1-0.7-0.5-0.9l-0.6-0.3v-0.4l2.6-0.3l0.2,0.1
           c0,0-0.1,0.7-0.1,1.8v5c0,0.7,0.1,0.8,0.4,0.8l0.7,0.2v0.5h-3.8V1287z M1094.7,1276.6c0-0.6,0.5-1,1.1-1c0.6,0,1.1,0.5,1.1,1
           c0,0.5-0.5,1-1.1,1C1095.2,1277.7,1094.7,1277.2,1094.7,1276.6z"/>
         <path class="st3" d="M1098.8,1287.3l-0.1-2.4h0.5c0.3,0.6,0.4,1,0.8,1.4c0.3,0.4,0.6,0.6,1.4,0.6c1,0,1.7-0.5,1.7-1.3
           c0-0.9-0.6-1.2-1.9-1.7c-1.4-0.5-2.4-1.2-2.4-2.5c0-1.5,1.3-2.4,3-2.4c0.9,0,1.5,0.1,2.1,0.3l0.1,2.1h-0.5
           c-0.3-0.5-0.5-0.9-0.8-1.3c-0.2-0.3-0.5-0.4-1.1-0.4c-1.1,0-1.5,0.5-1.5,1.3c0,0.6,0.5,1,1.9,1.6c1.5,0.6,2.5,1.2,2.5,2.4
           c0,1.5-1.2,2.7-3.2,2.7C1100.3,1287.7,1099.5,1287.6,1098.8,1287.3z"/>
         <path class="st3" d="M1105.6,1283.3c0-2.6,1.8-4.2,4-4.2c2,0,3.3,1.1,3.3,4.1h-5.5c0,2.4,1.2,3.5,2.8,3.5c1.2,0,2-0.4,2.6-0.7v0.6
           c-0.4,0.4-1.6,1.1-3.1,1.1C1106.9,1287.7,1105.6,1285.9,1105.6,1283.3z M1107.3,1282.5l3.7-0.2c0-1.8-0.3-2.5-1.5-2.5
           C1108.3,1279.8,1107.4,1280.6,1107.3,1282.5z"/>
         <path class="st3" d="M1113.9,1283.7c0-3.1,2.2-4.6,4.5-4.6c0.7,0,1.2,0.1,1.5,0.2c0,0,0-0.6,0-1.2v-1c0-0.6-0.1-0.7-0.5-0.8
           l-0.7-0.3v-0.4l2.7-0.3l0.2,0.1c0,0-0.1,0.6-0.1,1.9v8.4c0,0.7,0.1,0.8,0.5,1l0.6,0.3v0.4l-2.5,0.3l-0.1-0.1l0.1-0.8h-0.1
           c-0.7,0.5-1.7,0.9-2.8,0.9C1115.6,1287.7,1113.9,1286.7,1113.9,1283.7z M1119.9,1286.1v-5.6c-0.4-0.3-0.8-0.7-1.6-0.7
           c-1.7,0-2.7,0.9-2.7,3.5c0,2.3,0.9,3.2,2.4,3.2C1119,1286.6,1119.6,1286.3,1119.9,1286.1z"/>
         <path class="st3" d="M1127.3,1287l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-5.8h-1.1v-0.5l1.1-0.4v-0.9c0-2,1.5-3.2,3.6-3.2
           c1,0,1.5,0.4,1.5,1c0,0.4-0.3,0.8-0.8,0.8c-0.6,0-0.9-0.3-1-0.8c0-0.3-0.1-0.4-0.3-0.4c-0.6,0-1.4,0.4-1.4,1.8v1.7h1.9l-0.2,0.8
           h-1.6v5.7c0,0.8,0.1,0.8,0.4,0.9l0.9,0.2v0.5h-4V1287z"/>
         <path class="st3" d="M1132.5,1283.4c0-2.8,2-4.3,4-4.3c2.3,0,4,1.4,4,4.2c0,2.8-2,4.4-4,4.4
           C1134.2,1287.7,1132.5,1286.3,1132.5,1283.4z M1138.8,1283.5c0-2.7-0.8-3.8-2.3-3.8c-1.4,0-2.2,1-2.2,3.4c0,2.7,0.8,3.8,2.4,3.8
           C1138,1287,1138.8,1285.9,1138.8,1283.5z"/>
         <path class="st3" d="M1141.5,1287l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-5c0-0.6-0.1-0.7-0.5-0.9l-0.6-0.3v-0.4l2.5-0.3l0.2,0.1
           l-0.1,0.9h0.1c0.6-0.6,1.6-1.1,2.4-1.1c0.7,0,1.1,0.4,1.1,1c0,0.6-0.4,0.9-0.9,0.9c-0.6,0-0.8-0.2-0.9-0.5
           c-0.1-0.2-0.2-0.3-0.4-0.3c-0.3,0-1,0.4-1.2,0.7v5.1c0,0.7,0.1,0.8,0.4,0.8l0.8,0.2v0.5h-3.9V1287z"/>
       </g>
       <g class="st2">
         <path class="st3" d="M944.4,1307l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-5c0-0.6-0.1-0.7-0.5-0.9l-0.6-0.3v-0.4l2.6-0.3l0.2,0.1
           c0,0-0.1,0.7-0.1,1.8v5c0,0.7,0.1,0.8,0.4,0.8l0.7,0.2v0.5h-3.8V1307z M945.1,1296.6c0-0.6,0.5-1,1.1-1c0.6,0,1.1,0.5,1.1,1
           c0,0.5-0.5,1-1.1,1C945.6,1297.7,945.1,1297.2,945.1,1296.6z"/>
         <path class="st3" d="M948.9,1307l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-5c0-0.6-0.1-0.7-0.5-0.9l-0.6-0.3v-0.4l2.5-0.3l0.2,0.1
           l-0.1,0.9h0.1c0.8-0.5,2-1,3-1c1.6,0,2.2,0.7,2.2,2.1v4.8c0,0.6,0.1,0.8,0.4,0.8l0.6,0.2v0.5h-3.7v-0.5l0.7-0.2
           c0.3-0.1,0.4-0.2,0.4-0.8v-4.3c0-1.1-0.4-1.5-1.4-1.5c-0.8,0-1.7,0.3-2.2,0.5v5.3c0,0.7,0.1,0.8,0.4,0.8l0.7,0.2v0.5h-3.8V1307z"
           />
         <path class="st3" d="M958.7,1303.5c0-3.4,2.6-4.4,4.6-4.4c1.5,0,2.3,0.5,2.3,1.3c0,0.5-0.4,1-0.9,1c-0.6,0-0.8-0.3-0.9-0.9
           c-0.1-0.7-0.3-0.8-0.8-0.8c-1.3,0-2.4,0.9-2.4,3.2c0,2.4,1,3.7,2.8,3.7c1.1,0,1.9-0.4,2.5-0.7v0.6c-0.5,0.4-1.6,1.1-3.1,1.1
           C960,1307.7,958.7,1305.9,958.7,1303.5z"/>
         <path class="st3" d="M966.5,1307l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-5c0-0.6-0.1-0.7-0.5-0.9l-0.6-0.3v-0.4l2.5-0.3l0.2,0.1
           l-0.1,0.9h0.1c0.6-0.6,1.6-1.1,2.4-1.1c0.7,0,1.1,0.4,1.1,1c0,0.6-0.4,0.9-0.9,0.9c-0.6,0-0.8-0.2-0.9-0.5
           c-0.1-0.2-0.2-0.3-0.4-0.3c-0.3,0-1,0.4-1.2,0.7v5.1c0,0.7,0.1,0.8,0.4,0.8l0.8,0.2v0.5h-3.9V1307z"/>
         <path class="st3" d="M973.1,1303.3c0-2.6,1.8-4.2,4-4.2c2,0,3.3,1.1,3.3,4.1h-5.5c0,2.4,1.2,3.5,2.8,3.5c1.2,0,2-0.4,2.6-0.7v0.6
           c-0.4,0.4-1.6,1.1-3.1,1.1C974.4,1307.7,973.1,1305.9,973.1,1303.3z M974.8,1302.5l3.7-0.2c0-1.8-0.3-2.5-1.5-2.5
           C975.8,1299.8,975,1300.6,974.8,1302.5z"/>
         <path class="st3" d="M981.4,1305.6c0-1.3,0.9-2,2.8-2.4l1.9-0.4v-1.5c0-1.1-0.2-1.6-1.5-1.6c-0.7,0-0.8,0.2-0.9,0.6
           c-0.1,0.6-0.2,1.1-1,1.1c-0.6,0-0.8-0.4-0.8-0.8c0-1.1,1.7-1.5,3-1.5c1.9,0,2.8,0.7,2.8,2.1v4.7c0,0.4,0.1,0.7,0.4,0.8
           c0.2,0.1,0.4,0.1,0.8,0.1v0.4c-0.3,0.2-0.8,0.4-1.4,0.4c-0.8,0-1.2-0.4-1.3-0.9H986c-0.4,0.5-1.3,0.9-2.2,0.9
           C982.3,1307.7,981.4,1307,981.4,1305.6z M986.1,1306v-2.5l-1.2,0.1c-1.2,0.1-1.8,0.5-1.8,1.7c0,0.9,0.6,1.3,1.5,1.3
           C985.3,1306.8,985.8,1306.4,986.1,1306z"/>
         <path class="st3" d="M989.7,1307.3l-0.1-2.4h0.5c0.3,0.6,0.4,1,0.8,1.4c0.3,0.4,0.6,0.6,1.4,0.6c1,0,1.7-0.5,1.7-1.3
           c0-0.9-0.6-1.2-1.9-1.7c-1.4-0.5-2.4-1.2-2.4-2.5c0-1.5,1.3-2.4,3-2.4c0.9,0,1.5,0.1,2.1,0.3l0.1,2.1h-0.5
           c-0.3-0.5-0.5-0.9-0.8-1.3c-0.2-0.3-0.5-0.4-1.1-0.4c-1.1,0-1.5,0.5-1.5,1.3c0,0.6,0.5,1,1.9,1.6c1.5,0.6,2.5,1.2,2.5,2.4
           c0,1.5-1.2,2.7-3.2,2.7C991.2,1307.7,990.4,1307.6,989.7,1307.3z"/>
         <path class="st3" d="M996.5,1303.3c0-2.6,1.8-4.2,4-4.2c2,0,3.3,1.1,3.3,4.1h-5.5c0,2.4,1.2,3.5,2.8,3.5c1.2,0,2-0.4,2.6-0.7v0.6
           c-0.4,0.4-1.6,1.1-3.1,1.1C997.8,1307.7,996.5,1305.9,996.5,1303.3z M998.2,1302.5l3.7-0.2c0-1.8-0.3-2.5-1.5-2.5
           C999.2,1299.8,998.3,1300.6,998.2,1302.5z"/>
         <path class="st3" d="M1004.8,1303.7c0-3.1,2.2-4.6,4.5-4.6c0.7,0,1.2,0.1,1.5,0.2c0,0,0-0.6,0-1.2v-1c0-0.6-0.1-0.7-0.5-0.8
           l-0.7-0.3v-0.4l2.7-0.3l0.2,0.1c0,0-0.1,0.6-0.1,1.9v8.4c0,0.7,0.1,0.8,0.5,1l0.6,0.3v0.4l-2.5,0.3l-0.1-0.1l0.1-0.8h-0.1
           c-0.7,0.5-1.7,0.9-2.8,0.9C1006.5,1307.7,1004.8,1306.7,1004.8,1303.7z M1010.8,1306.1v-5.6c-0.4-0.3-0.8-0.7-1.6-0.7
           c-1.7,0-2.7,0.9-2.7,3.5c0,2.3,0.9,3.2,2.4,3.2C1009.9,1306.6,1010.4,1306.3,1010.8,1306.1z"/>
         <path class="st3" d="M1018.2,1307l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-5c0-0.6-0.1-0.7-0.5-0.9l-0.6-0.3v-0.4l2.6-0.3l0.2,0.1
           c0,0-0.1,0.7-0.1,1.8v5c0,0.7,0.1,0.8,0.4,0.8l0.7,0.2v0.5h-3.8V1307z M1018.9,1296.6c0-0.6,0.5-1,1.1-1c0.6,0,1.1,0.5,1.1,1
           c0,0.5-0.5,1-1.1,1C1019.4,1297.7,1018.9,1297.2,1018.9,1296.6z"/>
         <path class="st3" d="M1022.7,1307l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-5c0-0.6-0.1-0.7-0.5-0.9l-0.6-0.3v-0.4l2.5-0.3l0.2,0.1
           l-0.1,0.9h0.1c0.8-0.5,2-1,3-1c1.6,0,2.2,0.7,2.2,2.1v4.8c0,0.6,0.1,0.8,0.4,0.8l0.6,0.2v0.5h-3.7v-0.5l0.7-0.2
           c0.3-0.1,0.4-0.2,0.4-0.8v-4.3c0-1.1-0.4-1.5-1.4-1.5c-0.8,0-1.7,0.3-2.2,0.5v5.3c0,0.7,0.1,0.8,0.4,0.8l0.7,0.2v0.5h-3.8V1307z"
           />
         <path class="st3" d="M1032.8,1301c-0.3-0.7-0.4-0.9-0.9-1.1l-0.4-0.1v-0.5h3.7v0.5l-0.4,0.1c-0.4,0.1-0.4,0.3-0.4,0.5
           c0,0.2,0.1,0.5,0.2,0.8l1.6,4.4h0.1l1.6-4.4c0.1-0.4,0.2-0.7,0.2-0.9c0-0.2-0.1-0.4-0.4-0.5l-0.4-0.1v-0.5h2.9v0.5l-0.4,0.1
           c-0.4,0.1-0.5,0.4-0.8,1.2l-2.4,6.3h-0.9L1032.8,1301z"/>
         <path class="st3" d="M1040.3,1303.3c0-2.6,1.8-4.2,4-4.2c2,0,3.3,1.1,3.3,4.1h-5.5c0,2.4,1.2,3.5,2.8,3.5c1.2,0,2-0.4,2.6-0.7v0.6
           c-0.4,0.4-1.6,1.1-3.1,1.1C1041.6,1307.7,1040.3,1305.9,1040.3,1303.3z M1042,1302.5l3.7-0.2c0-1.8-0.3-2.5-1.5-2.5
           C1043,1299.8,1042.1,1300.6,1042,1302.5z"/>
         <path class="st3" d="M1048.7,1307.3l-0.1-2.4h0.5c0.3,0.6,0.4,1,0.8,1.4c0.3,0.4,0.6,0.6,1.4,0.6c1,0,1.7-0.5,1.7-1.3
           c0-0.9-0.6-1.2-1.9-1.7c-1.4-0.5-2.4-1.2-2.4-2.5c0-1.5,1.3-2.4,3-2.4c0.9,0,1.5,0.1,2.1,0.3l0.1,2.1h-0.5
           c-0.3-0.5-0.5-0.9-0.8-1.3c-0.2-0.3-0.5-0.4-1.1-0.4c-1.1,0-1.5,0.5-1.5,1.3c0,0.6,0.5,1,1.9,1.6c1.5,0.6,2.5,1.2,2.5,2.4
           c0,1.5-1.2,2.7-3.2,2.7C1050.2,1307.7,1049.4,1307.6,1048.7,1307.3z"/>
         <path class="st3" d="M1056.2,1306v-5.8h-1.1v-0.5c1.1-0.3,1.7-1.1,2.1-2.4h0.6v2h2.3l-0.2,0.8h-2.1v5.6c0,0.7,0.4,1,1.1,1
           c0.5,0,0.9-0.1,1.3-0.2v0.5c-0.3,0.3-1,0.6-2.1,0.6C1057,1307.7,1056.2,1307.2,1056.2,1306z"/>
         <path class="st3" d="M1060.8,1307l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-5c0-0.6-0.1-0.7-0.5-0.9l-0.6-0.3v-0.4l2.5-0.3l0.2,0.1
           l-0.1,0.9h0.1c0.8-0.5,1.9-1,2.9-1c1.2,0,1.6,0.4,1.9,1h0.1c0.9-0.5,1.9-1,3-1c1.4,0,2,0.7,2,2.1v4.8c0,0.6,0.1,0.8,0.4,0.8
           l0.7,0.2v0.5h-3.8v-0.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-4.3c0-1.1-0.3-1.5-1.2-1.5c-0.8,0-1.6,0.3-2.1,0.5v5.3
           c0,0.6,0.1,0.8,0.4,0.8l0.7,0.2v0.5h-3.8v-0.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-4.3c0-1.1-0.3-1.5-1.2-1.5
           c-0.8,0-1.6,0.3-2.1,0.5v5.3c0,0.7,0.1,0.8,0.4,0.8l0.7,0.2v0.5h-3.8V1307z"/>
         <path class="st3" d="M1075.3,1303.3c0-2.6,1.8-4.2,4-4.2c2,0,3.3,1.1,3.3,4.1h-5.5c0,2.4,1.2,3.5,2.8,3.5c1.2,0,2-0.4,2.6-0.7v0.6
           c-0.4,0.4-1.6,1.1-3.1,1.1C1076.6,1307.7,1075.3,1305.9,1075.3,1303.3z M1077,1302.5l3.7-0.2c0-1.8-0.3-2.5-1.5-2.5
           C1078,1299.8,1077.1,1300.6,1077,1302.5z"/>
         <path class="st3" d="M1083.5,1307l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-5c0-0.6-0.1-0.7-0.5-0.9l-0.6-0.3v-0.4l2.5-0.3l0.2,0.1
           l-0.1,0.9h0.1c0.8-0.5,2-1,3-1c1.6,0,2.2,0.7,2.2,2.1v4.8c0,0.6,0.1,0.8,0.4,0.8l0.6,0.2v0.5h-3.7v-0.5l0.7-0.2
           c0.3-0.1,0.4-0.2,0.4-0.8v-4.3c0-1.1-0.4-1.5-1.4-1.5c-0.8,0-1.7,0.3-2.2,0.5v5.3c0,0.7,0.1,0.8,0.4,0.8l0.7,0.2v0.5h-3.8V1307z"
           />
         <path class="st3" d="M1094,1306v-5.8h-1.1v-0.5c1.1-0.3,1.7-1.1,2.1-2.4h0.6v2h2.3l-0.2,0.8h-2.1v5.6c0,0.7,0.4,1,1.1,1
           c0.5,0,0.9-0.1,1.3-0.2v0.5c-0.3,0.3-1,0.6-2.1,0.6C1094.9,1307.7,1094,1307.2,1094,1306z"/>
       </g>
       <g class="st2">
         <path class="st4" d="M983.3,1189.3h-29.7c-3.3,0-3.7,1.1-5.9,6.3H946l1.6-13.4h41.7v3.4c-12.6,19.1-20.8,37.6-25.5,65.9h-7.9
           C962.1,1223.6,973.8,1204.4,983.3,1189.3z"/>
         <path class="st4" d="M993.8,1249.8l5.4-0.8c3.2-0.4,3.8-1.6,3.8-5.3v-52.3h-8.8v-1.8c8.1-1.4,11.4-3.3,15.4-8.1l1.2,0.2v62
           c0,3.7,0.6,4.9,3.4,5.3l5.5,0.8v1.7h-25.9V1249.8z"/>
         <path class="st4" d="M1023.9,1199.6c0-12.3,8-18.4,15.2-18.4c7,0,15,5.3,15,17.3c0,12.3-8.5,18-15.2,18
           C1032.1,1216.5,1023.9,1211.4,1023.9,1199.6z M1077.1,1180.1h4l-49.5,76.4h-4.1L1077.1,1180.1z M1046.8,1199.6
           c0-10.6-2.5-16.1-7.9-16.1c-5.3,0-7.7,5-7.7,14.9c0,10.5,2.6,15.9,7.7,15.9C1044.2,1214.3,1046.8,1209.7,1046.8,1199.6z
            M1056,1236c0-12.3,8-18.4,15.2-18.4c7,0,15,5.3,15,17.3c0,12.3-8.5,18-15.2,18C1064.2,1252.9,1056,1247.8,1056,1236z
            M1078.9,1236c0-10.6-2.5-16.1-7.9-16.1c-5.3,0-7.7,5-7.7,14.9c0,10.5,2.6,15.9,7.7,15.9C1076.3,1250.7,1078.9,1246.1,1078.9,1236
           z"/>
       </g>
     </g>
     </svg>
     `),
    ];
    stats2 = [
      this.sanitizer.bypassSecurityTrustHtml(`<svg class="stat-card-svg" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 300 200" style="enable-background:new 0 0 300 200;" xml:space="preserve">
      <style type="text/css">
        .st0{fill:none;}
        .st1{fill:none;stroke:#6E27C5;stroke-width:4;stroke-miterlimit:10;}
        .st2{enable-background:new    ;}
        .st3{fill:#333333;}
        .st4{fill:#6E27C5;}
      </style>
      <rect class="st0" width="300" height="200"/>
      <rect x="2" y="2" class="st1" width="296" height="196"/>
      <g class="st2">
        <path class="st3" d="M30.5,40.5l0.7-0.1c0.4-0.1,0.5-0.2,0.5-1v-7.9c0-0.8-0.1-0.9-0.5-1l-0.7-0.1v-0.5h4.4c2.4,0,3.9,0.7,3.9,2.6
          c0,1.5-1,2.3-2.7,2.6v0.1c2.2,0.2,3.3,1.1,3.3,2.7c0,2-1.6,3.1-4.1,3.1h-4.8V40.5z M34.9,34.9c1.4,0,2.2-0.8,2.2-2
          c0-1.8-0.7-2.2-2.3-2.2c-0.5,0-1,0-1.3,0.1v4.2H34.9z M37.6,38.1c0-1.7-0.7-2.5-2.5-2.5h-1.6V39c0,1.1,0.2,1.2,1.7,1.2
          C36.6,40.3,37.6,39.7,37.6,38.1z"/>
        <path class="st3" d="M40.1,43.6c0-0.5,0.3-0.9,0.8-0.9c0.5,0,0.8,0.3,0.9,0.7c0.1,0.2,0.1,0.3,0.3,0.3c0.4,0,0.9-0.5,1.4-1.7
          l0.4-0.9l-2.7-6.5c-0.4-0.8-0.4-0.9-0.8-1.1l-0.4-0.1v-0.5h3.6v0.5l-0.4,0.1c-0.4,0.1-0.5,0.2-0.5,0.5c0,0.2,0.1,0.5,0.2,0.9
          l1.7,4.3h0.1l1.6-4.3c0.1-0.4,0.2-0.7,0.2-0.9c0-0.3-0.2-0.3-0.4-0.4l-0.4-0.1v-0.5h2.9v0.5l-0.4,0.1c-0.4,0.1-0.5,0.3-0.8,1.2
          l-2.9,7.3c-0.6,1.6-1.4,2.7-2.8,2.7C40.5,44.6,40.1,44.1,40.1,43.6z"/>
        <path class="st3" d="M52.7,40.2c1.3-1.1,2.7-2.3,3.4-3.2c1.3-1.4,2.1-2.4,2.1-4.2c0-1.7-1-2.4-2-2.4c-0.9,0-1.2,0.2-1.2,0.8
          c-0.1,0.7-0.4,1.1-1,1.1c-0.6,0-0.9-0.4-0.9-0.9c0-0.8,1.1-1.6,3.3-1.6c2,0,3.6,1,3.6,3.2c0,2.4-2.7,4.5-5.6,6.6v0.1h3.7
          c0.9,0,1-0.1,1.6-1.2h0.4L59.9,41h-7.2V40.2z"/>
        <path class="st3" d="M61.3,35.6c0-4.2,2.5-5.9,4.5-5.9s4.4,1.6,4.4,5.6c0,4.2-2.5,5.9-4.5,5.9C63.8,41.2,61.3,39.7,61.3,35.6z
           M68.5,35.6c0-3.5-0.9-5.2-2.7-5.2c-1.7,0-2.7,1.5-2.7,4.9c0,3.5,0.9,5.1,2.7,5.1C67.6,40.4,68.5,39,68.5,35.6z"/>
        <path class="st3" d="M70.9,40.2c1.3-1.1,2.7-2.3,3.4-3.2c1.3-1.4,2.1-2.4,2.1-4.2c0-1.7-1-2.4-2-2.4c-0.9,0-1.2,0.2-1.2,0.8
          c-0.1,0.7-0.4,1.1-1,1.1c-0.6,0-0.9-0.4-0.9-0.9c0-0.8,1.1-1.6,3.3-1.6c2,0,3.6,1,3.6,3.2c0,2.4-2.7,4.5-5.6,6.6v0.1h3.7
          c0.9,0,1-0.1,1.6-1.2h0.4L78.1,41h-7.2V40.2z"/>
        <path class="st3" d="M79.5,40.5l0.9-0.1c0.4-0.1,0.5-0.2,0.5-1v-7.5h-1.5v-0.5c1.5-0.3,2.1-0.8,2.8-1.6l0.4,0v9.6
          c0,0.8,0.1,0.9,0.5,1l0.8,0.1V41h-4.6V40.5z"/>
        <path class="st3" d="M85.3,43.1c1.6-0.7,2.1-1.5,2.1-2.4c-0.1,0.1-0.3,0.2-0.5,0.2c-0.7,0-1-0.5-1-1.1c0-0.6,0.5-1.1,1.2-1.1
          c0.8,0,1.5,0.6,1.5,1.7c0,1.2-0.8,2.5-3,3L85.3,43.1z"/>
        <path class="st3" d="M94,40.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-5c0-0.6-0.1-0.7-0.5-0.9L94,33.4V33l2.5-0.3l0.2,0.1l-0.1,0.9h0.1
          c0.8-0.5,1.9-1,2.9-1c1.2,0,1.6,0.4,1.9,1h0.1c0.9-0.5,1.9-1,3-1c1.4,0,2,0.7,2,2.1v4.8c0,0.6,0.1,0.8,0.4,0.8l0.7,0.2V41h-3.8
          v-0.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-4.3c0-1.1-0.3-1.5-1.2-1.5c-0.8,0-1.6,0.3-2.1,0.5v5.3c0,0.6,0.1,0.8,0.4,0.8l0.7,0.2V41
          h-3.8v-0.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-4.3c0-1.1-0.3-1.5-1.2-1.5c-0.8,0-1.6,0.3-2.1,0.5v5.3c0,0.7,0.1,0.8,0.4,0.8l0.7,0.2
          V41H94V40.5z"/>
        <path class="st3" d="M108.5,36.9c0-2.8,2-4.3,4-4.3c2.3,0,4,1.4,4,4.2c0,2.8-2,4.4-4,4.4C110.2,41.2,108.5,39.8,108.5,36.9z
           M114.8,37c0-2.7-0.8-3.8-2.3-3.8c-1.4,0-2.2,1-2.2,3.4c0,2.7,0.8,3.8,2.4,3.8C114,40.5,114.8,39.4,114.8,37z"/>
        <path class="st3" d="M118.2,40.6v-10c0-0.6-0.1-0.7-0.5-0.8l-0.7-0.3V29l2.7-0.3l0.2,0.1c0,0-0.1,0.6-0.1,1.9v1.5
          c0,0.7-0.1,1.3-0.1,1.3h0.1c0.7-0.5,1.7-0.9,2.8-0.9c1.6,0,3.2,0.9,3.2,3.9s-1.9,4.7-4.6,4.7C120,41.2,119.1,41,118.2,40.6z
           M124.1,36.9c0-2.2-0.9-3.2-2.3-3.2c-1.1,0-1.6,0.3-2,0.5v5.8c0.4,0.4,0.9,0.5,1.6,0.5C123.3,40.5,124.1,39.5,124.1,36.9z"/>
        <path class="st3" d="M126.8,40.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-5c0-0.6-0.1-0.7-0.5-0.9l-0.6-0.3V33l2.6-0.3l0.2,0.1
          c0,0-0.1,0.7-0.1,1.8v5c0,0.7,0.1,0.8,0.4,0.8l0.7,0.2V41h-3.8V40.5z M127.6,30.1c0-0.6,0.5-1,1.1-1c0.6,0,1.1,0.5,1.1,1
          c0,0.5-0.5,1-1.1,1C128,31.2,127.6,30.7,127.6,30.1z"/>
        <path class="st3" d="M131.3,40.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-8.9c0-0.6-0.1-0.7-0.5-0.8l-0.7-0.3V29l2.7-0.3l0.2,0.1
          c0,0-0.1,0.8-0.1,1.9v8.8c0,0.7,0.1,0.8,0.4,0.8l0.7,0.2V41h-3.8V40.5z"/>
        <path class="st3" d="M135.9,36.8c0-2.6,1.8-4.2,4-4.2c2,0,3.3,1.1,3.3,4.1h-5.5c0,2.4,1.2,3.5,2.8,3.5c1.2,0,2-0.4,2.6-0.7v0.6
          c-0.4,0.4-1.6,1.1-3.1,1.1C137.2,41.2,135.9,39.4,135.9,36.8z M137.6,36l3.7-0.2c0-1.8-0.3-2.5-1.5-2.5
          C138.6,33.3,137.7,34.1,137.6,36z"/>
        <path class="st3" d="M148.9,34.5c-0.3-0.7-0.4-0.9-0.9-1.1l-0.4-0.1v-0.5h3.7v0.5l-0.4,0.1c-0.4,0.1-0.4,0.3-0.4,0.5
          c0,0.2,0.1,0.5,0.2,0.8l1.6,4.4h0.1l1.6-4.4c0.1-0.4,0.2-0.7,0.2-0.9c0-0.2-0.1-0.4-0.4-0.5l-0.4-0.1v-0.5h2.9v0.5l-0.4,0.1
          c-0.4,0.1-0.5,0.4-0.8,1.2l-2.4,6.3h-0.9L148.9,34.5z"/>
        <path class="st3" d="M156.4,40.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-5c0-0.6-0.1-0.7-0.5-0.9l-0.6-0.3V33l2.6-0.3l0.2,0.1
          c0,0-0.1,0.7-0.1,1.8v5c0,0.7,0.1,0.8,0.4,0.8l0.7,0.2V41h-3.8V40.5z M157.2,30.1c0-0.6,0.5-1,1.1-1c0.6,0,1.1,0.5,1.1,1
          c0,0.5-0.5,1-1.1,1C157.6,31.2,157.2,30.7,157.2,30.1z"/>
        <path class="st3" d="M161.1,37.2c0-3.1,2.2-4.6,4.5-4.6c0.7,0,1.2,0.1,1.5,0.2c0,0,0-0.6,0-1.2v-1c0-0.6-0.1-0.7-0.5-0.8l-0.7-0.3
          V29l2.7-0.3l0.2,0.1c0,0-0.1,0.6-0.1,1.9v8.4c0,0.7,0.1,0.8,0.5,1l0.6,0.3v0.4l-2.5,0.3l-0.1-0.1l0.1-0.8h-0.1
          c-0.7,0.5-1.7,0.9-2.8,0.9C162.8,41.2,161.1,40.2,161.1,37.2z M167.1,39.6V34c-0.4-0.3-0.8-0.7-1.6-0.7c-1.7,0-2.7,0.9-2.7,3.5
          c0,2.3,0.9,3.2,2.4,3.2C166.2,40.1,166.7,39.8,167.1,39.6z"/>
        <path class="st3" d="M170.6,36.8c0-2.6,1.8-4.2,4-4.2c2,0,3.3,1.1,3.3,4.1h-5.5c0,2.4,1.2,3.5,2.8,3.5c1.2,0,2-0.4,2.6-0.7v0.6
          c-0.4,0.4-1.6,1.1-3.1,1.1C171.9,41.2,170.6,39.4,170.6,36.8z M172.3,36l3.7-0.2c0-1.8-0.3-2.5-1.5-2.5
          C173.3,33.3,172.4,34.1,172.3,36z"/>
        <path class="st3" d="M178.9,36.9c0-2.8,2-4.3,4-4.3c2.3,0,4,1.4,4,4.2c0,2.8-2,4.4-4,4.4C180.7,41.2,178.9,39.8,178.9,36.9z
           M185.2,37c0-2.7-0.8-3.8-2.3-3.8c-1.4,0-2.2,1-2.2,3.4c0,2.7,0.8,3.8,2.4,3.8C184.4,40.5,185.2,39.4,185.2,37z"/>
      </g>
      <g class="st2">
        <path class="st3" d="M31.2,54.5c-0.3-0.7-0.4-0.9-0.9-1.1L30,53.3v-0.5h3.6v0.5l-0.4,0.1c-0.4,0.1-0.4,0.3-0.4,0.5
          c0,0.2,0.1,0.5,0.2,0.8l1.4,4.4h0.1l1.5-4.3l-0.1-0.4c-0.2-0.6-0.4-1-0.8-1.1l-0.4-0.1v-0.5h3.4v0.5l-0.4,0.1
          c-0.3,0.1-0.4,0.3-0.4,0.5c0,0.2,0.1,0.5,0.2,0.8l1.5,4.4h0.1l1.4-4.4c0.1-0.4,0.2-0.7,0.2-0.9c0-0.2-0.1-0.4-0.4-0.5l-0.4-0.1
          v-0.5h2.8v0.5l-0.4,0.1c-0.4,0.1-0.5,0.4-0.8,1.2L39.1,61h-1l-1.8-5.1h0L34.5,61h-1L31.2,54.5z"/>
        <path class="st3" d="M43,60.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-5c0-0.6-0.1-0.7-0.5-0.9L43,53.4V53l2.6-0.3l0.2,0.1
          c0,0-0.1,0.7-0.1,1.8v5c0,0.7,0.1,0.8,0.4,0.8l0.7,0.2V61H43V60.5z M43.8,50.1c0-0.6,0.5-1,1.1-1c0.6,0,1.1,0.5,1.1,1
          c0,0.5-0.5,1-1.1,1C44.3,51.2,43.8,50.7,43.8,50.1z"/>
        <path class="st3" d="M47.5,60.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-8.9c0-0.6-0.1-0.7-0.5-0.8l-0.7-0.3V49l2.7-0.3l0.2,0.1
          c0,0-0.1,0.8-0.1,1.9v8.8c0,0.7,0.1,0.8,0.4,0.8l0.7,0.2V61h-3.8V60.5z"/>
        <path class="st3" d="M52,60.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-8.9c0-0.6-0.1-0.7-0.5-0.8l-0.7-0.3V49l2.7-0.3l0.2,0.1
          c0,0-0.1,0.8-0.1,1.9v8.8c0,0.7,0.1,0.8,0.4,0.8l0.7,0.2V61H52V60.5z"/>
        <path class="st3" d="M60.7,57.2c0-3.1,2.2-4.6,4.5-4.6c0.7,0,1.2,0.1,1.5,0.2c0,0,0-0.6,0-1.2v-1c0-0.6-0.1-0.7-0.5-0.8l-0.7-0.3
          V49l2.7-0.3l0.2,0.1c0,0-0.1,0.6-0.1,1.9v8.4c0,0.7,0.1,0.8,0.5,1l0.6,0.3v0.4l-2.5,0.3l-0.1-0.1l0.1-0.8h-0.1
          c-0.7,0.5-1.7,0.9-2.8,0.9C62.4,61.2,60.7,60.2,60.7,57.2z M66.7,59.6V54c-0.4-0.3-0.8-0.7-1.6-0.7c-1.7,0-2.7,0.9-2.7,3.5
          c0,2.3,0.9,3.2,2.4,3.2C65.8,60.1,66.3,59.8,66.7,59.6z"/>
        <path class="st3" d="M70,60.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-5c0-0.6-0.1-0.7-0.5-0.9L70,53.4V53l2.5-0.3l0.2,0.1l-0.1,0.9h0.1
          c0.6-0.6,1.6-1.1,2.4-1.1c0.7,0,1.1,0.4,1.1,1c0,0.6-0.4,0.9-0.9,0.9c-0.6,0-0.8-0.2-0.9-0.5c-0.1-0.2-0.2-0.3-0.4-0.3
          c-0.3,0-1,0.4-1.2,0.7v5.1c0,0.7,0.1,0.8,0.4,0.8l0.8,0.2V61H70V60.5z"/>
        <path class="st3" d="M76.8,60.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-5c0-0.6-0.1-0.7-0.5-0.9l-0.6-0.3V53l2.6-0.3l0.2,0.1
          c0,0-0.1,0.7-0.1,1.8v5c0,0.7,0.1,0.8,0.4,0.8l0.7,0.2V61h-3.8V60.5z M77.6,50.1c0-0.6,0.5-1,1.1-1c0.6,0,1.1,0.5,1.1,1
          c0,0.5-0.5,1-1.1,1C78.1,51.2,77.6,50.7,77.6,50.1z"/>
        <path class="st3" d="M81.9,54.5c-0.3-0.7-0.4-0.9-0.9-1.1l-0.4-0.1v-0.5h3.7v0.5l-0.4,0.1c-0.4,0.1-0.4,0.3-0.4,0.5
          c0,0.2,0.1,0.5,0.2,0.8l1.6,4.4h0.1l1.6-4.4c0.1-0.4,0.2-0.7,0.2-0.9c0-0.2-0.1-0.4-0.4-0.5l-0.4-0.1v-0.5h2.9v0.5l-0.4,0.1
          c-0.4,0.1-0.5,0.4-0.8,1.2L85.4,61h-0.9L81.9,54.5z"/>
        <path class="st3" d="M89.3,56.8c0-2.6,1.8-4.2,4-4.2c2,0,3.3,1.1,3.3,4.1H91c0,2.4,1.2,3.5,2.8,3.5c1.2,0,2-0.4,2.6-0.7v0.6
          c-0.4,0.4-1.6,1.1-3.1,1.1C90.6,61.2,89.3,59.4,89.3,56.8z M91,56l3.7-0.2c0-1.8-0.3-2.5-1.5-2.5C92,53.3,91.2,54.1,91,56z"/>
      </g>
      <g class="st2">
        <path class="st3" d="M30.5,139.9c0-2.8,2-4.3,4-4.3c2.3,0,4,1.4,4,4.2c0,2.8-2,4.4-4,4.4C32.3,144.2,30.5,142.8,30.5,139.9z
           M36.9,140c0-2.7-0.8-3.8-2.3-3.8c-1.4,0-2.2,1-2.2,3.4c0,2.7,0.8,3.8,2.4,3.8C36.1,143.5,36.9,142.4,36.9,140z"/>
        <path class="st3" d="M39.6,143.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-5.8h-1.1v-0.5l1.1-0.4V135c0-2,1.5-3.2,3.6-3.2
          c1,0,1.5,0.4,1.5,1c0,0.4-0.3,0.8-0.8,0.8c-0.6,0-0.9-0.3-1-0.8c0-0.3-0.1-0.4-0.3-0.4c-0.6,0-1.4,0.4-1.4,1.8v1.7h1.9l-0.2,0.8
          h-1.6v5.7c0,0.8,0.1,0.8,0.4,0.9l0.9,0.2v0.5h-4V143.5z"/>
        <path class="st3" d="M48.7,142.1c0-1.3,0.9-2,2.8-2.4l1.9-0.4v-1.5c0-1.1-0.2-1.6-1.5-1.6c-0.7,0-0.8,0.2-0.9,0.6
          c-0.1,0.6-0.2,1.1-1,1.1c-0.6,0-0.8-0.4-0.8-0.8c0-1.1,1.7-1.5,3-1.5c1.9,0,2.8,0.7,2.8,2.1v4.7c0,0.4,0.1,0.7,0.4,0.8
          c0.2,0.1,0.4,0.1,0.8,0.1v0.4c-0.3,0.2-0.8,0.4-1.4,0.4c-0.8,0-1.2-0.4-1.3-0.9h-0.1c-0.4,0.5-1.3,0.9-2.2,0.9
          C49.7,144.2,48.7,143.5,48.7,142.1z M53.4,142.5v-2.5l-1.2,0.1c-1.2,0.1-1.8,0.5-1.8,1.7c0,0.9,0.6,1.3,1.5,1.3
          C52.6,143.3,53.2,142.9,53.4,142.5z"/>
        <path class="st3" d="M56.8,143.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-8.9c0-0.6-0.1-0.7-0.5-0.8l-0.7-0.3V132l2.7-0.3l0.2,0.1
          c0,0-0.1,0.8-0.1,1.9v8.8c0,0.7,0.1,0.8,0.4,0.8l0.7,0.2v0.5h-3.8V143.5z"/>
        <path class="st3" d="M61.3,143.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-8.9c0-0.6-0.1-0.7-0.5-0.8l-0.7-0.3V132l2.7-0.3l0.2,0.1
          c0,0-0.1,0.8-0.1,1.9v8.8c0,0.7,0.1,0.8,0.4,0.8l0.7,0.2v0.5h-3.8V143.5z"/>
        <path class="st3" d="M69.8,143.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-5c0-0.6-0.1-0.7-0.5-0.9l-0.6-0.3V136l2.6-0.3l0.2,0.1
          c0,0-0.1,0.7-0.1,1.8v5c0,0.7,0.1,0.8,0.4,0.8l0.7,0.2v0.5h-3.8V143.5z M70.6,133.1c0-0.6,0.5-1,1.1-1c0.6,0,1.1,0.5,1.1,1
          c0,0.5-0.5,1-1.1,1C71,134.2,70.6,133.7,70.6,133.1z"/>
        <path class="st3" d="M74.3,143.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-5c0-0.6-0.1-0.7-0.5-0.9l-0.6-0.3V136l2.5-0.3l0.2,0.1l-0.1,0.9
          H77c0.8-0.5,2-1,3-1c1.6,0,2.2,0.7,2.2,2.1v4.8c0,0.6,0.1,0.8,0.4,0.8l0.6,0.2v0.5h-3.7v-0.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-4.3
          c0-1.1-0.4-1.5-1.4-1.5c-0.8,0-1.7,0.3-2.2,0.5v5.3c0,0.7,0.1,0.8,0.4,0.8l0.7,0.2v0.5h-3.8V143.5z"/>
        <path class="st3" d="M84.8,142.5v-5.8h-1.1v-0.5c1.1-0.3,1.7-1.1,2.1-2.4h0.6v2h2.3l-0.2,0.8h-2.1v5.6c0,0.7,0.4,1,1.1,1
          c0.5,0,0.9-0.1,1.3-0.2v0.5c-0.3,0.3-1,0.6-2.1,0.6C85.7,144.2,84.8,143.7,84.8,142.5z"/>
        <path class="st3" d="M89.5,139.8c0-2.6,1.8-4.2,4-4.2c2,0,3.3,1.1,3.3,4.1h-5.5c0,2.4,1.2,3.5,2.8,3.5c1.2,0,2-0.4,2.6-0.7v0.6
          c-0.4,0.4-1.6,1.1-3.1,1.1C90.8,144.2,89.5,142.4,89.5,139.8z M91.2,139l3.7-0.2c0-1.8-0.3-2.5-1.5-2.5
          C92.2,136.3,91.3,137.1,91.2,139z"/>
        <path class="st3" d="M97.6,143.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-5c0-0.6-0.1-0.7-0.5-0.9l-0.6-0.3V136l2.5-0.3l0.2,0.1l-0.1,0.9
          h0.1c0.6-0.6,1.6-1.1,2.4-1.1c0.7,0,1.1,0.4,1.1,1c0,0.6-0.4,0.9-0.9,0.9c-0.6,0-0.8-0.2-0.9-0.5c-0.1-0.2-0.2-0.3-0.4-0.3
          c-0.3,0-1,0.4-1.2,0.7v5.1c0,0.7,0.1,0.8,0.4,0.8l0.8,0.2v0.5h-3.9V143.5z"/>
        <path class="st3" d="M104.4,143.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-5c0-0.6-0.1-0.7-0.5-0.9l-0.6-0.3V136l2.5-0.3l0.2,0.1
          l-0.1,0.9h0.1c0.8-0.5,2-1,3-1c1.6,0,2.2,0.7,2.2,2.1v4.8c0,0.6,0.1,0.8,0.4,0.8l0.6,0.2v0.5h-3.7v-0.5l0.7-0.2
          c0.3-0.1,0.4-0.2,0.4-0.8v-4.3c0-1.1-0.4-1.5-1.4-1.5c-0.8,0-1.7,0.3-2.2,0.5v5.3c0,0.7,0.1,0.8,0.4,0.8l0.7,0.2v0.5h-3.8V143.5z"
          />
        <path class="st3" d="M114.2,139.8c0-2.6,1.8-4.2,4-4.2c2,0,3.3,1.1,3.3,4.1h-5.5c0,2.4,1.2,3.5,2.8,3.5c1.2,0,2-0.4,2.6-0.7v0.6
          c-0.4,0.4-1.6,1.1-3.1,1.1C115.5,144.2,114.2,142.4,114.2,139.8z M115.9,139l3.7-0.2c0-1.8-0.3-2.5-1.5-2.5
          C116.9,136.3,116,137.1,115.9,139z"/>
        <path class="st3" d="M123.2,142.5v-5.8h-1.1v-0.5c1.1-0.3,1.7-1.1,2.1-2.4h0.6v2h2.3l-0.2,0.8h-2.1v5.6c0,0.7,0.4,1,1.1,1
          c0.5,0,0.9-0.1,1.3-0.2v0.5c-0.3,0.3-1,0.6-2.1,0.6C124.1,144.2,123.2,143.7,123.2,142.5z"/>
        <path class="st3" d="M132.6,137.5c-0.3-0.7-0.4-0.9-0.9-1.1l-0.4-0.1v-0.5h3.7v0.5l-0.4,0.1c-0.4,0.1-0.4,0.3-0.4,0.5
          c0,0.2,0.1,0.5,0.2,0.8l1.6,4.4h0.1l1.6-4.4c0.1-0.4,0.2-0.7,0.2-0.9c0-0.2-0.1-0.4-0.4-0.5l-0.4-0.1v-0.5h2.9v0.5l-0.4,0.1
          c-0.4,0.1-0.5,0.4-0.8,1.2l-2.4,6.3h-0.9L132.6,137.5z"/>
        <path class="st3" d="M140.1,143.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-5c0-0.6-0.1-0.7-0.5-0.9l-0.6-0.3V136l2.6-0.3l0.2,0.1
          c0,0-0.1,0.7-0.1,1.8v5c0,0.7,0.1,0.8,0.4,0.8l0.7,0.2v0.5h-3.8V143.5z M140.9,133.1c0-0.6,0.5-1,1.1-1c0.6,0,1.1,0.5,1.1,1
          c0,0.5-0.5,1-1.1,1C141.3,134.2,140.9,133.7,140.9,133.1z"/>
        <path class="st3" d="M144.8,140.2c0-3.1,2.2-4.6,4.5-4.6c0.7,0,1.2,0.1,1.5,0.2c0,0,0-0.6,0-1.2v-1c0-0.6-0.1-0.7-0.5-0.8l-0.7-0.3
          V132l2.7-0.3l0.2,0.1c0,0-0.1,0.6-0.1,1.9v8.4c0,0.7,0.1,0.8,0.5,1l0.6,0.3v0.4l-2.5,0.3l-0.1-0.1l0.1-0.8h-0.1
          c-0.7,0.5-1.7,0.9-2.8,0.9C146.5,144.2,144.8,143.2,144.8,140.2z M150.8,142.6V137c-0.4-0.3-0.8-0.7-1.6-0.7
          c-1.7,0-2.7,0.9-2.7,3.5c0,2.3,0.9,3.2,2.4,3.2C149.9,143.1,150.4,142.8,150.8,142.6z"/>
        <path class="st3" d="M154.3,139.8c0-2.6,1.8-4.2,4-4.2c2,0,3.3,1.1,3.3,4.1H156c0,2.4,1.2,3.5,2.8,3.5c1.2,0,2-0.4,2.6-0.7v0.6
          c-0.4,0.4-1.6,1.1-3.1,1.1C155.6,144.2,154.3,142.4,154.3,139.8z M156,139l3.7-0.2c0-1.8-0.3-2.5-1.5-2.5
          C157,136.3,156.1,137.1,156,139z"/>
        <path class="st3" d="M162.6,139.9c0-2.8,2-4.3,4-4.3c2.3,0,4,1.4,4,4.2c0,2.8-2,4.4-4,4.4C164.4,144.2,162.6,142.8,162.6,139.9z
           M168.9,140c0-2.7-0.8-3.8-2.3-3.8c-1.4,0-2.2,1-2.2,3.4c0,2.7,0.8,3.8,2.4,3.8C168.1,143.5,168.9,142.4,168.9,140z"/>
        <path class="st3" d="M176.5,142.5v-5.8h-1.1v-0.5c1.1-0.3,1.7-1.1,2.1-2.4h0.6v2h2.3l-0.2,0.8h-2.1v5.6c0,0.7,0.4,1,1.1,1
          c0.5,0,0.9-0.1,1.3-0.2v0.5c-0.3,0.3-1,0.6-2.1,0.6C177.4,144.2,176.5,143.7,176.5,142.5z"/>
        <path class="st3" d="M181.1,143.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-5c0-0.6-0.1-0.7-0.5-0.9l-0.6-0.3V136l2.5-0.3l0.2,0.1
          l-0.1,0.9h0.1c0.6-0.6,1.6-1.1,2.4-1.1c0.7,0,1.1,0.4,1.1,1c0,0.6-0.4,0.9-0.9,0.9c-0.6,0-0.8-0.2-0.9-0.5
          c-0.1-0.2-0.2-0.3-0.4-0.3c-0.3,0-1,0.4-1.2,0.7v5.1c0,0.7,0.1,0.8,0.4,0.8l0.8,0.2v0.5h-3.9V143.5z"/>
        <path class="st3" d="M187.8,142.1c0-1.3,0.9-2,2.8-2.4l1.9-0.4v-1.5c0-1.1-0.2-1.6-1.5-1.6c-0.7,0-0.8,0.2-0.9,0.6
          c-0.1,0.6-0.2,1.1-1,1.1c-0.6,0-0.8-0.4-0.8-0.8c0-1.1,1.7-1.5,3-1.5c1.9,0,2.8,0.7,2.8,2.1v4.7c0,0.4,0.1,0.7,0.4,0.8
          c0.2,0.1,0.4,0.1,0.8,0.1v0.4c-0.3,0.2-0.8,0.4-1.4,0.4c-0.8,0-1.2-0.4-1.3-0.9h-0.1c-0.4,0.5-1.3,0.9-2.2,0.9
          C188.8,144.2,187.8,143.5,187.8,142.1z M192.5,142.5v-2.5l-1.2,0.1c-1.2,0.1-1.8,0.5-1.8,1.7c0,0.9,0.6,1.3,1.5,1.3
          C191.7,143.3,192.3,142.9,192.5,142.5z"/>
        <path class="st3" d="M195.9,143.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-5.8h-1.1v-0.5l1.1-0.4v-0.4c0-2.2,1.6-3.4,3.7-3.4
          c1,0,1.6,0.3,2.1,0.7c0.7-0.6,1.6-0.9,2.8-0.9c1.3,0,1.8,0.4,1.8,1c0,0.5-0.4,0.8-0.9,0.8c-0.6,0-0.9-0.4-1-0.9
          c0-0.3-0.1-0.4-0.4-0.4c-0.9,0-1.8,0.4-1.8,2v1.5h2.9l1.7-0.2l0.2,0.1c0,0-0.1,0.7-0.1,1.5v5.3c0,0.7,0.1,0.8,0.4,0.8l0.7,0.2v0.5
          h-3.7v-0.5l0.6-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-5c0-0.6-0.2-0.8-0.6-0.8h-2.5v5.7c0,0.8,0.1,0.8,0.4,0.9l0.7,0.2v0.5h-3.8v-0.5
          l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-5.8h-3.2v5.7c0,0.8,0.1,0.8,0.4,0.9l0.7,0.2v0.5h-3.8V143.5z M202.1,133.8
          c-0.4-0.8-0.9-1.1-1.6-1.1c-1,0-1.8,0.5-1.8,2v1.2h3.2v-0.7C201.8,134.7,201.9,134.2,202.1,133.8z"/>
        <path class="st3" d="M210.1,140c0-3.4,2.6-4.4,4.6-4.4c1.5,0,2.3,0.5,2.3,1.3c0,0.5-0.4,1-0.9,1c-0.6,0-0.8-0.3-0.9-0.9
          c-0.1-0.7-0.3-0.8-0.8-0.8c-1.3,0-2.4,0.9-2.4,3.2c0,2.4,1,3.7,2.8,3.7c1.1,0,1.9-0.4,2.5-0.7v0.6c-0.5,0.4-1.6,1.1-3.1,1.1
          C211.4,144.2,210.1,142.4,210.1,140z"/>
        <path class="st3" d="M217.7,138.4l0.5-0.1c0.2,0,0.3-0.1,0.3-0.6v-4.4h-0.9v-0.3c0.9-0.2,1.2-0.4,1.7-0.9l0.2,0v5.6
          c0,0.5,0,0.5,0.3,0.6l0.5,0.1v0.3h-2.7V138.4z"/>
      </g>
      <g class="st2">
        <path class="st4" d="M30.8,114.9c4.5-3.8,7.4-6.7,10.3-9.7c4.4-4.6,7.5-8.5,7.5-15.2c0-6-3.6-8.1-7.3-8.1c-3.1,0-3.7,0.8-4,2.7
          c-0.2,1.9-0.8,3.1-2.7,3.1c-1.8,0-2.4-1-2.4-2.4c0-3.2,4.3-4.9,9.7-4.9c5.6,0,10.9,2.8,10.9,9.6c0,8.4-7.2,14.3-18.1,23.5v0.2h12.7
          c2.8,0,3.3-0.4,5.3-4.4h0.9l-0.9,7.9H30.8V114.9z"/>
        <path class="st4" d="M67,116.1l2.1-0.4c1.5-0.3,1.8-0.9,1.8-2.9v-6.7h-15v-1.7L73.1,81h1.7v22.9h6l-0.3,2.4h-5.7v6.7
          c0,2,0.4,2.5,1.7,2.8l2.3,0.4v0.9H67V116.1z M70.9,103.8V86.8h-0.2l-12.2,17v0.1H70.9z"/>
        <path class="st4" d="M82.2,90c0-6.4,4.2-9.6,7.9-9.6c3.6,0,7.8,2.8,7.8,9c0,6.4-4.4,9.4-7.9,9.4C86.5,98.8,82.2,96.1,82.2,90z
           M109.9,79.9h2.1l-25.7,39.7h-2.1L109.9,79.9z M94.1,90c0-5.5-1.3-8.4-4.1-8.4c-2.8,0-4,2.6-4,7.7c0,5.5,1.4,8.3,4,8.3
          C92.8,97.7,94.1,95.3,94.1,90z M98.9,108.9c0-6.4,4.2-9.6,7.9-9.6c3.6,0,7.8,2.8,7.8,9c0,6.4-4.4,9.4-7.9,9.4
          C103.2,117.7,98.9,115.1,98.9,108.9z M110.8,108.9c0-5.5-1.3-8.4-4.1-8.4c-2.8,0-4,2.6-4,7.7c0,5.5,1.4,8.3,4,8.3
          C109.5,116.6,110.8,114.2,110.8,108.9z"/>
      </g>
      </svg>
      `),
      this.sanitizer.bypassSecurityTrustHtml(`<svg class="stat-card-svg" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 300 200" style="enable-background:new 0 0 300 200;" xml:space="preserve">
   <style type="text/css">
     .st0{fill:none;}
     .st1{fill:none;stroke:#6E27C5;stroke-width:4;stroke-miterlimit:10;}
     .st2{enable-background:new    ;}
     .st3{fill:#333333;}
     .st4{fill:#6E27C5;}
   </style>
   <g id="Rectangle_4_Copy_8" transform="translate(590 1462.5)">
     <rect x="-590" y="-1462.5" class="st0" width="300" height="200"/>
     <rect x="-588" y="-1460.5" class="st1" width="296" height="196"/>
   </g>
   <g class="st2">
     <path class="st3" d="M30.5,93.9c0-2.8,2-4.3,4-4.3c2.3,0,4,1.4,4,4.2c0,2.8-2,4.4-4,4.4C32.3,98.2,30.5,96.8,30.5,93.9z M36.9,94
       c0-2.7-0.8-3.8-2.3-3.8c-1.4,0-2.2,1-2.2,3.4c0,2.7,0.8,3.8,2.4,3.8C36.1,97.5,36.9,96.4,36.9,94z"/>
     <path class="st3" d="M39.6,97.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-5.8h-1.1v-0.5l1.1-0.4V89c0-2,1.5-3.2,3.6-3.2c1,0,1.5,0.4,1.5,1
       c0,0.4-0.3,0.8-0.8,0.8c-0.6,0-0.9-0.3-1-0.8c0-0.3-0.1-0.4-0.3-0.4c-0.6,0-1.4,0.4-1.4,1.8v1.7h1.9l-0.2,0.8h-1.6v5.7
       c0,0.8,0.1,0.8,0.4,0.9l0.9,0.2V98h-4V97.5z"/>
     <path class="st3" d="M49.8,94.4v-5.9c0-0.8-0.1-0.9-0.5-1l-0.7-0.1v-0.5h4.2v0.5L52,87.5c-0.4,0.1-0.5,0.2-0.5,1v6.1
       c0,1.9,0.8,2.7,2.9,2.7c1.9,0,2.8-1,2.8-2.8v-5.8c0-1-0.1-1.1-0.7-1.2l-0.8-0.1v-0.5h3.6v0.5l-0.6,0.1c-0.5,0.1-0.7,0.2-0.7,1.2
       v5.9c0,2-1.2,3.7-4,3.7C51.4,98.2,49.8,97.1,49.8,94.4z"/>
     <path class="st3" d="M60,97c0-0.6,0.5-1.1,1.2-1.1c0.7,0,1.2,0.5,1.2,1.1s-0.5,1.1-1.2,1.1C60.5,98.1,60,97.6,60,97z"/>
     <path class="st3" d="M64.4,97.7l-0.2-3h0.6c0.3,0.8,0.5,1.3,1,1.9c0.4,0.6,0.8,0.8,1.9,0.8c1.5,0,2.3-0.9,2.3-1.9
       c0-1-0.7-1.6-2.7-2.5c-2-0.9-2.9-1.7-2.9-3.3c0-1.7,1.4-3,3.6-3c1.2,0,2.1,0.1,2.6,0.3l0.2,2.7h-0.6c-0.4-0.9-0.5-1.1-0.8-1.6
       c-0.3-0.4-0.6-0.6-1.6-0.6c-1.2,0-2.1,0.7-2.1,1.8c0,0.9,0.4,1.4,2.3,2.2c2.4,1.1,3.4,1.9,3.4,3.3c0,1.9-1.3,3.5-3.9,3.5
       C66.3,98.2,65.2,98,64.4,97.7z"/>
     <path class="st3" d="M73.1,97c0-0.6,0.5-1.1,1.2-1.1c0.7,0,1.2,0.5,1.2,1.1s-0.5,1.1-1.2,1.1C73.6,98.1,73.1,97.6,73.1,97z"/>
     <path class="st3" d="M81.2,94c0-3.4,2.6-4.4,4.6-4.4c1.5,0,2.3,0.5,2.3,1.3c0,0.5-0.4,1-0.9,1c-0.6,0-0.8-0.3-0.9-0.9
       c-0.1-0.7-0.3-0.8-0.8-0.8c-1.3,0-2.4,0.9-2.4,3.2c0,2.4,1,3.7,2.8,3.7c1.1,0,1.9-0.4,2.5-0.7v0.6c-0.5,0.4-1.6,1.1-3.1,1.1
       C82.5,98.2,81.2,96.4,81.2,94z"/>
     <path class="st3" d="M89,93.9c0-2.8,2-4.3,4-4.3c2.3,0,4,1.4,4,4.2c0,2.8-2,4.4-4,4.4C90.7,98.2,89,96.8,89,93.9z M95.3,94
       c0-2.7-0.8-3.8-2.3-3.8c-1.4,0-2.2,1-2.2,3.4c0,2.7,0.8,3.8,2.4,3.8C94.5,97.5,95.3,96.4,95.3,94z"/>
     <path class="st3" d="M98,97.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-5c0-0.6-0.1-0.7-0.5-0.9L98,90.4V90l2.5-0.3l0.2,0.1l-0.1,0.9h0.1
       c0.8-0.5,2-1,3-1c1.6,0,2.2,0.7,2.2,2.1v4.8c0,0.6,0.1,0.8,0.4,0.8l0.6,0.2V98h-3.7v-0.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-4.3
       c0-1.1-0.4-1.5-1.4-1.5c-0.8,0-1.7,0.3-2.2,0.5v5.3c0,0.7,0.1,0.8,0.4,0.8l0.7,0.2V98H98V97.5z"/>
     <path class="st3" d="M107.9,97.8l-0.1-2.4h0.5c0.3,0.6,0.4,1,0.8,1.4c0.3,0.4,0.6,0.6,1.4,0.6c1,0,1.7-0.5,1.7-1.3
       c0-0.9-0.6-1.2-1.9-1.7c-1.4-0.5-2.4-1.2-2.4-2.5c0-1.5,1.3-2.4,3-2.4c0.9,0,1.5,0.1,2.1,0.3L113,92h-0.5c-0.3-0.5-0.5-0.9-0.8-1.3
       c-0.2-0.3-0.5-0.4-1.1-0.4c-1.1,0-1.5,0.5-1.5,1.3c0,0.6,0.5,1,1.9,1.6c1.5,0.6,2.5,1.2,2.5,2.4c0,1.5-1.2,2.7-3.2,2.7
       C109.4,98.2,108.5,98.1,107.9,97.8z"/>
     <path class="st3" d="M115.4,96.1v-4.7c0-0.6-0.1-0.6-0.4-0.8l-0.7-0.3v-0.4l2.4-0.3l0.2,0.2v5.9c0,0.9,0.4,1.4,1.4,1.4
       c0.8,0,1.6-0.3,2.2-0.6v-5.2c0-0.6,0-0.6-0.4-0.8l-0.6-0.3v-0.4l2.4-0.3l0.2,0.2v6.4c0,0.7,0.1,0.8,0.5,1l0.6,0.3v0.4l-2.5,0.3
       l-0.2-0.1l0.1-1h-0.1c-0.8,0.6-1.9,1.1-3,1.1C116.2,98.2,115.4,97.5,115.4,96.1z"/>
     <path class="st3" d="M124,97.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-5c0-0.6-0.1-0.7-0.5-0.9l-0.6-0.3V90l2.5-0.3l0.2,0.1l-0.1,0.9
       h0.1c0.8-0.5,1.9-1,2.9-1c1.2,0,1.6,0.4,1.9,1h0.1c0.9-0.5,1.9-1,3-1c1.4,0,2,0.7,2,2.1v4.8c0,0.6,0.1,0.8,0.4,0.8l0.7,0.2V98h-3.8
       v-0.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-4.3c0-1.1-0.3-1.5-1.2-1.5c-0.8,0-1.6,0.3-2.1,0.5v5.3c0,0.6,0.1,0.8,0.4,0.8l0.7,0.2V98
       h-3.8v-0.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-4.3c0-1.1-0.3-1.5-1.2-1.5c-0.8,0-1.6,0.3-2.1,0.5v5.3c0,0.7,0.1,0.8,0.4,0.8l0.7,0.2
       V98H124V97.5z"/>
     <path class="st3" d="M138.4,93.8c0-2.6,1.8-4.2,4-4.2c2,0,3.3,1.1,3.3,4.1h-5.5c0,2.4,1.2,3.5,2.8,3.5c1.2,0,2-0.4,2.6-0.7v0.6
       c-0.4,0.4-1.6,1.1-3.1,1.1C139.7,98.2,138.4,96.4,138.4,93.8z M140.1,93l3.7-0.2c0-1.8-0.3-2.5-1.5-2.5
       C141.2,90.3,140.3,91.1,140.1,93z"/>
     <path class="st3" d="M146.6,97.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-5c0-0.6-0.1-0.7-0.5-0.9l-0.6-0.3V90l2.5-0.3l0.2,0.1l-0.1,0.9
       h0.1c0.6-0.6,1.6-1.1,2.4-1.1c0.7,0,1.1,0.4,1.1,1c0,0.6-0.4,0.9-0.9,0.9c-0.6,0-0.8-0.2-0.9-0.5c-0.1-0.2-0.2-0.3-0.4-0.3
       c-0.3,0-1,0.4-1.2,0.7v5.1c0,0.7,0.1,0.8,0.4,0.8l0.8,0.2V98h-3.9V97.5z"/>
     <path class="st3" d="M153.5,97.8l-0.1-2.4h0.5c0.3,0.6,0.4,1,0.8,1.4c0.3,0.4,0.6,0.6,1.4,0.6c1,0,1.7-0.5,1.7-1.3
       c0-0.9-0.6-1.2-1.9-1.7c-1.4-0.5-2.4-1.2-2.4-2.5c0-1.5,1.3-2.4,3-2.4c0.9,0,1.5,0.1,2.1,0.3l0.1,2.1h-0.5
       c-0.3-0.5-0.5-0.9-0.8-1.3c-0.2-0.3-0.5-0.4-1.1-0.4c-1.1,0-1.5,0.5-1.5,1.3c0,0.6,0.5,1,1.9,1.6c1.5,0.6,2.5,1.2,2.5,2.4
       c0,1.5-1.2,2.7-3.2,2.7C155.1,98.2,154.2,98.1,153.5,97.8z"/>
     <path class="st3" d="M164.5,93.1c0-3.1,2.1-5.6,3.9-7l0.4,0.4c-1.5,1.6-2.7,3.8-2.7,6.3c0,3.3,1.2,5.4,2.7,7l-0.4,0.4
       C166.6,98.8,164.5,96.6,164.5,93.1z"/>
     <path class="st3" d="M169.4,96.1c0-1.3,0.9-2,2.8-2.4l1.9-0.4v-1.5c0-1.1-0.2-1.6-1.5-1.6c-0.7,0-0.8,0.2-0.9,0.6
       c-0.1,0.6-0.2,1.1-1,1.1c-0.6,0-0.8-0.4-0.8-0.8c0-1.1,1.7-1.5,3-1.5c1.9,0,2.8,0.7,2.8,2.1v4.7c0,0.4,0.1,0.7,0.4,0.8
       c0.2,0.1,0.4,0.1,0.8,0.1v0.4c-0.3,0.2-0.8,0.4-1.4,0.4c-0.8,0-1.2-0.4-1.3-0.9h-0.1c-0.4,0.5-1.3,0.9-2.2,0.9
       C170.4,98.2,169.4,97.5,169.4,96.1z M174.1,96.5v-2.5l-1.2,0.1c-1.2,0.1-1.8,0.5-1.8,1.7c0,0.9,0.6,1.3,1.5,1.3
       C173.3,97.3,173.9,96.9,174.1,96.5z"/>
     <path class="st3" d="M177.6,97.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-5c0-0.6-0.1-0.7-0.5-0.9l-0.6-0.3V90l2.5-0.3l0.2,0.1l-0.1,0.9
       h0.1c0.8-0.5,2-1,3-1c1.6,0,2.2,0.7,2.2,2.1v4.8c0,0.6,0.1,0.8,0.4,0.8l0.6,0.2V98h-3.7v-0.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-4.3
       c0-1.1-0.4-1.5-1.4-1.5c-0.8,0-1.7,0.3-2.2,0.5v5.3c0,0.7,0.1,0.8,0.4,0.8l0.7,0.2V98h-3.8V97.5z"/>
     <path class="st3" d="M187.5,94.2c0-3.1,2.2-4.6,4.5-4.6c0.7,0,1.2,0.1,1.5,0.2c0,0,0-0.6,0-1.2v-1c0-0.6-0.1-0.7-0.5-0.8l-0.7-0.3
       V86l2.7-0.3l0.2,0.1c0,0-0.1,0.6-0.1,1.9v8.4c0,0.7,0.1,0.8,0.5,1l0.6,0.3v0.4l-2.5,0.3l-0.1-0.1l0.1-0.8h-0.1
       c-0.7,0.5-1.7,0.9-2.8,0.9C189.1,98.2,187.5,97.2,187.5,94.2z M193.4,96.6V91c-0.4-0.3-0.8-0.7-1.6-0.7c-1.7,0-2.7,0.9-2.7,3.5
       c0,2.3,0.9,3.2,2.4,3.2C192.5,97.1,193.1,96.8,193.4,96.6z"/>
     <path class="st3" d="M200.9,95.4c0-1.2,1.1-2.3,2.5-3v0c-1.2-0.7-2-1.5-2-2.7c0-1.6,1.5-2.9,3.4-2.9c1.7,0,3.3,0.9,3.3,2.5
       c0,1.1-1,2.1-2.1,2.8v0c1.6,0.9,2.5,1.6,2.5,3c0,1.7-1.6,3.2-3.8,3.2C203,98.2,200.9,97.3,200.9,95.4z M207.2,95.6
       c0-1.3-1.6-2-3.3-2.9c-0.6,0.5-1.5,1.3-1.5,2.6c0,1.5,1,2.2,2.5,2.2C206.3,97.5,207.2,96.7,207.2,95.6z M206.8,89.3
       c0-1.3-0.8-1.9-2-1.9c-1.2,0-2,0.7-2,1.7c0,1.1,1.5,1.9,2.8,2.6C206.1,91.2,206.8,90.4,206.8,89.3z"/>
     <path class="st3" d="M209.3,97.2c1.3-1.1,2.7-2.3,3.4-3.2c1.3-1.4,2.1-2.4,2.1-4.2c0-1.7-1-2.4-2-2.4c-0.9,0-1.2,0.2-1.2,0.8
       c-0.1,0.7-0.4,1.1-1,1.1c-0.6,0-0.9-0.4-0.9-0.9c0-0.8,1.1-1.6,3.3-1.6c2,0,3.6,1,3.6,3.2c0,2.4-2.7,4.5-5.6,6.6v0.1h3.7
       c0.9,0,1-0.1,1.6-1.2h0.4l-0.3,2.7h-7.2V97.2z"/>
     <path class="st3" d="M217.8,89.7c0-2,1.5-2.9,2.6-2.9c1.1,0,2.6,0.9,2.6,2.8c0,2-1.6,2.9-2.6,2.9C219.2,92.4,217.8,91.6,217.8,89.7
       z M227,86.6h0.9l-8.1,12.2h-0.9L227,86.6z M221.6,89.7c0-1.7-0.5-2.4-1.2-2.4c-0.8,0-1.2,0.7-1.2,2.2c0,1.6,0.4,2.4,1.2,2.4
       C221.2,91.9,221.6,91.2,221.6,89.7z M224.1,95.5c0-2,1.5-2.9,2.6-2.9c1.1,0,2.6,0.9,2.6,2.8c0,2-1.6,2.9-2.6,2.9
       C225.5,98.2,224.1,97.4,224.1,95.5z M227.9,95.5c0-1.7-0.4-2.4-1.2-2.4c-0.8,0-1.2,0.7-1.2,2.2c0,1.6,0.4,2.4,1.2,2.4
       C227.5,97.7,227.9,97,227.9,95.5z"/>
     <path class="st3" d="M234.4,93.9c0-2.8,2-4.3,4-4.3c2.3,0,4,1.4,4,4.2c0,2.8-2,4.4-4,4.4C236.2,98.2,234.4,96.8,234.4,93.9z
        M240.7,94c0-2.7-0.8-3.8-2.3-3.8c-1.4,0-2.2,1-2.2,3.4c0,2.7,0.8,3.8,2.4,3.8C240,97.5,240.7,96.4,240.7,94z"/>
     <path class="st3" d="M243.4,97.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-5.8h-1.1v-0.5l1.1-0.4V89c0-2,1.5-3.2,3.6-3.2
       c1,0,1.5,0.4,1.5,1c0,0.4-0.3,0.8-0.8,0.8c-0.6,0-0.9-0.3-1-0.8c0-0.3-0.1-0.4-0.3-0.4c-0.6,0-1.4,0.4-1.4,1.8v1.7h1.9l-0.2,0.8
       h-1.6v5.7c0,0.8,0.1,0.8,0.4,0.9l0.9,0.2V98h-4V97.5z"/>
   </g>
   <g class="st2">
     <path class="st3" d="M30.4,117.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-5c0-0.6-0.1-0.7-0.5-0.9l-0.6-0.3V110l2.5-0.3l0.2,0.1l-0.1,0.9
       h0.1c0.8-0.5,1.9-1,2.9-1c1.2,0,1.6,0.4,1.9,1H38c0.9-0.5,1.9-1,3-1c1.4,0,2,0.7,2,2.1v4.8c0,0.6,0.1,0.8,0.4,0.8l0.7,0.2v0.5h-3.8
       v-0.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-4.3c0-1.1-0.3-1.5-1.2-1.5c-0.8,0-1.6,0.3-2.1,0.5v5.3c0,0.6,0.1,0.8,0.4,0.8l0.7,0.2v0.5
       h-3.8v-0.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-4.3c0-1.1-0.3-1.5-1.2-1.5c-0.8,0-1.6,0.3-2.1,0.5v5.3c0,0.7,0.1,0.8,0.4,0.8l0.7,0.2
       v0.5h-3.8V117.5z"/>
     <path class="st3" d="M44.7,117.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-5c0-0.6-0.1-0.7-0.5-0.9l-0.6-0.3V110l2.6-0.3l0.2,0.1
       c0,0-0.1,0.7-0.1,1.8v5c0,0.7,0.1,0.8,0.4,0.8l0.7,0.2v0.5h-3.8V117.5z M45.5,107.1c0-0.6,0.5-1,1.1-1c0.6,0,1.1,0.5,1.1,1
       c0,0.5-0.5,1-1.1,1C46,108.2,45.5,107.7,45.5,107.1z"/>
     <path class="st3" d="M49.2,117.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-8.9c0-0.6-0.1-0.7-0.5-0.8l-0.7-0.3V106l2.7-0.3l0.2,0.1
       c0,0-0.1,0.8-0.1,1.9v8.8c0,0.7,0.1,0.8,0.4,0.8l0.7,0.2v0.5h-3.8V117.5z"/>
     <path class="st3" d="M53.7,117.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-8.9c0-0.6-0.1-0.7-0.5-0.8l-0.7-0.3V106l2.7-0.3l0.2,0.1
       c0,0-0.1,0.8-0.1,1.9v8.8c0,0.7,0.1,0.8,0.4,0.8l0.7,0.2v0.5h-3.8V117.5z"/>
     <path class="st3" d="M58.3,113.8c0-2.6,1.8-4.2,4-4.2c2,0,3.3,1.1,3.3,4.1H60c0,2.4,1.2,3.5,2.8,3.5c1.2,0,2-0.4,2.6-0.7v0.6
       c-0.4,0.4-1.6,1.1-3.1,1.1C59.6,118.2,58.3,116.4,58.3,113.8z M60,113l3.7-0.2c0-1.8-0.3-2.5-1.5-2.5C61,110.3,60.1,111.1,60,113z"
       />
     <path class="st3" d="M66.5,117.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-5c0-0.6-0.1-0.7-0.5-0.9l-0.6-0.3V110l2.5-0.3l0.2,0.1l-0.1,0.9
       h0.1c0.8-0.5,2-1,3-1c1.6,0,2.2,0.7,2.2,2.1v4.8c0,0.6,0.1,0.8,0.4,0.8l0.6,0.2v0.5h-3.7v-0.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8
       v-4.3c0-1.1-0.4-1.5-1.4-1.5c-0.8,0-1.7,0.3-2.2,0.5v5.3c0,0.7,0.1,0.8,0.4,0.8l0.7,0.2v0.5h-3.8V117.5z"/>
     <path class="st3" d="M76.1,117.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-5c0-0.6-0.1-0.7-0.5-0.9l-0.6-0.3V110l2.5-0.3l0.2,0.1l-0.1,0.9
       h0.1c0.8-0.5,2-1,3-1c1.6,0,2.2,0.7,2.2,2.1v4.8c0,0.6,0.1,0.8,0.4,0.8l0.6,0.2v0.5h-3.7v-0.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8
       v-4.3c0-1.1-0.4-1.5-1.4-1.5c-0.8,0-1.7,0.3-2.2,0.5v5.3c0,0.7,0.1,0.8,0.4,0.8l0.7,0.2v0.5h-3.8V117.5z"/>
     <path class="st3" d="M85.8,117.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-5c0-0.6-0.1-0.7-0.5-0.9l-0.6-0.3V110l2.6-0.3l0.2,0.1
       c0,0-0.1,0.7-0.1,1.8v5c0,0.7,0.1,0.8,0.4,0.8l0.7,0.2v0.5h-3.8V117.5z M86.6,107.1c0-0.6,0.5-1,1.1-1c0.6,0,1.1,0.5,1.1,1
       c0,0.5-0.5,1-1.1,1C87,108.2,86.6,107.7,86.6,107.1z"/>
     <path class="st3" d="M90.3,116.1c0-1.3,0.9-2,2.8-2.4l1.9-0.4v-1.5c0-1.1-0.2-1.6-1.5-1.6c-0.7,0-0.8,0.2-0.9,0.6
       c-0.1,0.6-0.2,1.1-1,1.1c-0.6,0-0.8-0.4-0.8-0.8c0-1.1,1.7-1.5,3-1.5c1.9,0,2.8,0.7,2.8,2.1v4.7c0,0.4,0.1,0.7,0.4,0.8
       c0.2,0.1,0.4,0.1,0.8,0.1v0.4c-0.3,0.2-0.8,0.4-1.4,0.4c-0.8,0-1.2-0.4-1.3-0.9H95c-0.4,0.5-1.3,0.9-2.2,0.9
       C91.3,118.2,90.3,117.5,90.3,116.1z M95,116.5v-2.5l-1.2,0.1c-1.2,0.1-1.8,0.5-1.8,1.7c0,0.9,0.6,1.3,1.5,1.3
       C94.2,117.3,94.8,116.9,95,116.5z"/>
     <path class="st3" d="M98.4,117.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-8.9c0-0.6-0.1-0.7-0.5-0.8l-0.7-0.3V106l2.7-0.3l0.2,0.1
       c0,0-0.1,0.8-0.1,1.9v8.8c0,0.7,0.1,0.8,0.4,0.8l0.7,0.2v0.5h-3.8V117.5z"/>
     <path class="st3" d="M103.1,117.8l-0.1-2.4h0.5c0.3,0.6,0.4,1,0.8,1.4c0.3,0.4,0.6,0.6,1.4,0.6c1,0,1.7-0.5,1.7-1.3
       c0-0.9-0.6-1.2-1.9-1.7c-1.4-0.5-2.4-1.2-2.4-2.5c0-1.5,1.3-2.4,3-2.4c0.9,0,1.5,0.1,2.1,0.3l0.1,2.1h-0.5
       c-0.3-0.5-0.5-0.9-0.8-1.3c-0.2-0.3-0.5-0.4-1.1-0.4c-1.1,0-1.5,0.5-1.5,1.3c0,0.6,0.5,1,1.9,1.6c1.5,0.6,2.5,1.2,2.5,2.4
       c0,1.5-1.2,2.7-3.2,2.7C104.7,118.2,103.8,118.1,103.1,117.8z"/>
     <path class="st3" d="M113.8,113.2c0,3.1-2.1,5.7-3.9,7.1l-0.4-0.4c1.5-1.6,2.7-3.8,2.7-6.3c0-3.3-1.2-5.4-2.7-7l0.4-0.4
       C111.7,107.5,113.8,109.8,113.8,113.2z"/>
     <path class="st3" d="M119.2,117.8l-0.1-2.4h0.5c0.3,0.6,0.4,1,0.8,1.4c0.3,0.4,0.6,0.6,1.4,0.6c1,0,1.7-0.5,1.7-1.3
       c0-0.9-0.6-1.2-1.9-1.7c-1.4-0.5-2.4-1.2-2.4-2.5c0-1.5,1.3-2.4,3-2.4c0.9,0,1.5,0.1,2.1,0.3l0.1,2.1h-0.5
       c-0.3-0.5-0.5-0.9-0.8-1.3c-0.2-0.3-0.5-0.4-1.1-0.4c-1.1,0-1.5,0.5-1.5,1.3c0,0.6,0.5,1,1.9,1.6c1.5,0.6,2.5,1.2,2.5,2.4
       c0,1.5-1.2,2.7-3.2,2.7C120.7,118.2,119.9,118.1,119.2,117.8z"/>
     <path class="st3" d="M126.7,116.5v-5.8h-1.1v-0.5c1.1-0.3,1.7-1.1,2.1-2.4h0.6v2h2.3l-0.2,0.8h-2.1v5.6c0,0.7,0.4,1,1.1,1
       c0.5,0,0.9-0.1,1.3-0.2v0.5c-0.3,0.3-1,0.6-2.1,0.6C127.5,118.2,126.7,117.7,126.7,116.5z"/>
     <path class="st3" d="M131.3,117.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-5c0-0.6-0.1-0.7-0.5-0.9l-0.6-0.3V110l2.5-0.3l0.2,0.1
       l-0.1,0.9h0.1c0.6-0.6,1.6-1.1,2.4-1.1c0.7,0,1.1,0.4,1.1,1c0,0.6-0.4,0.9-0.9,0.9c-0.6,0-0.8-0.2-0.9-0.5
       c-0.1-0.2-0.2-0.3-0.4-0.3c-0.3,0-1,0.4-1.2,0.7v5.1c0,0.7,0.1,0.8,0.4,0.8l0.8,0.2v0.5h-3.9V117.5z"/>
     <path class="st3" d="M138,113.8c0-2.6,1.8-4.2,4-4.2c2,0,3.3,1.1,3.3,4.1h-5.5c0,2.4,1.2,3.5,2.8,3.5c1.2,0,2-0.4,2.6-0.7v0.6
       c-0.4,0.4-1.6,1.1-3.1,1.1C139.2,118.2,138,116.4,138,113.8z M139.7,113l3.7-0.2c0-1.8-0.3-2.5-1.5-2.5
       C140.7,110.3,139.8,111.1,139.7,113z"/>
     <path class="st3" d="M146.2,116.1c0-1.3,0.9-2,2.8-2.4l1.9-0.4v-1.5c0-1.1-0.2-1.6-1.5-1.6c-0.7,0-0.8,0.2-0.9,0.6
       c-0.1,0.6-0.2,1.1-1,1.1c-0.6,0-0.8-0.4-0.8-0.8c0-1.1,1.7-1.5,3-1.5c1.9,0,2.8,0.7,2.8,2.1v4.7c0,0.4,0.1,0.7,0.4,0.8
       c0.2,0.1,0.4,0.1,0.8,0.1v0.4c-0.3,0.2-0.8,0.4-1.4,0.4c-0.8,0-1.2-0.4-1.3-0.9h-0.1c-0.4,0.5-1.3,0.9-2.2,0.9
       C147.2,118.2,146.2,117.5,146.2,116.1z M150.9,116.5v-2.5l-1.2,0.1c-1.2,0.1-1.8,0.5-1.8,1.7c0,0.9,0.6,1.3,1.5,1.3
       C150.1,117.3,150.7,116.9,150.9,116.5z"/>
     <path class="st3" d="M154.4,117.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-5c0-0.6-0.1-0.7-0.5-0.9l-0.6-0.3V110l2.5-0.3l0.2,0.1
       l-0.1,0.9h0.1c0.8-0.5,1.9-1,2.9-1c1.2,0,1.6,0.4,1.9,1h0.1c0.9-0.5,1.9-1,3-1c1.4,0,2,0.7,2,2.1v4.8c0,0.6,0.1,0.8,0.4,0.8
       l0.7,0.2v0.5h-3.8v-0.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-4.3c0-1.1-0.3-1.5-1.2-1.5c-0.8,0-1.6,0.3-2.1,0.5v5.3
       c0,0.6,0.1,0.8,0.4,0.8l0.7,0.2v0.5h-3.8v-0.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-4.3c0-1.1-0.3-1.5-1.2-1.5c-0.8,0-1.6,0.3-2.1,0.5
       v5.3c0,0.7,0.1,0.8,0.4,0.8l0.7,0.2v0.5h-3.8V117.5z"/>
     <path class="st3" d="M175.1,117.5l0.8-0.1c0.4-0.1,0.5-0.2,0.5-1v-8.8h-0.1c-1.6,0-1.8,0-2.3,0.8c-0.3,0.6-0.4,1-0.7,1.9h-0.6
       l0.2-3.4h8.9l0.2,3.4h-0.6c-0.3-0.9-0.4-1.3-0.7-1.9c-0.5-0.8-0.7-0.8-2.3-0.8h-0.1v8.8c0,0.8,0.1,0.9,0.5,1l0.8,0.1v0.5h-4.3
       V117.5z"/>
     <path class="st3" d="M183.9,109c-0.4-1.1-0.5-1.3-1-1.5l-0.4-0.1v-0.5h4.1v0.5l-0.5,0.1c-0.4,0.1-0.5,0.3-0.5,0.5
       c0,0.2,0.1,0.7,0.3,1.1l2.4,6.8h0.1l2.3-6.8c0.1-0.3,0.2-0.8,0.2-1c0-0.4-0.2-0.5-0.6-0.6l-0.5-0.1v-0.5h3.3v0.5l-0.4,0.1
       c-0.5,0.1-0.5,0.3-1,1.6l-3.1,8.9h-1.1L183.9,109z"/>
     <path class="st3" d="M197.6,117.8l-0.1-2.4h0.5c0.3,0.6,0.4,1,0.8,1.4c0.3,0.4,0.6,0.6,1.4,0.6c1,0,1.7-0.5,1.7-1.3
       c0-0.9-0.6-1.2-1.9-1.7c-1.4-0.5-2.4-1.2-2.4-2.5c0-1.5,1.3-2.4,3-2.4c0.9,0,1.5,0.1,2.1,0.3l0.1,2.1h-0.5
       c-0.3-0.5-0.5-0.9-0.8-1.3c-0.2-0.3-0.5-0.4-1.1-0.4c-1.1,0-1.5,0.5-1.5,1.3c0,0.6,0.5,1,1.9,1.6c1.5,0.6,2.5,1.2,2.5,2.4
       c0,1.5-1.2,2.7-3.2,2.7C199.1,118.2,198.3,118.1,197.6,117.8z"/>
     <path class="st3" d="M204,117.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-8.9c0-0.6-0.1-0.7-0.5-0.8l-0.7-0.3V106l2.7-0.3l0.2,0.1
       c0,0-0.1,0.8-0.1,1.9v1.5c0,0.7-0.1,1.4-0.1,1.4h0.1c0.8-0.5,2-1,3-1c1.6,0,2.2,0.7,2.2,2.1v4.8c0,0.6,0.1,0.8,0.4,0.8l0.6,0.2v0.5
       h-3.7v-0.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-4.3c0-1.1-0.4-1.5-1.4-1.5c-0.8,0-1.7,0.3-2.2,0.5v5.3c0,0.7,0.1,0.8,0.4,0.8l0.7,0.2
       v0.5H204V117.5z"/>
     <path class="st3" d="M213.9,113.9c0-2.8,2-4.3,4-4.3c2.3,0,4,1.4,4,4.2c0,2.8-2,4.4-4,4.4C215.6,118.2,213.9,116.8,213.9,113.9z
        M220.2,114c0-2.7-0.8-3.8-2.3-3.8c-1.4,0-2.2,1-2.2,3.4c0,2.7,0.8,3.8,2.4,3.8C219.4,117.5,220.2,116.4,220.2,114z"/>
     <path class="st3" d="M223.5,111.5c-0.3-0.7-0.4-0.9-0.9-1.1l-0.4-0.1v-0.5h3.6v0.5l-0.4,0.1c-0.4,0.1-0.4,0.3-0.4,0.5
       c0,0.2,0.1,0.5,0.2,0.8l1.4,4.4h0.1l1.5-4.3l-0.1-0.4c-0.2-0.6-0.4-1-0.8-1.1l-0.4-0.1v-0.5h3.4v0.5l-0.4,0.1
       c-0.3,0.1-0.4,0.3-0.4,0.5c0,0.2,0.1,0.5,0.2,0.8l1.5,4.4h0.1l1.4-4.4c0.1-0.4,0.2-0.7,0.2-0.9c0-0.2-0.1-0.4-0.4-0.5l-0.4-0.1
       v-0.5h2.8v0.5l-0.4,0.1c-0.4,0.1-0.5,0.4-0.8,1.2l-2.3,6.3h-1l-1.8-5.1h0l-1.8,5.1h-1L223.5,111.5z"/>
     <path class="st3" d="M235.3,117.8l-0.1-2.4h0.5c0.3,0.6,0.4,1,0.8,1.4c0.3,0.4,0.6,0.6,1.4,0.6c1,0,1.7-0.5,1.7-1.3
       c0-0.9-0.6-1.2-1.9-1.7c-1.4-0.5-2.4-1.2-2.4-2.5c0-1.5,1.3-2.4,3-2.4c0.9,0,1.5,0.1,2.1,0.3l0.1,2.1H240c-0.3-0.5-0.5-0.9-0.8-1.3
       c-0.2-0.3-0.5-0.4-1.1-0.4c-1.1,0-1.5,0.5-1.5,1.3c0,0.6,0.5,1,1.9,1.6c1.5,0.6,2.5,1.2,2.5,2.4c0,1.5-1.2,2.7-3.2,2.7
       C236.8,118.2,236,118.1,235.3,117.8z"/>
     <path class="st3" d="M246,116.1c0-1.3,0.9-2,2.8-2.4l1.9-0.4v-1.5c0-1.1-0.2-1.6-1.5-1.6c-0.7,0-0.8,0.2-0.9,0.6
       c-0.1,0.6-0.2,1.1-1,1.1c-0.6,0-0.8-0.4-0.8-0.8c0-1.1,1.7-1.5,3-1.5c1.9,0,2.8,0.7,2.8,2.1v4.7c0,0.4,0.1,0.7,0.4,0.8
       c0.2,0.1,0.4,0.1,0.8,0.1v0.4c-0.3,0.2-0.8,0.4-1.4,0.4c-0.8,0-1.2-0.4-1.3-0.9h-0.1c-0.4,0.5-1.3,0.9-2.2,0.9
       C246.9,118.2,246,117.5,246,116.1z M250.6,116.5v-2.5l-1.2,0.1c-1.2,0.1-1.8,0.5-1.8,1.7c0,0.9,0.6,1.3,1.5,1.3
       C249.9,117.3,250.4,116.9,250.6,116.5z"/>
     <path class="st3" d="M254.9,116.5v-5.8h-1.1v-0.5c1.1-0.3,1.7-1.1,2.1-2.4h0.6v2h2.3l-0.2,0.8h-2.1v5.6c0,0.7,0.4,1,1.1,1
       c0.5,0,0.9-0.1,1.3-0.2v0.5c-0.3,0.3-1,0.6-2.1,0.6C255.7,118.2,254.9,117.7,254.9,116.5z"/>
   </g>
   <g class="st2">
     <path class="st3" d="M30.4,137.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-8.9c0-0.6-0.1-0.7-0.5-0.8l-0.7-0.3V126l2.7-0.3l0.2,0.1
       c0,0-0.1,0.8-0.1,1.9v8.8c0,0.7,0.1,0.8,0.4,0.8l0.7,0.2v0.5h-3.8V137.5z"/>
     <path class="st3" d="M35,133.8c0-2.6,1.8-4.2,4-4.2c2,0,3.3,1.1,3.3,4.1h-5.5c0,2.4,1.2,3.5,2.8,3.5c1.2,0,2-0.4,2.6-0.7v0.6
       c-0.4,0.4-1.6,1.1-3.1,1.1C36.3,138.2,35,136.4,35,133.8z M36.7,133l3.7-0.2c0-1.8-0.3-2.5-1.5-2.5C37.7,130.3,36.9,131.1,36.7,133
       z"/>
     <path class="st3" d="M43.3,136.1c0-1.3,0.9-2,2.8-2.4l1.9-0.4v-1.5c0-1.1-0.2-1.6-1.5-1.6c-0.7,0-0.8,0.2-0.9,0.6
       c-0.1,0.6-0.2,1.1-1,1.1c-0.6,0-0.8-0.4-0.8-0.8c0-1.1,1.7-1.5,3-1.5c1.9,0,2.8,0.7,2.8,2.1v4.7c0,0.4,0.1,0.7,0.4,0.8
       c0.2,0.1,0.4,0.1,0.8,0.1v0.4c-0.3,0.2-0.8,0.4-1.4,0.4c-0.8,0-1.2-0.4-1.3-0.9H48c-0.4,0.5-1.3,0.9-2.2,0.9
       C44.2,138.2,43.3,137.5,43.3,136.1z M48,136.5v-2.5l-1.2,0.1c-1.2,0.1-1.8,0.5-1.8,1.7c0,0.9,0.6,1.3,1.5,1.3
       C47.2,137.3,47.7,136.9,48,136.5z"/>
     <path class="st3" d="M51.6,137.8l-0.1-2.4h0.5c0.3,0.6,0.4,1,0.8,1.4c0.3,0.4,0.6,0.6,1.4,0.6c1,0,1.7-0.5,1.7-1.3
       c0-0.9-0.6-1.2-1.9-1.7c-1.4-0.5-2.4-1.2-2.4-2.5c0-1.5,1.3-2.4,3-2.4c0.9,0,1.5,0.1,2.1,0.3l0.1,2.1h-0.5
       c-0.3-0.5-0.5-0.9-0.8-1.3c-0.2-0.3-0.5-0.4-1.1-0.4c-1.1,0-1.5,0.5-1.5,1.3c0,0.6,0.5,1,1.9,1.6c1.5,0.6,2.5,1.2,2.5,2.4
       c0,1.5-1.2,2.7-3.2,2.7C53.2,138.2,52.3,138.1,51.6,137.8z"/>
     <path class="st3" d="M59.1,136.5v-5.8H58v-0.5c1.1-0.3,1.7-1.1,2.1-2.4h0.6v2H63l-0.2,0.8h-2.1v5.6c0,0.7,0.4,1,1.1,1
       c0.5,0,0.9-0.1,1.3-0.2v0.5c-0.3,0.3-1,0.6-2.1,0.6C60,138.2,59.1,137.7,59.1,136.5z"/>
     <path class="st3" d="M67.7,137.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-5c0-0.6-0.1-0.7-0.5-0.9l-0.6-0.3V130l2.5-0.3l0.2,0.1l-0.1,0.9
       h0.1c0.8-0.5,1.9-1,2.9-1c1.2,0,1.6,0.4,1.9,1h0.1c0.9-0.5,1.9-1,3-1c1.4,0,2,0.7,2,2.1v4.8c0,0.6,0.1,0.8,0.4,0.8l0.7,0.2v0.5
       h-3.8v-0.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-4.3c0-1.1-0.3-1.5-1.2-1.5c-0.8,0-1.6,0.3-2.1,0.5v5.3c0,0.6,0.1,0.8,0.4,0.8l0.7,0.2
       v0.5h-3.8v-0.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-4.3c0-1.1-0.3-1.5-1.2-1.5c-0.8,0-1.6,0.3-2.1,0.5v5.3c0,0.7,0.1,0.8,0.4,0.8
       l0.7,0.2v0.5h-3.8V137.5z"/>
     <path class="st3" d="M82.1,133.9c0-2.8,2-4.3,4-4.3c2.3,0,4,1.4,4,4.2c0,2.8-2,4.4-4,4.4C83.9,138.2,82.1,136.8,82.1,133.9z
        M88.5,134c0-2.7-0.8-3.8-2.3-3.8c-1.4,0-2.2,1-2.2,3.4c0,2.7,0.8,3.8,2.4,3.8C87.7,137.5,88.5,136.4,88.5,134z"/>
     <path class="st3" d="M91.2,137.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-5c0-0.6-0.1-0.7-0.5-0.9l-0.6-0.3V130l2.5-0.3l0.2,0.1l-0.1,0.9
       h0.1c0.8-0.5,2-1,3-1c1.6,0,2.2,0.7,2.2,2.1v4.8c0,0.6,0.1,0.8,0.4,0.8l0.6,0.2v0.5h-3.7v-0.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8
       v-4.3c0-1.1-0.4-1.5-1.4-1.5c-0.8,0-1.7,0.3-2.2,0.5v5.3c0,0.7,0.1,0.8,0.4,0.8l0.7,0.2v0.5h-3.8V137.5z"/>
     <path class="st3" d="M101.7,136.5v-5.8h-1.1v-0.5c1.1-0.3,1.7-1.1,2.1-2.4h0.6v2h2.3l-0.2,0.8h-2.1v5.6c0,0.7,0.4,1,1.1,1
       c0.5,0,0.9-0.1,1.3-0.2v0.5c-0.3,0.3-1,0.6-2.1,0.6C102.6,138.2,101.7,137.7,101.7,136.5z"/>
     <path class="st3" d="M106.3,137.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-8.9c0-0.6-0.1-0.7-0.5-0.8l-0.7-0.3V126l2.7-0.3l0.2,0.1
       c0,0-0.1,0.8-0.1,1.9v1.5c0,0.7-0.1,1.4-0.1,1.4h0.1c0.8-0.5,2-1,3-1c1.6,0,2.2,0.7,2.2,2.1v4.8c0,0.6,0.1,0.8,0.4,0.8l0.6,0.2v0.5
       h-3.7v-0.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-4.3c0-1.1-0.4-1.5-1.4-1.5c-0.8,0-1.7,0.3-2.2,0.5v5.3c0,0.7,0.1,0.8,0.4,0.8l0.7,0.2
       v0.5h-3.8V137.5z"/>
     <path class="st3" d="M115.9,137.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-8.9c0-0.6-0.1-0.7-0.5-0.8l-0.7-0.3V126l2.7-0.3l0.2,0.1
       c0,0-0.1,0.8-0.1,1.9v8.8c0,0.7,0.1,0.8,0.4,0.8l0.7,0.2v0.5h-3.8V137.5z"/>
     <path class="st3" d="M120.1,140.6c0-0.5,0.3-0.9,0.8-0.9c0.5,0,0.8,0.3,0.9,0.7c0.1,0.2,0.1,0.3,0.3,0.3c0.4,0,0.9-0.5,1.4-1.7
       l0.4-0.9l-2.7-6.5c-0.4-0.8-0.4-0.9-0.8-1.1l-0.4-0.1v-0.5h3.6v0.5l-0.4,0.1c-0.4,0.1-0.5,0.2-0.5,0.5c0,0.2,0.1,0.5,0.2,0.9
       l1.7,4.3h0.1l1.6-4.3c0.1-0.4,0.2-0.7,0.2-0.9c0-0.3-0.2-0.3-0.4-0.4l-0.4-0.1v-0.5h2.9v0.5l-0.4,0.1c-0.4,0.1-0.5,0.3-0.8,1.2
       l-2.9,7.3c-0.6,1.6-1.4,2.7-2.8,2.7C120.5,141.6,120.1,141.1,120.1,140.6z"/>
     <path class="st3" d="M128.6,132.2c0.8-0.6,1.6-1.3,2-1.8c0.7-0.8,1.2-1.4,1.2-2.5c0-1-0.6-1.4-1.2-1.4c-0.5,0-0.7,0.1-0.7,0.4
       c0,0.4-0.2,0.7-0.6,0.7c-0.4,0-0.5-0.2-0.5-0.5c0-0.5,0.7-1,1.9-1c1.2,0,2.1,0.6,2.1,1.8c0,1.4-1.6,2.6-3.3,3.9v0h2.2
       c0.5,0,0.6-0.1,0.9-0.7h0.3l-0.1,1.5h-4.2V132.2z"/>
   </g>
   <g class="st2">
     <path class="st4" d="M31.9,56.6c0-16.3,9.8-22.3,16.6-22.3c4.6,0,7,1.6,7,4.2c0,1.3-0.8,2.1-2.2,2.1c-1.6,0-2.2-0.6-2.5-2.4
       c-0.4-1.7-1-2.3-2.8-2.3c-4.6,0-11,4.2-11.8,16.3c1.8-1.5,4.8-3.1,8.4-3.1c6.7,0,10.8,4.2,10.8,11c0,7.3-5.9,11.4-11,11.4
       C36.9,71.6,31.9,65.6,31.9,56.6z M51.4,60.9c0-5.9-2.2-9.8-7.7-9.8c-2.5,0-5.7,1.1-7.4,2.4c-0.1,1-0.1,0.8-0.1,1.9
       c0,4.9,0.5,14.6,8.4,14.6C49.9,70.1,51.4,66.2,51.4,60.9z"/>
     <path class="st4" d="M58.8,53.6c0-13.6,6.9-19.3,13.8-19.3C79,34.3,86,39.2,86,52.5c0,13.7-7.1,19.1-13.7,19.1
       C66,71.6,58.8,66.8,58.8,53.6z M81.7,53.6c0-11.1-3.2-17.6-9.3-17.6c-6.1,0-9.4,6-9.4,16.6c0,11.1,3.4,17.4,9.4,17.4
       C78.4,70,81.7,64.4,81.7,53.6z"/>
     <path class="st4" d="M89.3,44c0-6.4,4.2-9.6,7.9-9.6c3.6,0,7.8,2.8,7.8,9c0,6.4-4.4,9.4-7.9,9.4C93.5,52.8,89.3,50.1,89.3,44z
        M116.9,33.9h2.1L93.3,73.6h-2.1L116.9,33.9z M101.2,44c0-5.5-1.3-8.4-4.1-8.4c-2.8,0-4,2.6-4,7.7c0,5.5,1.4,8.3,4,8.3
       C99.8,51.7,101.2,49.3,101.2,44z M106,62.9c0-6.4,4.2-9.6,7.9-9.6c3.6,0,7.8,2.8,7.8,9c0,6.4-4.4,9.4-7.9,9.4
       C110.2,71.7,106,69.1,106,62.9z M117.9,62.9c0-5.5-1.3-8.4-4.1-8.4c-2.8,0-4,2.6-4,7.7c0,5.5,1.4,8.3,4,8.3
       C116.5,70.6,117.9,68.2,117.9,62.9z"/>
   </g>
   </svg>
    `),
      this.sanitizer.bypassSecurityTrustHtml(`<svg class="stat-card-svg" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 300 200" style="enable-background:new 0 0 300 200;" xml:space="preserve">
   <style type="text/css">
     .st0{fill:none;}
     .st1{fill:none;stroke:#6E27C5;stroke-width:4;stroke-miterlimit:10;}
     .st2{enable-background:new    ;}
     .st3{fill:#333333;}
     .st4{fill:#6E27C5;}
   </style>
   <g id="Rectangle_4_Copy_7" transform="translate(914 1462.5)">
     <rect x="-914" y="-1462.5" class="st0" width="300" height="200"/>
     <rect x="-912" y="-1460.5" class="st1" width="296" height="196"/>
   </g>
   <g class="st2">
     <path class="st3" d="M31.6,32c-0.4-1.1-0.5-1.3-1-1.5l-0.4-0.1v-0.5h4v0.5l-0.5,0.1c-0.4,0.1-0.5,0.3-0.5,0.5
       c0,0.2,0.1,0.7,0.3,1.1l2.4,6.8h0.1l2.3-6.8c0.1-0.3,0.2-0.8,0.2-1c0-0.4-0.2-0.5-0.6-0.6l-0.5-0.1v-0.5h3.3v0.5l-0.4,0.1
       c-0.5,0.1-0.5,0.3-1,1.6L35.9,41h-1.1L31.6,32z"/>
     <path class="st3" d="M40.9,40.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-5c0-0.6-0.1-0.7-0.5-0.9l-0.6-0.3V33l2.6-0.3l0.2,0.1
       c0,0-0.1,0.7-0.1,1.8v5c0,0.7,0.1,0.8,0.4,0.8l0.7,0.2V41h-3.8V40.5z M41.6,30.1c0-0.6,0.5-1,1.1-1c0.6,0,1.1,0.5,1.1,1
       c0,0.5-0.5,1-1.1,1C42.1,31.2,41.6,30.7,41.6,30.1z"/>
     <path class="st3" d="M45.5,37.2c0-3.1,2.2-4.6,4.5-4.6c0.7,0,1.2,0.1,1.5,0.2c0,0,0-0.6,0-1.2v-1c0-0.6-0.1-0.7-0.5-0.8l-0.7-0.3
       V29l2.7-0.3l0.2,0.1c0,0-0.1,0.6-0.1,1.9v8.4c0,0.7,0.1,0.8,0.5,1l0.6,0.3v0.4l-2.5,0.3l-0.1-0.1l0.1-0.8h-0.1
       c-0.7,0.5-1.7,0.9-2.8,0.9C47.2,41.2,45.5,40.2,45.5,37.2z M51.5,39.6V34c-0.4-0.3-0.8-0.7-1.6-0.7c-1.7,0-2.7,0.9-2.7,3.5
       c0,2.3,0.9,3.2,2.4,3.2C50.6,40.1,51.2,39.8,51.5,39.6z"/>
     <path class="st3" d="M55,36.8c0-2.6,1.8-4.2,4-4.2c2,0,3.3,1.1,3.3,4.1h-5.5c0,2.4,1.2,3.5,2.8,3.5c1.2,0,2-0.4,2.6-0.7v0.6
       c-0.4,0.4-1.6,1.1-3.1,1.1C56.3,41.2,55,39.4,55,36.8z M56.7,36l3.7-0.2c0-1.8-0.3-2.5-1.5-2.5C57.7,33.3,56.9,34.1,56.7,36z"/>
     <path class="st3" d="M63.3,36.9c0-2.8,2-4.3,4-4.3c2.3,0,4,1.4,4,4.2c0,2.8-2,4.4-4,4.4C65.1,41.2,63.3,39.8,63.3,36.9z M69.6,37
       c0-2.7-0.8-3.8-2.3-3.8c-1.4,0-2.2,1-2.2,3.4c0,2.7,0.8,3.8,2.4,3.8C68.9,40.5,69.6,39.4,69.6,37z"/>
     <path class="st3" d="M76.6,40.8l-0.1-2.4H77c0.3,0.6,0.4,1,0.8,1.4c0.3,0.4,0.6,0.6,1.4,0.6c1,0,1.7-0.5,1.7-1.3
       c0-0.9-0.6-1.2-1.9-1.7c-1.4-0.5-2.4-1.2-2.4-2.5c0-1.5,1.3-2.4,3-2.4c0.9,0,1.5,0.1,2.1,0.3l0.1,2.1h-0.5
       c-0.3-0.5-0.5-0.9-0.8-1.3c-0.2-0.3-0.5-0.4-1.1-0.4c-1.1,0-1.5,0.5-1.5,1.3c0,0.6,0.5,1,1.9,1.6c1.5,0.6,2.5,1.2,2.5,2.4
       c0,1.5-1.2,2.7-3.2,2.7C78.1,41.2,77.2,41.1,76.6,40.8z"/>
     <path class="st3" d="M84,39.5v-5.8h-1.1v-0.5c1.1-0.3,1.7-1.1,2.1-2.4h0.6v2H88l-0.2,0.8h-2.1v5.6c0,0.7,0.4,1,1.1,1
       c0.5,0,0.9-0.1,1.3-0.2v0.5c-0.3,0.3-1,0.6-2.1,0.6C84.9,41.2,84,40.7,84,39.5z"/>
     <path class="st3" d="M88.7,40.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-5c0-0.6-0.1-0.7-0.5-0.9l-0.6-0.3V33l2.5-0.3l0.2,0.1l-0.1,0.9
       h0.1c0.6-0.6,1.6-1.1,2.4-1.1c0.7,0,1.1,0.4,1.1,1c0,0.6-0.4,0.9-0.9,0.9c-0.6,0-0.8-0.2-0.9-0.5c-0.1-0.2-0.2-0.3-0.4-0.3
       c-0.3,0-1,0.4-1.2,0.7v5.1c0,0.7,0.1,0.8,0.4,0.8l0.8,0.2V41h-3.9V40.5z"/>
     <path class="st3" d="M95.3,36.8c0-2.6,1.8-4.2,4-4.2c2,0,3.3,1.1,3.3,4.1H97c0,2.4,1.2,3.5,2.8,3.5c1.2,0,2-0.4,2.6-0.7v0.6
       c-0.4,0.4-1.6,1.1-3.1,1.1C96.6,41.2,95.3,39.4,95.3,36.8z M97,36l3.7-0.2c0-1.8-0.3-2.5-1.5-2.5C98,33.3,97.2,34.1,97,36z"/>
     <path class="st3" d="M103.6,39.1c0-1.3,0.9-2,2.8-2.4l1.9-0.4v-1.5c0-1.1-0.2-1.6-1.5-1.6c-0.7,0-0.8,0.2-0.9,0.6
       c-0.1,0.6-0.2,1.1-1,1.1c-0.6,0-0.8-0.4-0.8-0.8c0-1.1,1.7-1.5,3-1.5c1.9,0,2.8,0.7,2.8,2.1v4.7c0,0.4,0.1,0.7,0.4,0.8
       c0.2,0.1,0.4,0.1,0.8,0.1v0.4c-0.3,0.2-0.8,0.4-1.4,0.4c-0.8,0-1.2-0.4-1.3-0.9h-0.1c-0.4,0.5-1.3,0.9-2.2,0.9
       C104.5,41.2,103.6,40.5,103.6,39.1z M108.3,39.5v-2.5l-1.2,0.1c-1.2,0.1-1.8,0.5-1.8,1.7c0,0.9,0.6,1.3,1.5,1.3
       C107.5,40.3,108,39.9,108.3,39.5z"/>
     <path class="st3" d="M111.7,40.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-5c0-0.6-0.1-0.7-0.5-0.9l-0.6-0.3V33l2.5-0.3l0.2,0.1l-0.1,0.9
       h0.1c0.8-0.5,1.9-1,2.9-1c1.2,0,1.6,0.4,1.9,1h0.1c0.9-0.5,1.9-1,3-1c1.4,0,2,0.7,2,2.1v4.8c0,0.6,0.1,0.8,0.4,0.8l0.7,0.2V41h-3.8
       v-0.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-4.3c0-1.1-0.3-1.5-1.2-1.5c-0.8,0-1.6,0.3-2.1,0.5v5.3c0,0.6,0.1,0.8,0.4,0.8l0.7,0.2V41
       h-3.8v-0.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-4.3c0-1.1-0.3-1.5-1.2-1.5c-0.8,0-1.6,0.3-2.1,0.5v5.3c0,0.7,0.1,0.8,0.4,0.8l0.7,0.2
       V41h-3.8V40.5z"/>
     <path class="st3" d="M126,40.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-5c0-0.6-0.1-0.7-0.5-0.9l-0.6-0.3V33l2.6-0.3l0.2,0.1
       c0,0-0.1,0.7-0.1,1.8v5c0,0.7,0.1,0.8,0.4,0.8l0.7,0.2V41H126V40.5z M126.8,30.1c0-0.6,0.5-1,1.1-1c0.6,0,1.1,0.5,1.1,1
       c0,0.5-0.5,1-1.1,1C127.3,31.2,126.8,30.7,126.8,30.1z"/>
     <path class="st3" d="M130.5,40.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-5c0-0.6-0.1-0.7-0.5-0.9l-0.6-0.3V33l2.5-0.3l0.2,0.1l-0.1,0.9
       h0.1c0.8-0.5,2-1,3-1c1.6,0,2.2,0.7,2.2,2.1v4.8c0,0.6,0.1,0.8,0.4,0.8l0.6,0.2V41h-3.7v-0.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-4.3
       c0-1.1-0.4-1.5-1.4-1.5c-0.8,0-1.7,0.3-2.2,0.5v5.3c0,0.7,0.1,0.8,0.4,0.8l0.7,0.2V41h-3.8V40.5z"/>
     <path class="st3" d="M139.7,42.7c0-0.8,0.7-1.5,1.8-1.9v-0.1c-0.5-0.2-1.1-0.6-1.1-1.3c0-0.9,0.7-1.4,1.2-1.8v-0.1
       c-0.7-0.3-1.4-1.1-1.4-2.1c0-1.9,1.6-2.8,3.2-2.8c0.6,0,1.1,0.1,1.7,0.3c0.6-0.5,1.3-0.9,2-0.9c0.5,0,0.9,0.3,0.9,0.8
       c0,0.4-0.2,0.8-0.7,0.8c-0.4,0-0.6-0.2-0.7-0.5c-0.1-0.2-0.7,0-1.1,0.2v0c0.7,0.4,1.1,1,1.1,1.9c0,1.9-1.6,2.7-3.2,2.7
       c-0.5,0-0.8-0.1-1.2-0.2c-0.3,0.2-0.6,0.5-0.6,0.9c0,0.4,0.3,0.7,1,0.7h2.5c1.4,0,2.3,0.4,2.3,2c0,1.2-1,3.2-4.4,3.2
       C141,44.6,139.7,43.8,139.7,42.7z M146.4,42c0-0.7-0.3-1.2-1.4-1.2h-2.5c-0.5,0.1-1.3,0.8-1.3,1.6c0,1,0.7,1.5,2.3,1.5
       C145,43.9,146.4,43.1,146.4,42z M145,35.4c0-1.6-0.5-2.2-1.6-2.2c-1,0-1.5,0.6-1.5,2c0,1.6,0.5,2.1,1.5,2.1
       C144.5,37.3,145,36.8,145,35.4z"/>
     <path class="st3" d="M152.3,40.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-5c0-0.6-0.1-0.7-0.5-0.9l-0.6-0.3V33l2.6-0.3l0.2,0.1
       c0,0-0.1,0.7-0.1,1.8v5c0,0.7,0.1,0.8,0.4,0.8l0.7,0.2V41h-3.8V40.5z M153.1,30.1c0-0.6,0.5-1,1.1-1c0.6,0,1.1,0.5,1.1,1
       c0,0.5-0.5,1-1.1,1C153.6,31.2,153.1,30.7,153.1,30.1z"/>
     <path class="st3" d="M157.2,40.8l-0.1-2.4h0.5c0.3,0.6,0.4,1,0.8,1.4c0.3,0.4,0.6,0.6,1.4,0.6c1,0,1.7-0.5,1.7-1.3
       c0-0.9-0.6-1.2-1.9-1.7c-1.4-0.5-2.4-1.2-2.4-2.5c0-1.5,1.3-2.4,3-2.4c0.9,0,1.5,0.1,2.1,0.3l0.1,2.1h-0.5
       c-0.3-0.5-0.5-0.9-0.8-1.3c-0.2-0.3-0.5-0.4-1.1-0.4c-1.1,0-1.5,0.5-1.5,1.3c0,0.6,0.5,1,1.9,1.6c1.5,0.6,2.5,1.2,2.5,2.4
       c0,1.5-1.2,2.7-3.2,2.7C158.7,41.2,157.8,41.1,157.2,40.8z"/>
   </g>
   <g class="st2">
     <path class="st3" d="M30.5,56.8c0-2.6,1.8-4.2,4-4.2c2,0,3.3,1.1,3.3,4.1h-5.5c0,2.4,1.2,3.5,2.8,3.5c1.2,0,2-0.4,2.6-0.7v0.6
       c-0.4,0.4-1.6,1.1-3.1,1.1C31.8,61.2,30.5,59.4,30.5,56.8z M32.3,56l3.7-0.2c0-1.8-0.3-2.5-1.5-2.5C33.3,53.3,32.4,54.1,32.3,56z"
       />
     <path class="st3" d="M38.5,60.5l0.5-0.1c0.6-0.2,0.9-0.4,1.4-1.2l1.5-2.2L40,54.6c-0.7-0.9-0.8-1.1-1.3-1.2l-0.3-0.1v-0.5h3.7v0.5
       l-0.4,0.1c-0.3,0.1-0.4,0.2-0.4,0.4c0,0.2,0.2,0.5,0.4,0.8l1.1,1.5h0l0.8-1.3c0.3-0.5,0.5-0.8,0.5-1c0-0.2-0.1-0.3-0.3-0.3
       l-0.5-0.1v-0.5h3.2v0.5L46,53.4c-0.6,0.2-0.8,0.4-1.4,1.2l-1.4,1.9l2,2.7c0.6,0.8,0.8,1.1,1.4,1.2l0.2,0V61h-3.7v-0.5l0.4-0.1
       c0.3-0.1,0.4-0.2,0.4-0.4c0-0.2-0.2-0.5-0.5-0.8l-1.2-1.7h0l-0.9,1.5c-0.3,0.5-0.5,0.8-0.5,1c0,0.2,0.1,0.3,0.5,0.4l0.3,0.1V61
       h-3.2V60.5z"/>
     <path class="st3" d="M47.2,64l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-8.5c0-0.6-0.1-0.7-0.5-0.9l-0.6-0.3V53l2.5-0.3l0.2,0.1l-0.1,0.8
       h0.1c0.7-0.5,1.7-0.9,2.8-0.9c1.6,0,3.2,0.9,3.2,3.9s-1.8,4.7-4.6,4.7c-0.4,0-1-0.1-1.4-0.2c0,0,0,0.5,0,1v1c0,0.7,0.1,0.8,0.4,0.8
       l0.9,0.2v0.5h-4V64z M54.2,56.9c0-2.2-0.9-3.2-2.5-3.2c-0.9,0-1.5,0.4-1.8,0.5v5.7c0.4,0.4,0.9,0.6,1.6,0.6
       C53.3,60.5,54.2,59.5,54.2,56.9z"/>
     <path class="st3" d="M57,56.8c0-2.6,1.8-4.2,4-4.2c2,0,3.3,1.1,3.3,4.1h-5.5c0,2.4,1.2,3.5,2.8,3.5c1.2,0,2-0.4,2.6-0.7v0.6
       c-0.4,0.4-1.6,1.1-3.1,1.1C58.3,61.2,57,59.4,57,56.8z M58.7,56l3.7-0.2c0-1.8-0.3-2.5-1.5-2.5C59.7,53.3,58.9,54.1,58.7,56z"/>
     <path class="st3" d="M65.3,57c0-3.4,2.6-4.4,4.6-4.4c1.5,0,2.3,0.5,2.3,1.3c0,0.5-0.4,1-0.9,1c-0.6,0-0.8-0.3-0.9-0.9
       c-0.1-0.7-0.3-0.8-0.8-0.8c-1.3,0-2.4,0.9-2.4,3.2c0,2.4,1,3.7,2.8,3.7c1.1,0,1.9-0.4,2.5-0.7v0.6c-0.5,0.4-1.6,1.1-3.1,1.1
       C66.6,61.2,65.3,59.4,65.3,57z"/>
     <path class="st3" d="M74.1,59.5v-5.8H73v-0.5c1.1-0.3,1.7-1.1,2.1-2.4h0.6v2H78l-0.2,0.8h-2.1v5.6c0,0.7,0.4,1,1.1,1
       c0.5,0,0.9-0.1,1.3-0.2v0.5c-0.3,0.3-1,0.6-2.1,0.6C74.9,61.2,74.1,60.7,74.1,59.5z"/>
     <path class="st3" d="M78.7,56.8c0-2.6,1.8-4.2,4-4.2c2,0,3.3,1.1,3.3,4.1h-5.5c0,2.4,1.2,3.5,2.8,3.5c1.2,0,2-0.4,2.6-0.7v0.6
       c-0.4,0.4-1.6,1.1-3.1,1.1C80,61.2,78.7,59.4,78.7,56.8z M80.4,56l3.7-0.2c0-1.8-0.3-2.5-1.5-2.5C81.4,53.3,80.5,54.1,80.4,56z"/>
     <path class="st3" d="M87.1,57.2c0-3.1,2.2-4.6,4.5-4.6c0.7,0,1.2,0.1,1.5,0.2c0,0,0-0.6,0-1.2v-1c0-0.6-0.1-0.7-0.5-0.8l-0.7-0.3
       V49l2.7-0.3l0.2,0.1c0,0-0.1,0.6-0.1,1.9v8.4c0,0.7,0.1,0.8,0.5,1l0.6,0.3v0.4l-2.5,0.3l-0.1-0.1l0.1-0.8h-0.1
       c-0.7,0.5-1.7,0.9-2.8,0.9C88.7,61.2,87.1,60.2,87.1,57.2z M93,59.6V54c-0.4-0.3-0.8-0.7-1.6-0.7c-1.7,0-2.7,0.9-2.7,3.5
       c0,2.3,0.9,3.2,2.4,3.2C92.1,60.1,92.7,59.8,93,59.6z"/>
     <path class="st3" d="M101.3,59.5v-5.8h-1.1v-0.5c1.1-0.3,1.7-1.1,2.1-2.4h0.6v2h2.3l-0.2,0.8h-2.1v5.6c0,0.7,0.4,1,1.1,1
       c0.5,0,0.9-0.1,1.3-0.2v0.5c-0.3,0.3-1,0.6-2.1,0.6C102.1,61.2,101.3,60.7,101.3,59.5z"/>
     <path class="st3" d="M105.9,56.9c0-2.8,2-4.3,4-4.3c2.3,0,4,1.4,4,4.2c0,2.8-2,4.4-4,4.4C107.7,61.2,105.9,59.8,105.9,56.9z
        M112.2,57c0-2.7-0.8-3.8-2.3-3.8c-1.4,0-2.2,1-2.2,3.4c0,2.7,0.8,3.8,2.4,3.8C111.5,60.5,112.2,59.4,112.2,57z"/>
     <path class="st3" d="M118.4,62.7c0-0.8,0.7-1.5,1.8-1.9v-0.1c-0.5-0.2-1.1-0.6-1.1-1.3c0-0.9,0.7-1.4,1.2-1.8v-0.1
       c-0.7-0.3-1.4-1.1-1.4-2.1c0-1.9,1.6-2.8,3.2-2.8c0.6,0,1.1,0.1,1.7,0.3c0.6-0.5,1.3-0.9,2-0.9c0.5,0,0.9,0.3,0.9,0.8
       c0,0.4-0.2,0.8-0.7,0.8c-0.4,0-0.6-0.2-0.7-0.5c-0.1-0.2-0.7,0-1.1,0.2v0c0.7,0.4,1.1,1,1.1,1.9c0,1.9-1.6,2.7-3.2,2.7
       c-0.5,0-0.8-0.1-1.2-0.2c-0.3,0.2-0.6,0.5-0.6,0.9c0,0.4,0.3,0.7,1,0.7h2.5c1.4,0,2.3,0.4,2.3,2c0,1.2-1,3.2-4.4,3.2
       C119.7,64.6,118.4,63.8,118.4,62.7z M125.1,62c0-0.7-0.3-1.2-1.4-1.2h-2.5c-0.5,0.1-1.3,0.8-1.3,1.6c0,1,0.7,1.5,2.3,1.5
       C123.8,63.9,125.1,63.1,125.1,62z M123.8,55.4c0-1.6-0.5-2.2-1.6-2.2c-1,0-1.5,0.6-1.5,2c0,1.6,0.5,2.1,1.5,2.1
       C123.2,57.3,123.8,56.8,123.8,55.4z"/>
     <path class="st3" d="M127.1,60.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-5c0-0.6-0.1-0.7-0.5-0.9l-0.6-0.3V53l2.5-0.3l0.2,0.1l-0.1,0.9
       h0.1c0.6-0.6,1.6-1.1,2.4-1.1c0.7,0,1.1,0.4,1.1,1c0,0.6-0.4,0.9-0.9,0.9c-0.6,0-0.8-0.2-0.9-0.5c-0.1-0.2-0.2-0.3-0.4-0.3
       c-0.3,0-1,0.4-1.2,0.7v5.1c0,0.7,0.1,0.8,0.4,0.8l0.8,0.2V61h-3.9V60.5z"/>
     <path class="st3" d="M133.7,56.9c0-2.8,2-4.3,4-4.3c2.3,0,4,1.4,4,4.2c0,2.8-2,4.4-4,4.4C135.5,61.2,133.7,59.8,133.7,56.9z
        M140,57c0-2.7-0.8-3.8-2.3-3.8c-1.4,0-2.2,1-2.2,3.4c0,2.7,0.8,3.8,2.4,3.8C139.3,60.5,140,59.4,140,57z"/>
     <path class="st3" d="M143.3,54.5c-0.3-0.7-0.4-0.9-0.9-1.1l-0.4-0.1v-0.5h3.6v0.5l-0.4,0.1c-0.4,0.1-0.4,0.3-0.4,0.5
       c0,0.2,0.1,0.5,0.2,0.8l1.4,4.4h0.1l1.5-4.3l-0.1-0.4c-0.2-0.6-0.4-1-0.8-1.1l-0.4-0.1v-0.5h3.4v0.5l-0.4,0.1
       c-0.3,0.1-0.4,0.3-0.4,0.5c0,0.2,0.1,0.5,0.2,0.8l1.5,4.4h0.1l1.4-4.4c0.1-0.4,0.2-0.7,0.2-0.9c0-0.2-0.1-0.4-0.4-0.5l-0.4-0.1
       v-0.5h2.8v0.5l-0.4,0.1c-0.4,0.1-0.5,0.4-0.8,1.2l-2.3,6.3h-1l-1.8-5.1h0l-1.8,5.1h-1L143.3,54.5z"/>
     <path class="st3" d="M159.2,59.1c0-1.3,0.9-2,2.8-2.4l1.9-0.4v-1.5c0-1.1-0.2-1.6-1.5-1.6c-0.7,0-0.8,0.2-0.9,0.6
       c-0.1,0.6-0.2,1.1-1,1.1c-0.6,0-0.8-0.4-0.8-0.8c0-1.1,1.7-1.5,3-1.5c1.9,0,2.8,0.7,2.8,2.1v4.7c0,0.4,0.1,0.7,0.4,0.8
       c0.2,0.1,0.4,0.1,0.8,0.1v0.4c-0.3,0.2-0.8,0.4-1.4,0.4c-0.8,0-1.2-0.4-1.3-0.9h-0.1c-0.4,0.5-1.3,0.9-2.2,0.9
       C160.1,61.2,159.2,60.5,159.2,59.1z M163.9,59.5v-2.5l-1.2,0.1c-1.2,0.1-1.8,0.5-1.8,1.7c0,0.9,0.6,1.3,1.5,1.3
       C163.1,60.3,163.6,59.9,163.9,59.5z"/>
     <path class="st3" d="M168.1,59.5v-5.8H167v-0.5c1.1-0.3,1.7-1.1,2.1-2.4h0.6v2h2.3l-0.2,0.8h-2.1v5.6c0,0.7,0.4,1,1.1,1
       c0.5,0,0.9-0.1,1.3-0.2v0.5c-0.3,0.3-1,0.6-2.1,0.6C169,61.2,168.1,60.7,168.1,59.5z"/>
   </g>
   <g class="st2">
     <path class="st3" d="M30.4,142.1c0-1.3,0.9-2,2.8-2.4l1.9-0.4v-1.5c0-1.1-0.2-1.6-1.5-1.6c-0.7,0-0.8,0.2-0.9,0.6
       c-0.1,0.6-0.2,1.1-1,1.1c-0.6,0-0.8-0.4-0.8-0.8c0-1.1,1.7-1.5,3-1.5c1.9,0,2.8,0.7,2.8,2.1v4.7c0,0.4,0.1,0.7,0.4,0.8
       c0.2,0.1,0.4,0.1,0.8,0.1v0.4c-0.3,0.2-0.8,0.4-1.4,0.4c-0.8,0-1.2-0.4-1.3-0.9h-0.1c-0.4,0.5-1.3,0.9-2.2,0.9
       C31.4,144.2,30.4,143.5,30.4,142.1z M35.1,142.5v-2.5l-1.2,0.1c-1.2,0.1-1.8,0.5-1.8,1.7c0,0.9,0.6,1.3,1.5,1.3
       C34.3,143.3,34.9,142.9,35.1,142.5z"/>
     <path class="st3" d="M38.6,143.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-5c0-0.6-0.1-0.7-0.5-0.9l-0.6-0.3V136l2.5-0.3l0.2,0.1l-0.1,0.9
       h0.1c0.8-0.5,2-1,3-1c1.6,0,2.2,0.7,2.2,2.1v4.8c0,0.6,0.1,0.8,0.4,0.8l0.6,0.2v0.5h-3.7v-0.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8
       v-4.3c0-1.1-0.4-1.5-1.4-1.5c-0.8,0-1.7,0.3-2.2,0.5v5.3c0,0.7,0.1,0.8,0.4,0.8l0.7,0.2v0.5h-3.8V143.5z"/>
     <path class="st3" d="M48.2,143.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-5c0-0.6-0.1-0.7-0.5-0.9l-0.6-0.3V136l2.5-0.3l0.2,0.1l-0.1,0.9
       h0.1c0.8-0.5,2-1,3-1c1.6,0,2.2,0.7,2.2,2.1v4.8c0,0.6,0.1,0.8,0.4,0.8l0.6,0.2v0.5h-3.7v-0.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8
       v-4.3c0-1.1-0.4-1.5-1.4-1.5c-0.8,0-1.7,0.3-2.2,0.5v5.3c0,0.7,0.1,0.8,0.4,0.8l0.7,0.2v0.5h-3.8V143.5z"/>
     <path class="st3" d="M58.7,142.1v-4.7c0-0.6-0.1-0.6-0.4-0.8l-0.7-0.3v-0.4l2.4-0.3l0.2,0.2v5.9c0,0.9,0.4,1.4,1.4,1.4
       c0.8,0,1.6-0.3,2.2-0.6v-5.2c0-0.6,0-0.6-0.4-0.8l-0.6-0.3v-0.4l2.4-0.3l0.2,0.2v6.4c0,0.7,0.1,0.8,0.5,1l0.6,0.3v0.4l-2.5,0.3
       l-0.2-0.1l0.1-1h-0.1c-0.8,0.6-1.9,1.1-3,1.1C59.5,144.2,58.7,143.5,58.7,142.1z"/>
     <path class="st3" d="M67.4,142.1c0-1.3,0.9-2,2.8-2.4l1.9-0.4v-1.5c0-1.1-0.2-1.6-1.5-1.6c-0.7,0-0.8,0.2-0.9,0.6
       c-0.1,0.6-0.2,1.1-1,1.1c-0.6,0-0.8-0.4-0.8-0.8c0-1.1,1.7-1.5,3-1.5c1.9,0,2.8,0.7,2.8,2.1v4.7c0,0.4,0.1,0.7,0.4,0.8
       c0.2,0.1,0.4,0.1,0.8,0.1v0.4c-0.3,0.2-0.8,0.4-1.4,0.4c-0.8,0-1.2-0.4-1.3-0.9h-0.1c-0.4,0.5-1.3,0.9-2.2,0.9
       C68.4,144.2,67.4,143.5,67.4,142.1z M72.1,142.5v-2.5l-1.2,0.1c-1.2,0.1-1.8,0.5-1.8,1.7c0,0.9,0.6,1.3,1.5,1.3
       C71.3,143.3,71.9,142.9,72.1,142.5z"/>
     <path class="st3" d="M75.6,143.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-8.9c0-0.6-0.1-0.7-0.5-0.8l-0.7-0.3V132l2.7-0.3l0.2,0.1
       c0,0-0.1,0.8-0.1,1.9v8.8c0,0.7,0.1,0.8,0.4,0.8l0.7,0.2v0.5h-3.8V143.5z"/>
     <path class="st3" d="M80,143.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-8.9c0-0.6-0.1-0.7-0.5-0.8l-0.7-0.3V132l2.7-0.3l0.2,0.1
       c0,0-0.1,0.8-0.1,1.9v8.8c0,0.7,0.1,0.8,0.4,0.8l0.7,0.2v0.5H80V143.5z"/>
     <path class="st3" d="M84.2,146.6c0-0.5,0.3-0.9,0.8-0.9c0.5,0,0.8,0.3,0.9,0.7c0.1,0.2,0.1,0.3,0.3,0.3c0.4,0,0.9-0.5,1.4-1.7
       l0.4-0.9l-2.7-6.5c-0.4-0.8-0.4-0.9-0.8-1.1l-0.4-0.1v-0.5h3.6v0.5l-0.4,0.1c-0.4,0.1-0.5,0.2-0.5,0.5c0,0.2,0.1,0.5,0.2,0.9
       l1.7,4.3h0.1l1.6-4.3c0.1-0.4,0.2-0.7,0.2-0.9c0-0.3-0.2-0.3-0.4-0.4l-0.4-0.1v-0.5h2.9v0.5l-0.4,0.1c-0.4,0.1-0.5,0.3-0.8,1.2
       l-2.9,7.3c-0.6,1.6-1.4,2.7-2.8,2.7C84.6,147.6,84.2,147.1,84.2,146.6z"/>
     <path class="st3" d="M97,139.9c0-2.8,2-4.3,4-4.3c2.3,0,4,1.4,4,4.2c0,2.8-2,4.4-4,4.4C98.8,144.2,97,142.8,97,139.9z M103.4,140
       c0-2.7-0.8-3.8-2.3-3.8c-1.4,0-2.2,1-2.2,3.4c0,2.7,0.8,3.8,2.4,3.8C102.6,143.5,103.4,142.4,103.4,140z"/>
     <path class="st3" d="M106.6,137.5c-0.3-0.7-0.4-0.9-0.9-1.1l-0.4-0.1v-0.5h3.7v0.5l-0.4,0.1c-0.4,0.1-0.4,0.3-0.4,0.5
       c0,0.2,0.1,0.5,0.2,0.8l1.6,4.4h0.1l1.6-4.4c0.1-0.4,0.2-0.7,0.2-0.9c0-0.2-0.1-0.4-0.4-0.5l-0.4-0.1v-0.5h2.9v0.5l-0.4,0.1
       c-0.4,0.1-0.5,0.4-0.8,1.2l-2.4,6.3h-0.9L106.6,137.5z"/>
     <path class="st3" d="M114.1,139.8c0-2.6,1.8-4.2,4-4.2c2,0,3.3,1.1,3.3,4.1h-5.5c0,2.4,1.2,3.5,2.8,3.5c1.2,0,2-0.4,2.6-0.7v0.6
       c-0.4,0.4-1.6,1.1-3.1,1.1C115.4,144.2,114.1,142.4,114.1,139.8z M115.8,139l3.7-0.2c0-1.8-0.3-2.5-1.5-2.5
       C116.8,136.3,115.9,137.1,115.8,139z"/>
     <path class="st3" d="M122.2,143.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-5c0-0.6-0.1-0.7-0.5-0.9l-0.6-0.3V136l2.5-0.3l0.2,0.1
       l-0.1,0.9h0.1c0.6-0.6,1.6-1.1,2.4-1.1c0.7,0,1.1,0.4,1.1,1c0,0.6-0.4,0.9-0.9,0.9c-0.6,0-0.8-0.2-0.9-0.5
       c-0.1-0.2-0.2-0.3-0.4-0.3c-0.3,0-1,0.4-1.2,0.7v5.1c0,0.7,0.1,0.8,0.4,0.8l0.8,0.2v0.5h-3.9V143.5z"/>
     <path class="st3" d="M133.9,142.5v-5.8h-1.1v-0.5c1.1-0.3,1.7-1.1,2.1-2.4h0.6v2h2.3l-0.2,0.8h-2.1v5.6c0,0.7,0.4,1,1.1,1
       c0.5,0,0.9-0.1,1.3-0.2v0.5c-0.3,0.3-1,0.6-2.1,0.6C134.7,144.2,133.9,143.7,133.9,142.5z"/>
     <path class="st3" d="M138.4,143.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-8.9c0-0.6-0.1-0.7-0.5-0.8l-0.7-0.3V132l2.7-0.3l0.2,0.1
       c0,0-0.1,0.8-0.1,1.9v1.5c0,0.7-0.1,1.4-0.1,1.4h0.1c0.8-0.5,2-1,3-1c1.6,0,2.2,0.7,2.2,2.1v4.8c0,0.6,0.1,0.8,0.4,0.8l0.6,0.2v0.5
       h-3.7v-0.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-4.3c0-1.1-0.4-1.5-1.4-1.5c-0.8,0-1.7,0.3-2.2,0.5v5.3c0,0.7,0.1,0.8,0.4,0.8l0.7,0.2
       v0.5h-3.8V143.5z"/>
     <path class="st3" d="M148.2,139.8c0-2.6,1.8-4.2,4-4.2c2,0,3.3,1.1,3.3,4.1h-5.5c0,2.4,1.2,3.5,2.8,3.5c1.2,0,2-0.4,2.6-0.7v0.6
       c-0.4,0.4-1.6,1.1-3.1,1.1C149.5,144.2,148.2,142.4,148.2,139.8z M149.9,139l3.7-0.2c0-1.8-0.3-2.5-1.5-2.5
       C150.9,136.3,150.1,137.1,149.9,139z"/>
     <path class="st3" d="M160.4,143.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-5c0-0.6-0.1-0.7-0.5-0.9l-0.6-0.3V136l2.5-0.3l0.2,0.1
       l-0.1,0.9h0.1c0.8-0.5,2-1,3-1c1.6,0,2.2,0.7,2.2,2.1v4.8c0,0.6,0.1,0.8,0.4,0.8l0.6,0.2v0.5h-3.7v-0.5l0.7-0.2
       c0.3-0.1,0.4-0.2,0.4-0.8v-4.3c0-1.1-0.4-1.5-1.4-1.5c-0.8,0-1.7,0.3-2.2,0.5v5.3c0,0.7,0.1,0.8,0.4,0.8l0.7,0.2v0.5h-3.8V143.5z"
       />
     <path class="st3" d="M170.2,139.8c0-2.6,1.8-4.2,4-4.2c2,0,3.3,1.1,3.3,4.1h-5.5c0,2.4,1.2,3.5,2.8,3.5c1.2,0,2-0.4,2.6-0.7v0.6
       c-0.4,0.4-1.6,1.1-3.1,1.1C171.5,144.2,170.2,142.4,170.2,139.8z M171.9,139l3.7-0.2c0-1.8-0.3-2.5-1.5-2.5
       C172.9,136.3,172.1,137.1,171.9,139z"/>
     <path class="st3" d="M178.2,143.5l0.5-0.1c0.6-0.2,0.9-0.4,1.4-1.2l1.5-2.2l-1.8-2.5c-0.7-0.9-0.8-1.1-1.3-1.2l-0.3-0.1v-0.5h3.7
       v0.5l-0.4,0.1c-0.3,0.1-0.4,0.2-0.4,0.4c0,0.2,0.2,0.5,0.4,0.8l1.1,1.5h0l0.8-1.3c0.3-0.5,0.5-0.8,0.5-1c0-0.2-0.1-0.3-0.3-0.3
       l-0.5-0.1v-0.5h3.2v0.5l-0.5,0.1c-0.6,0.2-0.8,0.4-1.4,1.2l-1.4,1.9l2,2.7c0.6,0.8,0.8,1.1,1.4,1.2l0.2,0v0.5h-3.7v-0.5l0.4-0.1
       c0.3-0.1,0.4-0.2,0.4-0.4c0-0.2-0.2-0.5-0.5-0.8l-1.2-1.7h0l-0.9,1.5c-0.3,0.5-0.5,0.8-0.5,1c0,0.2,0.1,0.3,0.5,0.4l0.3,0.1v0.5
       h-3.2V143.5z"/>
     <path class="st3" d="M187.7,142.5v-5.8h-1.1v-0.5c1.1-0.3,1.7-1.1,2.1-2.4h0.6v2h2.3l-0.2,0.8h-2.1v5.6c0,0.7,0.4,1,1.1,1
       c0.5,0,0.9-0.1,1.3-0.2v0.5c-0.3,0.3-1,0.6-2.1,0.6C188.5,144.2,187.7,143.7,187.7,142.5z"/>
     <path class="st3" d="M198.9,135.9h2.9l1.7-0.2l0.2,0.1c0,0-0.1,0.7-0.1,1.5v5.3c0,0.7,0.1,0.8,0.4,0.8l0.7,0.2v0.5h-3.7v-0.5
       l0.6-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-5c0-0.6-0.2-0.8-0.6-0.8h-2.5v5.7c0,0.8,0.1,0.8,0.4,0.9l0.7,0.2v0.5h-3.8v-0.5l0.7-0.2
       c0.3-0.1,0.4-0.2,0.4-0.8v-5.8h-1.1v-0.5l1.1-0.4v-0.6c0-2.2,1.6-3.5,3.8-3.5c1.3,0,1.8,0.4,1.8,1c0,0.6-0.4,0.9-0.9,0.9
       c-0.6,0-0.9-0.4-1-0.9c0-0.3-0.1-0.4-0.4-0.4c-0.9,0-1.8,0.4-1.8,2V135.9z"/>
     <path class="st3" d="M206.1,137.5c-0.3-0.7-0.4-0.9-0.9-1.1l-0.4-0.1v-0.5h3.7v0.5l-0.4,0.1c-0.4,0.1-0.4,0.3-0.4,0.5
       c0,0.2,0.1,0.5,0.2,0.8l1.6,4.4h0.1l1.6-4.4c0.1-0.4,0.2-0.7,0.2-0.9c0-0.2-0.1-0.4-0.4-0.5l-0.4-0.1v-0.5h2.9v0.5l-0.4,0.1
       c-0.4,0.1-0.5,0.4-0.8,1.2l-2.4,6.3h-0.9L206.1,137.5z"/>
     <path class="st3" d="M213.5,139.8c0-2.6,1.8-4.2,4-4.2c2,0,3.3,1.1,3.3,4.1h-5.5c0,2.4,1.2,3.5,2.8,3.5c1.2,0,2-0.4,2.6-0.7v0.6
       c-0.4,0.4-1.6,1.1-3.1,1.1C214.8,144.2,213.5,142.4,213.5,139.8z M215.2,139l3.7-0.2c0-1.8-0.3-2.5-1.5-2.5
       C216.2,136.3,215.4,137.1,215.2,139z"/>
     <path class="st3" d="M225.6,146.6c0-0.5,0.3-0.9,0.8-0.9c0.5,0,0.8,0.3,0.9,0.7c0.1,0.2,0.1,0.3,0.3,0.3c0.4,0,0.9-0.5,1.4-1.7
       l0.4-0.9l-2.7-6.5c-0.4-0.8-0.4-0.9-0.8-1.1l-0.4-0.1v-0.5h3.6v0.5l-0.4,0.1c-0.4,0.1-0.5,0.2-0.5,0.5c0,0.2,0.1,0.5,0.2,0.9
       l1.7,4.3h0.1l1.6-4.3c0.1-0.4,0.2-0.7,0.2-0.9c0-0.3-0.2-0.3-0.4-0.4l-0.4-0.1v-0.5h2.9v0.5l-0.4,0.1c-0.4,0.1-0.5,0.3-0.8,1.2
       l-2.9,7.3c-0.6,1.6-1.4,2.7-2.8,2.7C225.9,147.6,225.6,147.1,225.6,146.6z"/>
     <path class="st3" d="M234.1,139.8c0-2.6,1.8-4.2,4-4.2c2,0,3.3,1.1,3.3,4.1h-5.5c0,2.4,1.2,3.5,2.8,3.5c1.2,0,2-0.4,2.6-0.7v0.6
       c-0.4,0.4-1.6,1.1-3.1,1.1C235.4,144.2,234.1,142.4,234.1,139.8z M235.8,139l3.7-0.2c0-1.8-0.3-2.5-1.5-2.5
       C236.8,136.3,236,137.1,235.8,139z"/>
     <path class="st3" d="M242.4,142.1c0-1.3,0.9-2,2.8-2.4l1.9-0.4v-1.5c0-1.1-0.2-1.6-1.5-1.6c-0.7,0-0.8,0.2-0.9,0.6
       c-0.1,0.6-0.2,1.1-1,1.1c-0.6,0-0.8-0.4-0.8-0.8c0-1.1,1.7-1.5,3-1.5c1.9,0,2.8,0.7,2.8,2.1v4.7c0,0.4,0.1,0.7,0.4,0.8
       c0.2,0.1,0.4,0.1,0.8,0.1v0.4c-0.3,0.2-0.8,0.4-1.4,0.4c-0.8,0-1.2-0.4-1.3-0.9H247c-0.4,0.5-1.3,0.9-2.2,0.9
       C243.3,144.2,242.4,143.5,242.4,142.1z M247.1,142.5v-2.5l-1.2,0.1c-1.2,0.1-1.8,0.5-1.8,1.7c0,0.9,0.6,1.3,1.5,1.3
       C246.3,143.3,246.8,142.9,247.1,142.5z"/>
     <path class="st3" d="M250.5,143.5l0.7-0.2c0.3-0.1,0.4-0.2,0.4-0.8v-5c0-0.6-0.1-0.7-0.5-0.9l-0.6-0.3V136l2.5-0.3l0.2,0.1
       l-0.1,0.9h0.1c0.6-0.6,1.6-1.1,2.4-1.1c0.7,0,1.1,0.4,1.1,1c0,0.6-0.4,0.9-0.9,0.9c-0.6,0-0.8-0.2-0.9-0.5
       c-0.1-0.2-0.2-0.3-0.4-0.3c-0.3,0-1,0.4-1.2,0.7v5.1c0,0.7,0.1,0.8,0.4,0.8l0.8,0.2v0.5h-3.9V143.5z"/>
     <path class="st3" d="M257.5,143.8l-0.1-2.4h0.5c0.3,0.6,0.4,1,0.8,1.4c0.3,0.4,0.6,0.6,1.4,0.6c1,0,1.7-0.5,1.7-1.3
       c0-0.9-0.6-1.2-1.9-1.7c-1.4-0.5-2.4-1.2-2.4-2.5c0-1.5,1.3-2.4,3-2.4c0.9,0,1.5,0.1,2.1,0.3l0.1,2.1h-0.5
       c-0.3-0.5-0.5-0.9-0.8-1.3c-0.2-0.3-0.5-0.4-1.1-0.4c-1.1,0-1.5,0.5-1.5,1.3c0,0.6,0.5,1,1.9,1.6c1.5,0.6,2.5,1.2,2.5,2.4
       c0,1.5-1.2,2.7-3.2,2.7C259,144.2,258.1,144.1,257.5,143.8z"/>
     <path class="st3" d="M263.8,137.8c0-0.2,0.2-0.5,0.5-0.5c0.3,0,0.5,0.1,0.6,0.4c0.2,0.5,0.1,0.7,0.8,0.7c1,0,1.3-0.7,1.3-1.5
       c0-0.8-0.3-1.3-1.4-1.3h-0.5v-0.4h0.4c0.9,0,1.3-0.4,1.3-1.3c0-0.9-0.4-1.3-1.1-1.3c-0.4,0-0.5,0.1-0.6,0.5c0,0.4-0.2,0.6-0.6,0.6
       c-0.4,0-0.5-0.3-0.5-0.5c0-0.6,0.9-0.9,1.8-0.9c1.3,0,2,0.7,2,1.5c0,0.7-0.5,1.4-1.6,1.6v0c1.2,0.1,1.8,0.7,1.8,1.6
       c0,1-0.8,1.9-2.5,1.9C264.5,138.8,263.8,138.4,263.8,137.8z"/>
   </g>
   <g class="st2">
     <path class="st4" d="M31.1,112.8c0-1.4,0.9-2.2,2.3-2.2c1.7,0,2.2,0.9,2.6,2.6c0.6,2.1,1.1,2.9,4.1,2.9c6.2,0,8.6-4.3,8.6-9
       c0-4.7-1.7-7.8-7.7-7.8h-2.9v-1.7h2.4c5,0,7.1-2.5,7.1-7.9c0-5-2.4-7.8-6.7-7.8c-2.3,0-3.5,0.5-3.8,3.1c-0.2,1.8-1.1,2.6-2.5,2.6
       c-1.8,0-2.3-1.1-2.3-2.1c0-3.4,5.1-5,9.2-5c7.1,0,10.2,4.1,10.2,8.6c0,4.1-2.4,7.9-8.4,9.1v0.2c6.4,0.5,9.6,4.1,9.6,8.9
       c0,5.1-4.1,10.5-12.8,10.5C34.7,117.6,31.1,116,31.1,112.8z"/>
     <path class="st4" d="M55.6,114.6c0-1.5,1.2-2.7,2.7-2.7c1.5,0,2.7,1.2,2.7,2.7c0,1.5-1.2,2.7-2.7,2.7
       C56.8,117.2,55.6,116.1,55.6,114.6z"/>
     <path class="st4" d="M64.1,116.1l2.8-0.4c1.7-0.2,2-0.8,2-2.8V85.7h-4.6v-0.9c4.2-0.7,5.9-1.7,8-4.2l0.6,0.1v32.2
       c0,1.9,0.3,2.5,1.8,2.8l2.9,0.4v0.9H64.1V116.1z"/>
     <path class="st4" d="M79.7,90c0-6.4,4.2-9.6,7.9-9.6c3.6,0,7.8,2.8,7.8,9c0,6.4-4.4,9.4-7.9,9.4C84,98.8,79.7,96.1,79.7,90z
        M107.4,79.9h2.1l-25.7,39.7h-2.1L107.4,79.9z M91.6,90c0-5.5-1.3-8.4-4.1-8.4c-2.8,0-4,2.6-4,7.7c0,5.5,1.4,8.3,4,8.3
       C90.3,97.7,91.6,95.3,91.6,90z M96.4,108.9c0-6.4,4.2-9.6,7.9-9.6c3.6,0,7.8,2.8,7.8,9c0,6.4-4.4,9.4-7.9,9.4
       C100.7,117.7,96.4,115.1,96.4,108.9z M108.3,108.9c0-5.5-1.3-8.4-4.1-8.4c-2.8,0-4,2.6-4,7.7c0,5.5,1.4,8.3,4,8.3
       C107,116.6,108.3,114.2,108.3,108.9z"/>
   </g>
   </svg>`),
    ];

    panels = [
        {
            title: `4Q 2018 Middle market indicator`,
            href: `assets/pdf/NCMM_MMI_Q4_2018.pdf`,
            image: {
                src: `assets/img/outlook-logo-ncmm@2x.png`
            },
            share: {
                email_id: '2_1',
                web_root: 'https://www.chubb.com/pacific-tech/',
                url: 'assets/pdf/NCMM_MMI_Q4_2018.pdf',
                social_title: 'Check out the NCMM Middle Market Indicator',
                social_body: 'Check out a special report, the NCMM quarterly middle market indicator.',
                email_subject: 'Check out the NCMM Middle Market Indicator',
                email_body: 'You might be interested in this resource from Chubb and the National Center for the Middle Market. Take a look.',
                color: ''
            }
        }
    ];

    state = 'hide';
    direction = 'down';
    offset = 0;

    constructor(public el: ElementRef, protected sanitizer: DomSanitizer, private globals: Globals) { }

    @HostListener('window:scroll', ['$event']) checkScroll() {
        const ne = this.el.nativeElement;

        this.direction = window.pageYOffset > this.offset ? 'down' : 'up';
        this.offset = window.pageYOffset;

        if (window.pageYOffset >= ne.offsetTop - ne.offsetHeight / 2 && window.pageYOffset <= ne.offsetTop + ne.offsetHeight) {
          if (this.direction === 'down') {
            this.state = 'show';
          } else {
            this.state = 'fadein';
          }
        } else {
          this.state = 'hide';
        }
    }

    ngOnInit() {
    }

    scrollStart(el) {
      const ne = this.el.nativeElement,
        hr = el.element.querySelectorAll('hr'),
        svg = el.element.querySelectorAll('svg');
        if (window.pageYOffset < (ne.offsetTop - 100) || window.pageYOffset > (ne.offsetTop + ne.offsetHeight + 100)) {
            hr.forEach(h => {
                h.style.transition = 'none';
                h.style.opacity = '0';
            });
            svg.forEach(e => {
                e.style.transition = 'none';
                e.style.opacity = '0';
            });
        }
    }

    scrollDone(el) {
        const hr = el.element.querySelectorAll('hr'),
          svg = el.element.querySelectorAll('svg, img');

        // if (this.globals.ignoreScroll === false) {
        //   if (this.state === 'show' || this.state === 'fade') {
        //     window.dataLayer.push({
        //       'event': 'irEvent',
        //       'eventCategory': 'Scroll (section)',
        //       'eventAction': 'Scrolled from Industry Outlook',
        //       'eventLabel': 'from Industry Outlook'
        //     });
        //     this.globals.ignoreScroll = true;
        //     setTimeout(() => {
        //       this.globals.ignoreScroll = false;
        //     }, 3000);
        //   }
        // }

        setTimeout(() => {
            hr.forEach(h => {
                h.style.transition = 'all 500ms ease-in';
                h.style.opacity = '1';
            });
            setTimeout(() => {
                svg.forEach(e => {
                    e.style.transition = 'all 600ms ease-out';
                    e.style.opacity = '1';
                });
            }, 500);
        }, 250);
    }
}
