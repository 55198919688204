<div class="workform" *ngIf="this.submitted && this.response" [@formComplete]>
    <h2>Thank You</h2>
    <h3>Your information has been submitted and we will get back to you shortly.</h3>
  </div>
  
  <div class="workform" *ngIf="this.submitted && !this.response" [@formComplete]>
    <h2>Send Failed</h2>
    <h3>
      Something went wrong and your message was not sent. Please try again later, or visit our 
      <a href="/us-en/contact-us/" data-cta="Contact us page">main contact page</a>.
    </h3>
  </div>
  
  <form class="workform" (ngSubmit)="onSubmit()" #workForm="ngForm" [ngClass]="{ hidden: this.submitted }" >
    <h2>Connect with a local rep</h2>

    <label>
      I am a(n)<br />
      <div class="select-wrapper">
        <select class="form-control select-styled" name="ContactType" (change)="iAmHandler($event)" [(ngModel)]="formModel.i_am" required #spy>
          <option *ngFor="let o of iAmOptions" value="{{ o.value }}">{{ o.title }}</option>
        </select>
      </div>
    </label>

    <label [hidden]="!this.showOther">
        If Other, please specify:<br />
        <input type="text" name="ContactTypeEntry" class="form-control" [(ngModel)]="formModel.other" [required]="iAmSelected === 'Other'" (change)="dlHandler($event)" #spy />
    </label>

    <label>
      First Name<br />
      <input type="text" name="FirstName" class="form-control icon-emo-unhappy" [(ngModel)]="formModel.name_first" (change)="dlHandler($event)" required #spy />
    </label>
    <label>
      Last Name<br />
      <input type="text" name="LastName" class="form-control" [(ngModel)]="formModel.name_last" (change)="dlHandler($event)" required #spy />
    </label>

    <label>
      Email<br />
      <input type="text" name="Email Address" class="form-control" [(ngModel)]="formModel.email" (change)="dlHandler($event)" required email #spy />
    </label>
    <label>
      Phone Number (optional)<br />
      <input type="text" name="Phone" class="form-control" [(ngModel)]="formModel.phone" (change)="dlHandler($event)" pattern="[0-9 \-()]*" #spy />
    </label>

    <label>
      Name of Business<br />
      <input type="text" name="Company" class="form-control" [(ngModel)]="formModel.nob" (change)="dlHandler($event)" required #spy />
    </label>

    <label>
      ZIP<br />
      <input type="text" name="Zip" class="form-control" [(ngModel)]="formModel.zip" (change)="dlHandler($event)" required #spy />
    </label>

    <label class="wide">
        Question/Comment - please update with more specific details about your inquiry.<br />
        <textarea class="form-control" name="WebComment" [(ngModel)]="formModel.comment" (change)="dlHandler($event)" #spy></textarea>
    </label>

    <label hidden="true">
      <input type="text" name="MID" class="form-control" [ngModel]=""/>
    </label>

    <label hidden="true">
      <input type="text" name="Timestamp" class="form-control" [ngModel]=""/>
    </label>

    <label hidden="true"> 
      <input type="text" name="WebpageURL" class="form-control" [ngModel]="pageUrl"/>
    </label>

    <label class="wide recaptcha">
      <recaptcha name="My Recaptcha" [(ngModel)]="myRecaptcha" (scriptLoad)="onScriptLoad()" (scriptError)="onScriptError()" required></recaptcha>
    </label>

    <label class="wide">
        <button type="submit" name="submit" value="Submit" class="btn white" data-cta="Form Submit: Connect with a local rep" [disabled]="!workForm.form.valid">Submit&nbsp;<fa-icon [icon]="['fas', 'angle-right']"></fa-icon></button>
    </label>

  </form>
